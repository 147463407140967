import * as React from "react";
import * as ReactDOM from "react-dom";
import MifPageContainer from "../containers/MifPageContainer";
import MifTabs from "../components/MifTabs";
import MifPageSelectorContainer from "../containers/MifPageSelectorContainer";
import MifCurrencyFilterContainer from "../containers/MifCurrencyFilterContainer";

const Index = () =>
    <div>
        <h1 style={{paddingTop: "37px" }} />
        <MifPageSelectorContainer />
        <MifCurrencyFilterContainer />
        <MifTabs/>
    </div>;

export default Index;