import { put, takeEvery, takeLatest, fork, select } from 'redux-saga/effects'
import config from '../config';
import * as Interfaces from "../interfaces"
import * as Actions from "./actions";
import * as Top from "../reducers"
import * as Selectors from "./selectors"
import * as GeneralSelectors from "../general/selectors"
import {notification} from "antd"
import * as MainActions from "../actions";

import AuthenticatedRequest from "../http";

function erdrTakeEvery<TAction extends string>(action: TAction,
    reducer: (act: Actions.Action & { type: TAction }) => any) {
    return takeEvery(action, reducer);
}

 
function* loadPageFull(action: Actions.LoadPageFullAction   ) {
    if (!action.id)
        return;

    const currentPageFull: Interfaces.ErdrPageFull = yield (select(Selectors.getPageFull(action.id)));

    if (!currentPageFull || action.forceReload) {
        const url = config.apiBaseUrl + "/api/ErdrPages/" + action.id.toString();
        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            }
            const json: Interfaces.ErdrPageFull = yield response.json();
            yield put(Actions.addPageFull(action.id, json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading Erdr Page " + action.id });
        }
    }
}

function* watchLoadPageFull() {
    yield erdrTakeEvery(Actions.LOAD_PAGE_FULL, loadPageFull);
}

function* loadAvailablePages(action: Actions.LoadAvailablePagesAction) {
    const selectedDate: Date = yield select(GeneralSelectors.getDate);
    const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);
    const selectedEntityId: Date = yield select(GeneralSelectors.getEntity);
    const selectedCurrencyId: Date = yield select(GeneralSelectors.getCurrency);

    const currentPageIds: Interfaces.MifPageList[] = yield (select(Selectors.getAvailablePages));
    if (currentPageIds.length === 0 || action.forceReload) {


        let url = config.apiBaseUrl + "/api/ErdrPages?selectedCountryId=" + selectedCountryId + "&selectedEntityId=" + selectedEntityId + "&selectedCurrencyId=" + selectedCurrencyId;
        if (selectedDate !== null) {
            var selectedDateFormatted = selectedDate.toISOString();
            url += "&selectedDate=" + selectedDateFormatted;
        }
        //Commenting below line of code to resolve "The value 'null' is not valid" while parsing to Date
        //else {
        //    url += "&selectedDate=" + null;
        //}


        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            }
            const json: Interfaces.ErdrConsolidatedPage[] = yield response.json();
            yield put(Actions.setAvailablePages(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading Available Pages" });
        }
    }
}

function* watchLoadPageIds() {
    yield erdrTakeEvery(Actions.LOAD_AVAILABLE_PAGES, loadAvailablePages);
}

export function* saga() {
    yield fork(watchLoadPageFull);
    yield fork(watchLoadPageIds);
}