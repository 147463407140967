import * as React from "react";
import * as ReactDom from "react-dom";
import SpecialRulesTableContainer from "../containers/SpecialRulesTableContainer";

const SpecialRulesData = (props) =>
    <div>
        <h1 style={{ paddingTop: "25px" }} />
        <SpecialRulesTableContainer />

    </div>;

export default SpecialRulesData; 