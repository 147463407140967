import * as Interfaces from "../interfaces";
export type SET_AVAILABLE_PAGES = "ERDR/SET_AVAILABLE_PAGES";
export const SET_AVAILABLE_PAGES : SET_AVAILABLE_PAGES = "ERDR/SET_AVAILABLE_PAGES";
export type SetAvailablePagesAction = {
    type: SET_AVAILABLE_PAGES,
    pages: Interfaces.ErdrConsolidatedPage[],
}
export function setAvailablePages(pages: Interfaces.ErdrConsolidatedPage[]) : Action {
    return {
        type: SET_AVAILABLE_PAGES,
        pages: pages
    }
}

export type LOAD_AVAILABLE_PAGES = "ERDR/LOAD_AVAILABLE_PAGES";
export const LOAD_AVAILABLE_PAGES : LOAD_AVAILABLE_PAGES = "ERDR/LOAD_AVAILABLE_PAGES";
export type LoadAvailablePagesAction = {
    type: LOAD_AVAILABLE_PAGES,
    forceReload: boolean,
}
export function loadAvailablePages(forceReload = false) : Action {
    return {
        type: LOAD_AVAILABLE_PAGES,
        forceReload: forceReload,
    }
}

export type ADD_PAGE_FULL = "ERDR/ADD_PAGE_FULL";
export const ADD_PAGE_FULL : ADD_PAGE_FULL = "ERDR/ADD_PAGE_FULL";
export type AddPageFullAction = {
    type: ADD_PAGE_FULL,
    id: number,
    pageFull: Interfaces.ErdrPageFull,
}
export function addPageFull(id: number, pageFull: Interfaces.ErdrPageFull) : Action {
    return {
        type: ADD_PAGE_FULL,
        id: id,
        pageFull: pageFull
    }
}

export type LOAD_PAGE_FULL = "ERDR/LOAD_PAGE_FULL";
export const LOAD_PAGE_FULL : LOAD_PAGE_FULL = "ERDR/LOAD_PAGE_FULL";
export type LoadPageFullAction = {
    type: LOAD_PAGE_FULL,
    id: number,
    forceReload: boolean,
}
export function loadPageFull(id:number, forceReload = false) : Action {
    return {
        type: LOAD_PAGE_FULL,
        id: id,
        forceReload: forceReload
    }
}

export type OtherAction = { type: "ERDR/Default" }
export const OtherAction : OtherAction = {type: "ERDR/Default"}

export type Action = SetAvailablePagesAction | LoadAvailablePagesAction | AddPageFullAction | LoadPageFullAction | OtherAction
