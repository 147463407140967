// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";
import * as Actions from "../actions";
import * as Interfaces from "../interfaces";
import  UploadFileForm  from "../components/UploadFileForm";
import * as GeneralSelectors from "../general/selectors";

const mapStateToProps = (state ) => ({
    availableEntities: GeneralSelectors.getAvailableEntities(state),
});

const mapDispatchToProps = (dispatch) => ({
    uploadFile: file => dispatch(Actions.uploadFile(file)),
    autoUploadFiles: x => dispatch(Actions.autoUploadFiles(x)),
 });

const UploadFileFormContainer = connect(mapStateToProps, mapDispatchToProps)(UploadFileForm);

export default UploadFileFormContainer;
