import { connect } from "react-redux";
import { ErdrPageSelector } from "../components/ErdrPageSelector";
import * as Interfaces from "../../interfaces";
import { Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch } from "./imports"

const mapStateToProps = (state: State) => ({
    mifCountries: GeneralSelectors.getCountries(state),
    availableEntities: GeneralSelectors.getAvailableEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),

    selectedDate: GeneralSelectors.getDate(state),
    selectedMifCountry: GeneralSelectors.getCountry(state),
    selectedMifEntity: GeneralSelectors.getEntity(state),
    selectedMifCurrency: GeneralSelectors.getCurrency(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateErdrPageSelectorTable: () => dispatch(Actions.loadAvailablePages(true)),
    selectDate: (selectedDate) => dispatch(GeneralActions.selectDate(selectedDate)),
    selectCountry: (selectedCountryId) => dispatch(GeneralActions.selectCountry(selectedCountryId)),
    selectEntity: (selectedEntityId) => dispatch(GeneralActions.selectEntity(selectedEntityId)),
    selectCurrency: (selectedCurrencyId) => dispatch(GeneralActions.selectCurrency(selectedCurrencyId))
});

const ErdrPageSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(ErdrPageSelector);

export default ErdrPageSelectorContainer;