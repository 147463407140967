// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";

import * as Actions from "../actions";
import * as Interfaces from "../interfaces";
import {AdjustmentsTable} from "../components/AdjustmentsTable";
import * as GeneralSelectors from "../general/selectors";

const mapStateToProps = (state) => ({
    //availablePages: Selectors.getAvailablePages(state)
    adjustments: state.adjustments,
    mifCountries: GeneralSelectors.getCountries(state),
    mifEntities: GeneralSelectors.getEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),
    riskPositionNames: GeneralSelectors.getRiskPositionNames(state)
});

const mapDispatchToProps = (dispatch) => ({
    addSaveAdjustment: (newAdjustment: Interfaces.AdjustmentEntry) => dispatch(Actions.addSaveAdjustment(newAdjustment)),
    deleteAdjustment: (x: number) => dispatch(Actions.deleteAdjustment(x)),
    editSaveAdjustment: (x: Interfaces.AdjustmentEntry) => dispatch(Actions.editSaveAdjustment(x))
});

const AdjustmentsTableContainer = connect(mapStateToProps, mapDispatchToProps)(AdjustmentsTable);

export default AdjustmentsTableContainer;