import { combineReducers } from "redux"
import { Map } from "immutable"
import * as Actions from "./actions";
import * as Interfaces from "./interfaces";
import { History } from 'history'

import * as General from "./general"
import * as Mif from "./mif"
import * as Erdr from "./erdr"
import * as RunoffView from "./runoffView"
import * as EquityInjections from "./equityInjections"
import * as SpecialRules from "./specialRules"
import { connectRouter } from "connected-react-router";

export type State = {
    general: General.State,
    mif: Mif.State,
    erdr: Erdr.State,
    runoffView: RunoffView.State,
    equityInjections: EquityInjections.State,
    specialRules: SpecialRules.State
}

//----------------------------------------------------------------------------
//eRDR Reducers

//-----------------------------------------------------------------------------------------------------
//Risk Position Reducers

//Needs to be an array of objects - risk positions
function availableRiskPositions(state = [], action) {
    switch (action.type) {
        case Actions.SET_AVAILABLE_RISK_POSITIONS:          //this is the key action here
            return action.riskPositions;
        case Actions.LOAD_RISK_POSITION_IDS:
            return [];
        default:
            return state;
    }
}

//-----------------------------------------------------------------------------------------------------

function inputFiles(state = [], action) {
    switch (action.type) {
        case Actions.SET_INPUT_FILES:
            return action.files;
        case Actions.LOAD_INPUT_FILES:
            return [];
        default:
            return state;
    }
}
//---------------------------------------------------------


function drmInputFiles(state = [], action) {
    switch (action.type) {
        case Actions.SET_DRM_INPUT_FILES:
            return action.files;
        case Actions.LOAD_DRM_INPUT_FILES:
            return [];
        default:
            return state;
    }
}
//---------------------------------------------------------

//------------------------------------------------------------
//Adjustments

function adjustments(state = [], action) {
    switch (action.type) {
        case Actions.SET_ADJUSTMENTS:
            return action.adjustments;
        default:
            return state;
    }
}

//Risk Positions

function riskPositions(state = [], action) {
    switch (action.type) {
        case Actions.SET_RISK_POSITIONS:
            return action.riskPositions;
        default:
            return state;
    }
}

// Modelable Risk Positions

function modelableRiskPositions(state = [], action) {
    switch (action.type) {
        case Actions.SET_MODELABLE_RISK_POSITIONS:
            return action.modelableRiskPositions;
        case Actions.LOAD_MODELABLE_RISK_POSITIONS:
            return [];
        default:
            return state;
    }
}

//Risk Position Models

function riskPositionModels(state = [], action) {
    switch (action.type) {
        case Actions.SET_RISK_POSITION_MODELS:
            return action.riskPositionModels;
        default:
            return state;
    }
}

function generalLedgers(state = [], action) {
    switch (action.type) {
        case Actions.SET_GENERAL_LEDGERS:
            return action.generalLedgers;
        case Actions.LOAD_GENERAL_LEDGERS:
            return [];
        default:
            return state;
    }
}

function runoffModels(state = [], action) {
    switch (action.type) {
        case Actions.SET_RUNOFF_MODELS:
            return action.runoffModels;
        case Actions.LOAD_RUNOFF_MODELS:
            return [];
        default:
            return state;
    }
}

function runoffMaturityTypes(state = [], action) {
    switch (action.type) {
        case Actions.SET_RUNOFF_MATURITY_TYPES:
            return action.runoffMaturityTypes;
        case Actions.LOAD_RUNOFF_MATURITY_TYPES:
            return [];
        default:
            return state;
    }
}

function runoffIrrTypes(state = [], action) {
    switch (action.type) {
        case Actions.SET_RUNOFF_IRR_TYPES:
            return action.runoffIrrTypes;
        case Actions.LOAD_RUNOFF_IRR_TYPES:
            return [];
        default:
            return state;
    }
}

function runoffMfrMfsTypes(state = [], action) {
    switch (action.type) {
        case Actions.SET_RUNOFF_MFR_MFS_TYPES:
            return action.runoffMfrMfsTypes;
        case Actions.LOAD_RUNOFF_MFR_MFS_TYPES:
            return [];
        default:
            return state;
    }
}

function userReducer(state = null, action) {
    switch (action.type) {
        case Actions.USER_LOGGEDIN:
            return action.user

        //case Actions.USER_LOGGEDOUT:
        //    return null

        default:
            return state
    }
}

//------------------------------------------------------------

const bstbApp = (history: History) => combineReducers({
    router: connectRouter(history),
    general: General.reducer,
    mif: Mif.reducer,
    erdr: Erdr.reducer,
    runoffView: RunoffView.reducer,
    equityInjections: EquityInjections.reducer,
    specialRules: SpecialRules.reducer,
    inputFiles,
    drmInputFiles,
    availableRiskPositions,
    riskPositions,
    modelableRiskPositions,
    adjustments,
    riskPositionModels,
    generalLedgers,
    runoffModels,
    runoffMaturityTypes,
    runoffIrrTypes,
    runoffMfrMfsTypes,
    user: userReducer
});

export default bstbApp;
