// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";

import * as Interfaces from "../../interfaces";
import { SpecialRulesTable } from "../components/SpecialRulesTable";
import {Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch} from "./imports"


const mapStateToProps = (state : State) => ({
    mifCountries: GeneralSelectors.getCountries(state),
    mifEntities: GeneralSelectors.getEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),
    riskPositionNames: GeneralSelectors.getRiskPositionNames(state),
    specialRules: Selectors.getSpecialRules(state),
});



const mapDispatchToProps = (dispatch : Dispatch) => ({
    addSaveSpecialRule: (newSpecialRule: Interfaces.SpecialRulesEntry) => dispatch(Actions.addSpecialRule(newSpecialRule)),
    deleteSpecialRule: (x: number) => dispatch(Actions.deleteSpecialRule(x)),
    editSaveSpecialRule: (x: Interfaces.SpecialRulesEntry) => dispatch(Actions.editSpecialRule(x))

});

const SpecialRulesTableContainer = connect(mapStateToProps, mapDispatchToProps)(SpecialRulesTable);

export default SpecialRulesTableContainer;