import { put, takeEvery, takeLatest, fork, select } from 'redux-saga/effects'
import config from "../config";
import * as Interfaces from "../interfaces"
import * as Actions from "./actions"
import {notification} from "antd"
import * as MainActions from "../actions";

import AuthenticatedRequest from "../http";

function* loadMifCountries() {
    const url = config.apiBaseUrl + "/api/MifCountries";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include"
        });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        const json: Interfaces.MifCountry[] = yield response.json();
        yield put(Actions.setCountries(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading Countries" });
    }
}

function* watchLoadMifCountries() {
    yield takeEvery(Actions.LOAD_COUNTRIES, loadMifCountries);
}

function* loadMifEntities() {
    const url = config.apiBaseUrl + "/api/MifEntities";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include"
        });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        const json: Interfaces.MifEntity[] = yield response.json();
        yield put(Actions.setEntities(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading Entities" });
    }
}

function* watchLoadMifEntities() {
    yield takeEvery(Actions.LOAD_ENTITIES, loadMifEntities);
}

function* loadMifCurrencies() {
    const url = config.apiBaseUrl + "/api/MifCurrencies";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include"
        });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        const json: Interfaces.MifCurrency[] = yield response.json();
        yield put(Actions.setCurrencies(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading Currencies" });
    }
}

function* watchLoadMifCurrencies() {
    yield takeEvery(Actions.LOAD_CURRENCIES, loadMifCurrencies);
}

function* loadRiskPositionNames() {
    const url = config.apiBaseUrl + "/api/RiskPositionNames";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include"
        });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        const json: Interfaces.RiskPositionName[] = yield response.json();
        yield put(Actions.setRiskPositionNames(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading Risk Positions" });
    }
}

function* watchLoadRiskPositionNames() {
    yield takeEvery(Actions.LOAD_RISK_POSITION_NAMES, loadRiskPositionNames);
}

export function* saga() {
    yield fork(watchLoadMifCountries);
    yield fork(watchLoadMifEntities);
    yield fork(watchLoadMifCurrencies);
    yield fork(watchLoadRiskPositionNames);
}
