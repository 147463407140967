// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import { Table, Icon, Input, Tooltip, Spin, notification, message, Popover, Popconfirm, Modal } from 'antd';
const { Column, ColumnGroup } = Table;

import * as React from "react";
import moment from "moment";
import { Link } from "react-router-dom"

import * as Interfaces from "../../interfaces"

export interface MifPageSelectorTableProps {
    availablePages: Interfaces.MifPageList[];
    finalisingStatus: Boolean;
    changePage: (page: number) => void;
    startPage: (page: number, entityErdrCode: string, entityName: string, referenceDate: Date) => void;
    unfinalisePage: (page: number, entityErdrCode: string, entityName: string, referenceDate: Date) => void;
    setFinalisingStatus: (finalisingStatus: boolean) => void;
    url: string;
}

interface MifPageSelectorTableState {
    filteredInfo: any,
    filteredCount: number,
    sortedInfo: any,
    pageInfo: any
}


function nonNumberSorter(a, b) {
    if (a == null && b != null) return -1;
    if (a != null && b == null) return 1;
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function currencyStringifier(record: any) {
    let finalString = "";
    for (var i = 0; i < record.MifCurrencies.length; i++) {
        if (i === 0) {
        
        finalString += record.MifCurrencies[i].Name;
        } else {
                finalString = finalString + ", " + record.MifCurrencies[i].Name;
        }

    }
    return finalString;
}


class MyTable extends Table<Interfaces.MifPageList> {}
class MyColumn extends Column<Interfaces.MifPageList> {}

export class MifPageSelectorTable extends React.Component<MifPageSelectorTableProps, MifPageSelectorTableState> {


    constructor(props) {
        super(props);

        this.state = {
            filteredInfo: null,
            filteredCount: this.props.availablePages.length,
            sortedInfo: null,
            pageInfo: 1
        };

        //binding callback functions
        this.handleSorterFilterChange = this.handleSorterFilterChange.bind(this);
        this.handleFinalisation = this.handleFinalisation.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.finalisingStatus !== prevProps.finalisingStatus && this.props.finalisingStatus == false) {
            message.destroy()
        }
    }

    handleFinalisation(Id: number, entityErdrCode: string, entityName: string, referenceDate: Date, type: string) {
        message.config({ top: 0 });        
        message.loading(type == 'start' ? 'Starting to finalize page...' : "Unfinalising page...", 0);
        type == 'start' ? this.props.startPage(Id, entityErdrCode, entityName, referenceDate) : this.props.unfinalisePage(Id, entityErdrCode, entityName, referenceDate);
        this.props.setFinalisingStatus(true);
    }

    renderDateFinalised(props) {
        return (text, record) =>
            <div>
                <span>
                    {(record.FinalisedDate == null)
                        ? <Popover
                            placement="rightTop"
                            content={
                                <div>
                                    <p><Icon type={record.LDDataCompleteFlag == true ? "check-circle" : "exclamation-circle"} style={record.LDDataCompleteFlag == true ? { color: '#52c41a' } : { color: '#faad14' }} /> LD Data</p>
                                    <p><Icon type={record.SecDepCompleteFlag == true ? "check-circle" : "exclamation-circle"} style={record.SecDepCompleteFlag == true ? { color: '#52c41a' } : { color: '#faad14' }} /> Sec Dep</p>
                                    <p><Icon type={record.RunOffCompleteFlag == true ? "check-circle" : "close-circle"} style={record.RunOffCompleteFlag == true ? { color: '#52c41a' } : { color: '#f5222d' }} /> eRDR Runoff</p>
                                    <p><Icon type={record.TM1CompleteFlag == true ? "check-circle" : "exclamation-circle"} style={record.TM1CompleteFlag == true ? { color: '#52c41a' } : { color: '#faad14' }} /> TM1 Data</p>
                                </div>
                            }
                            title="File upload status up to this month"
                        >
                            <Popconfirm
                                title="Are you sure you want to start finalizing page?"
                                onConfirm={e => {
                                record.RunOffCompleteFlag == true ? this.handleFinalisation(record.Id, record.EntityErdrCode, record.EntityName, record.ReferenceDate, 'start') : notification.error({
                                    message: 'Failed Mif Page start',
                                    description: "Incomplete eRDR runoff data",
                                    duration: 3
                                }); }}
                            >
                                {
                                    (this.props.finalisingStatus == false) ?
                                        <a href="#" onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                                            Finalize up to this month</a> :
                                        <div>Finalisation still disabled</div>}
                            </Popconfirm>
                        </Popover>
                        : moment(record.FinalisedDate).fromNow()}
                </span>
                <span>&nbsp;</span>
                <span>

                    {(record.FinalisedDate != null &&
                        props.availablePages.filter(page => page.FinalisedDate != null &&
                            page.EntityName == record.EntityName && page.ReferenceDate > record.ReferenceDate).length == 0)
                        ? <Popconfirm
                            title="Are you sure you want to unfinalise?"
                            onConfirm={e => {
                                this.handleFinalisation(record.Id, record.EntityErdrCode,
                                    record.EntityName, record.ReferenceDate, 'unfinalise');
                            }}>
                            <a href="#" onClick={e => {
                                e.preventDefault(); e.stopPropagation(); }}>Unfinalise</a>
                        </Popconfirm>
                        : ""}
                </span>
            </div>;
    }

    handleSorterFilterChange(pagination, filters, sorter) {
        //Re-calculating number of table entries after application of filters
        var filteredMifPageListCount = this.props.availablePages.filter(
            x => (filters.EntityCountryName === null || filters.EntityCountryName.length === 0) ? true : filters.EntityCountryName.indexOf(x.EntityCountryName || " ") !== -1).filter(
                x => (filters.EntityName === null || filters.EntityName.length === 0) ? true : filters.EntityName.indexOf(x.EntityName || " ") !== -1).length;


        this.setState({
            filteredInfo: filters,
            filteredCount: filteredMifPageListCount,
            sortedInfo: sorter,
            pageInfo: pagination.current
        })
    }

    render() {

        //defining the filter options for the country, in alphabetical order
        const entityFiltersUnsorted = [...new Set(this.props.availablePages.map(x => x.EntityErdrCode))].map(convertToFilter);
        const entityFilters = entityFiltersUnsorted.sort((a, b) => nonNumberSorter(b.value, a.value));
        const countryFiltersUnsorted = [...new Set(this.props.availablePages.map(x => x.EntityCountryName))].map(convertToFilter);
        const countryFilters = countryFiltersUnsorted.sort((a, b) => nonNumberSorter(b.value, a.value));

        let sortedInfo = this.state.sortedInfo;
        let filteredInfo = this.state.filteredInfo;
        let filteredCount = this.state.filteredCount;
        let pageInfo = this.state.pageInfo;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        //Configuration object for pagination of table
        var paginationConfig = {
            pagination: {
                current: pageInfo,
                pageSize: 5,
                total: filteredCount
            }
        }

        return (
            <MyTable
                className="mif-page-selector"
                size="small"
                dataSource={this.props.availablePages}
                rowKey="Id"
                onRowClick={record => {

                    //TODO: Check for if the finalised / unfinalised buttons have been pressed - only call the this.props.changePage if not pressed
                    notification["info"]({
                        message: 'Mif Page Selected',
                        description: record.EntityErdrCode + " (" + record.EntityName + ")" + " - " + moment(record.ReferenceDate).format("MMMM YYYY"),
                        duration: 10
                    });
                    this.props.changePage(record.Id);
                   
                }}
                scroll={{ y: 300, x: 0 }}
                {...paginationConfig}
                onChange={this.handleSorterFilterChange}
            >

                <MyColumn
                    title="Country"
                    dataIndex="EntityCountryName"
                    key="EntityCountryName"
                    sorter={(a, b) => nonNumberSorter(a.EntityCountryName, b.EntityCountryName)}
                    sortOrder={sortedInfo.columnKey === "EntityCountryName" && sortedInfo.order}
                    filters={countryFilters}
                    filteredValue={filteredInfo.EntityCountryName || null}
                    onFilter={(value, record) => record.EntityCountryName.indexOf(value) === 0}                    
                    width={200} />
                <MyColumn
                    title="Entity"
                    render={(text, record) => <Tooltip title={record.EntityName} placement="rightTop">{record
                        .EntityErdrCode}</Tooltip>}
                    key="EntityName"
                    sorter={(a, b) => nonNumberSorter(a.EntityErdrCode, b.EntityErdrCode)}  
                    sortOrder={sortedInfo.columnKey === "EntityErdrCode" && sortedInfo.order}  
                    filters={entityFilters}
                    filteredValue={filteredInfo.EntityErdrCode || null}
                    onFilter={(value, record) => record.EntityErdrCode.indexOf(value) === 0}                
                    width={200}/>               
                <MyColumn
                    title="Currencies"
                    render={(record) => currencyStringifier(record)}
                    key="MifCurrencies"
                    width={200}/>
                <MyColumn
                    title="Date"
                    render={(text, record) => moment(record.ReferenceDate).format("MMMM YYYY")}
                    key="ReferenceDate"
                    sorter={(a, b) => -nonNumberSorter(a.ReferenceDate, b.ReferenceDate)}
                    sortOrder={sortedInfo.columnKey === "ReferenceDate" && sortedInfo.order} 
                    width={200}/>
                <MyColumn
                    title="Submitted"
                    render={(text, record) => <span>{moment(record.SubmissionDate).fromNow()}</span>}
                    key="SubmissionDate"
                    sorter={(a, b) => -nonNumberSorter(a.SubmissionDate, b.SubmissionDate)}
                    sortOrder={sortedInfo.columnKey === "SubmissionDate" && sortedInfo.order}
                    width={200}/>
                <MyColumn
                    title="Finalised"
                    render={this.renderDateFinalised(this.props)}
                    key="FinalisedDate"
                    sorter={(a, b) => -nonNumberSorter(a.FinalisedDate, b.FinalisedDate)}
                    sortOrder={sortedInfo.columnKey === "FinalisedDate" && sortedInfo.order}
                    width={200}/>
            </MyTable>
        );
    }
}
