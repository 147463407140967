// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";

import * as Interfaces from "../../interfaces";
import { RunoffViewTableLiability } from "../components/RunoffViewTableLiability";
import { Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch } from "./imports"


const mapStateToProps = (state: State, ownProps) => ({
    selectedEntity: ownProps.selectedEntity,
    selectedCurrency: ownProps.selectedCurrency,
    selectedDate: ownProps.selectedDate,
    ccyCode: ownProps.ccyCode,
    runoffDataLiability: Selectors.getLiability(state)
});


const mapDispatchToProps = (dispatch: Dispatch) => ({

});

const RunoffViewTableLiabilityContainer = connect(mapStateToProps, mapDispatchToProps)(RunoffViewTableLiability);

export default RunoffViewTableLiabilityContainer;