import UrlMap from "../UrlMap"

import * as Actions from "./actions"
import * as GeneralActions from "../general/actions"

export const urls = [
    new UrlMap("MifData/:pageId?",
        (params: { pageId?: string }) => [
            Actions.setLoadingStatus(true),
            Actions.loadAvailablePages(true),
            GeneralActions.loadCountries(),
            GeneralActions.loadEntities(),
            GeneralActions.loadCurrencies(),
            Actions.resetMifPagesFull(params.pageId),
            Actions.loadMifPageFull(params.pageId && parseInt(params.pageId)),
        ])
];