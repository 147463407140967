import * as React from "react";
import * as ReactDOM from "react-dom";
import RiskPositionModelTableContainer from "../containers/RiskPositionModelTableContainer";

const RiskPositionModelData = (props) =>(
    <div>
        <div style={{ paddingLeft: "25px", paddingTop: "25px" }} />
        <RiskPositionModelTableContainer />

    </div>
);

export default RiskPositionModelData;


    