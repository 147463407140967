import { combineReducers } from "redux"
import { Map } from "immutable"
import * as Actions from "./actions";
import * as Interfaces from "../interfaces";

export type State = {
    equityInjections: Interfaces.EquityInjectionEntry[]
}

function equityInjections(state = [], action) {
    switch (action.type) {
        case Actions.SET_EQUITY_INJECTIONS:
            return action.equityInjections;
        default:
            return state;
    }
}


export const reducer = combineReducers<State>({
    equityInjections
});