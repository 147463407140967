import * as React from "react";

import { Tabs } from "antd";
const TabPane = Tabs.TabPane;
import MifPageContainer from "../containers/MifPageContainer";
import MifPageAggregatedContainer from "../containers/MifPageAggregatedContainer";

export default class MifTabs extends React.Component<any, undefined> {
    render() {
        return (
            <div style={{ paddingLeft: "25px" }}>               
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Raw" key="1"><MifPageContainer /></TabPane>
                    <TabPane tab="Aggregated" key="2"><MifPageAggregatedContainer /></TabPane>
                </Tabs>
            </div>
        );
    }
}