// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";
import { Table, Badge, Menu, Icon, Button, Input } from 'antd';
const { Column, ColumnGroup } = Table;
import * as Immutable from "immutable"

interface EditableCellProps {
    editableStatus: boolean,
    value: any,
    editStateValue: (value: any) => void,
}

interface EditableCellState
{
    value: any,
    editableStatus: boolean,   
}


export default class EditableCell extends React.Component<EditableCellProps, EditableCellState> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: props.value,
            editableStatus: props.editableStatus || false,          
        };
    }

    //Change the state of the RiskPositionMappingTable parent component through it's function
    handleChange(e) {
        this.setState({ value: e.target.value, editableStatus: this.state.editableStatus });
        this.props.editStateValue(e.target.value);

    };

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({ editableStatus: nextProps.editableStatus });
    }

    render() {
        const value = this.state.value;
        const editableStatus = this.state.editableStatus;
        return (
            <div>
                {
                    editableStatus ?
                        <div>
                            <Input
                                value={value != null ? value.toString() : ""}
                                onChange={e => this.handleChange(e)}
                            />
                        </div>
                        :
                        <div className="editable-row-text">
                            {value != null ? value.toString() : " "}
                        </div>
                }
            </div>
        );
    }
};