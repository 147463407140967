import * as Interfaces from "../interfaces";


export type SELECT_DATE = "General/SELECT_DATE";
export const SELECT_DATE: SELECT_DATE = "General/SELECT_DATE";
export type SelectDateAction = {
    type: SELECT_DATE,
    date: Date,
}
export function selectDate(date: Date): Action {
    return {
        type: SELECT_DATE,
        date: date
    }
}

export type LOAD_COUNTRIES = "General/LOAD_COUNTRIES";
export const LOAD_COUNTRIES:LOAD_COUNTRIES = "General/LOAD_COUNTRIES";
export type LoadCountriesAction = {
    type: LOAD_COUNTRIES,
}
export function loadCountries() : Action {
    return {
        type: LOAD_COUNTRIES
    }
}

export type SET_COUNTRIES = "General/SET_COUNTRIES";
export const SET_COUNTRIES:SET_COUNTRIES = "General/SET_COUNTRIES";
export type SetCountriesAction = {
    type: SET_COUNTRIES,
    files: Interfaces.MifCountry[],
}
export function setCountries(files: Interfaces.MifCountry[]): Action {
    return {
        type: SET_COUNTRIES,
        files: files
    }
}

export type SELECT_COUNTRY = "General/SELECT_COUNTRY";
export const SELECT_COUNTRY:SELECT_COUNTRY = "General/SELECT_COUNTRY";
export type SelectCountryAction = {
    type: SELECT_COUNTRY,
    id: number,
}
export function selectCountry(id:number) : Action {
    return {
        type: SELECT_COUNTRY,
        id: id
    }
}

export type LOAD_ENTITIES = "General/LOAD_ENTITIES";
export const LOAD_ENTITIES:LOAD_ENTITIES = "General/LOAD_ENTITIES";
export type LoadEntitiesAction = {
    type: LOAD_ENTITIES,
}
export function loadEntities() : Action {
    return {
        type: LOAD_ENTITIES
    }
}

export type SET_ENTITIES = "General/SET_ENTITIES";
export const SET_ENTITIES:SET_ENTITIES = "General/SET_ENTITIES";
export type SetEntitiesAction = {
    type: SET_ENTITIES,
    files: Interfaces.MifEntity[],
}
export function setEntities(files: Interfaces.MifEntity[]): Action {
    return {
        type: SET_ENTITIES,
        files: files
    }
}

export type SELECT_ENTITY = "General/SELECT_ENTITY";
export const SELECT_ENTITY:SELECT_ENTITY = "General/SELECT_ENTITY";
export type SelectEntityAction = {
    type: SELECT_ENTITY,
    id: number,
}
export function selectEntity(id:number) : Action {
    return {
        type: SELECT_ENTITY,
        id: id
    }
}

export type LOAD_CURRENCIES = "General/LOAD_CURRENCIES";
export const LOAD_CURRENCIES:LOAD_CURRENCIES = "General/LOAD_CURRENCIES";
export type LoadCurrenciesAction = {
    type: LOAD_CURRENCIES,
}
export function loadCurrencies() : Action {
    return {
        type: LOAD_CURRENCIES
    }
}

export type SET_CURRENCIES = "General/SET_CURRENCIES";
export const SET_CURRENCIES:SET_CURRENCIES = "General/SET_CURRENCIES";
export type SetCurrenciesAction = {
    type: SET_CURRENCIES,
    files: Interfaces.MifCurrency[],
}
export function setCurrencies(files: Interfaces.MifCurrency[]): Action {
    return {
        type: SET_CURRENCIES,
        files: files
    }
}

export type SELECT_CURRENCY = "General/SELECT_CURRENCY";
export const SELECT_CURRENCY:SELECT_CURRENCY = "General/SELECT_CURRENCY";
export type SelectCurrencyAction = {
    type: SELECT_CURRENCY,
    id: number,
}
export function selectCurrency(id:number) : Action {
    return {
        type: SELECT_CURRENCY,
        id: id
    }
}

export type LOAD_RISK_POSITION_NAMES = "General/LOAD_RISK_POSITION_NAMES";
export const LOAD_RISK_POSITION_NAMES : LOAD_RISK_POSITION_NAMES = "General/LOAD_RISK_POSITION_NAMES";
export type LoadRiskPositionNamesAction = {
    type: LOAD_RISK_POSITION_NAMES,
}
export function loadRiskPositionNames() : Action {
    return {
        type: LOAD_RISK_POSITION_NAMES,
    }
}

export type SET_RISK_POSITION_NAMES = "General/SET_RISK_POSITION_NAMES";
export const SET_RISK_POSITION_NAMES : SET_RISK_POSITION_NAMES = "General/SET_RISK_POSITION_NAMES";
export type SetRiskPositionNamesAction = {
    type: SET_RISK_POSITION_NAMES,
    riskPositionNames : Interfaces.RiskPositionName[],
}
export function setRiskPositionNames(riskPositionNames: Interfaces.RiskPositionName[]) : Action {
    return {
        type: SET_RISK_POSITION_NAMES,
        riskPositionNames
    }
}


export type OtherAction = { type: "General/Default" }
export const OtherAction : OtherAction = {type: "General/Default"}

export type Action =
    SelectDateAction | LoadCountriesAction | SetCountriesAction | SelectCountryAction |
    LoadEntitiesAction | SetEntitiesAction | SelectEntityAction |
    LoadCurrenciesAction | SetCurrenciesAction | SelectCurrencyAction |
    LoadRiskPositionNamesAction | SetRiskPositionNamesAction |
    OtherAction
