// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX


//This component is a mix of presentational and container.

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../actions";
import { Table, message, Upload, Button, Icon } from "antd";
const { Column, ColumnGroup } = Table;
import * as Interfaces from "../interfaces";
import moment from "moment";
import UploadDRMFileFormContainer from "./UploadDRMFileFormContainer";

class MyTable extends Table<Interfaces.DrmInputFile> { }

class MyColumn extends Column<Interfaces.DrmInputFile> { }

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

//Need to add the Overwrite button here

export class DrmFileUploader extends React.Component<any, any> {

    render() {

        return (
            <div>

                <div style={{ paddingLeft: "10px", paddingTop: "37px" }}>
                    <UploadDRMFileFormContainer />
                </div>               
                <br/>
                <div style={{ paddingLeft: "25px" }}>
                    <MyTable className="import-page" dataSource={this.props.drmInputFiles} rowKey={x => x.Id.toString()}>
                        <MyColumn
                            title="Filename"
                            dataIndex="FileName"
                            sorter={(a, b) => nonNumberSorter(a.FileName, b.FileName)}
                            width={150}/>

                        <MyColumn
                            title="Status"
                            dataIndex="Status"
                            sorter={(a, b) => nonNumberSorter(a.Status, b.Status)}
                            filters={this.props.drmInputFiles !== null ? [...new Set(this.props.drmInputFiles.map(x => x.Status))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                            onFilter={(value, record) => record.Status.indexOf(value) === 0}
                            width={100}/>

                        <MyColumn
                            title="Date"
                            render={(text, record) => moment(record.DateSubmitted).format("D MMMM YYYY h:mm:ss a")}
                            key="DateSubmitted"
                            sorter={(a, b) => nonNumberSorter(a.DateSubmitted, b.DateSubmitted)}
                            width={100} />

                        <MyColumn
                            title="User Name"
                            dataIndex="UserFullName"
                            sorter={(a,b) => nonNumberSorter(a.UserFullName, b.UserFullName)}
                            filters={this.props.drmInputFiles !== null ? [...new Set(this.props.drmInputFiles.map(x => x.UserFullName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                            onFilter={(value, record) => record.UserFullName.indexOf(value) === 0}
                            width={100} />

                    </MyTable>
                </div>
            </div>
        );
    }
}

var DrmFileUploaderContainer: {} = connect(
    (state: any) => ({ drmInputFiles: state.drmInputFiles }),
    dispatch => ({} as any)
)(DrmFileUploader);

export default DrmFileUploaderContainer as any;



