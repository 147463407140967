import * as React from "react";
import * as ReactDOM from "react-dom";
import RiskPositionTableContainer from "../containers/RiskPositionTableContainer";

const RiskPositionData = (props) =>(
    <div>
        <div style={{ paddingLeft: "25px", paddingTop: "25px" }} />
        <RiskPositionTableContainer />

    </div>
);

export default RiskPositionData;


    