// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";
import { Table, Badge, Menu, Icon, Button, Input, Popconfirm, notification } from 'antd';
const { Column, ColumnGroup } = Table;
import * as Immutable from "immutable";
import moment from "moment";
import DropDownCell from "./DropDownCell"
import EditableCell from "./EditableCell"

export interface RiskPositionTableProps {
    riskPositions: Interfaces.RiskPositionEntry[],
    addSaveRiskPosition: (x: Interfaces.RiskPositionEntry) => void;
    deleteRiskPosition: (x: number) => void;
    loadRiskPositions: () => void;
}

interface RiskPositionsTableState {
    data: Immutable.Map<number, Interfaces.RiskPositionEntry>,
    filteredInfo: any,
    filteredCount: number,
    sortedInfo: any,
    pageInfo: any
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

class MyTable extends Table<Interfaces.RiskPositionEntry> { }
class MyColumn extends Column<Interfaces.RiskPositionEntry> { }

export class RiskPositionTable extends React.Component<RiskPositionTableProps, RiskPositionsTableState> {
    constructor(props) {
        super(props);
        this.state = {
            data: Immutable.Map<number, Interfaces.RiskPositionEntry>(),
            filteredInfo: null,
            filteredCount: this.props.riskPositions.length,
            sortedInfo: null,
            pageInfo: 1
        };

        //binding callback functions
        this.handleSorterFilterChange = this.handleSorterFilterChange.bind(this);
    }

    getEditable(recordId: number) {
        return this.state.data.get(recordId);
    }

    setEditable(recordId: number, record: Interfaces.RiskPositionEntry) {
        const newOuter = this.state.data.set(recordId, record);
        this.setState({ data: newOuter });

    }

    //Function to change the parent state whenever an Editable cell's value is changed
    handleEditableCellValueChange = (recordId: number, fieldName: string) =>
        (value: any) => {
            const oldValue = this.state.data.get(recordId);
            var update = {};
            if (fieldName === "Name") {
                update[fieldName] = value;
            }
            else {
                update[fieldName] = parseInt(value);
            }
            
            const newValue = Object.assign({}, oldValue, update);
            this.setEditable(recordId, newValue);
        }
    
    handleSave = (record: Interfaces.RiskPositionEntry) => {
        var data = this.state.data.get(record.Id);
        
        this.props.addSaveRiskPosition(data);
        const newData = this.state.data.delete(record.Id);
        this.setState({ data: newData });
           
        }
    
    handleCancel = (recordId: number) => {
        const imm = this.state.data;
        var newStatedata = imm.delete(recordId);
        this.setState({ data: newStatedata });
    }

    handleAdd = () => {

        const newData: Interfaces.RiskPositionEntry = {
            Id: 0,
            Name: null,
            DiffPositionFlag: 2,
            ValidFlag: 0,
            EffectiveFrom: null,
            EffectiveFromUserId: null,
            EffectiveFromUserFullName: null,
            EffectiveTo: null,
            EffectiveToUserId: null,
            EffectiveToUserFullName: null,
        };

        const imm = this.state.data;
        var newStateData = imm.set(0, newData);
        this.setState({ data: newStateData, filteredInfo: null, sortedInfo: null, pageInfo: 1  });

    };

    handleDelete = (id) => {
        this.props.deleteRiskPosition(id);
    }

    handleSorterFilterChange(pagination, filters, sorter) {

        //Re-calculating number of table entries after application of filters
        var filteredRiskPositionCount = this.props.riskPositions.filter(
            x => (filters.Name === null || filters.Name.length === 0) ? true : filters.Name.indexOf(x.Name) !== -1).filter(
            x => (filters.DiffPositionFlag === null || filters.DiffPositionFlag.length === 0) ? true : filters.DiffPositionFlag.indexOf(x.DiffPositionFlag) !== -1).filter(
            x => (filters.ValidFlag === null || filters.ValidFlag.length === 0) ? true : filters.ValidFlag.indexOf(x.ValidFlag) !== -1).length;


        this.setState({
            filteredInfo: filters,
            filteredCount: filteredRiskPositionCount,
            sortedInfo: sorter,
            pageInfo: pagination.current
        })
    }

    render() {

        let sortedInfo = this.state.sortedInfo;
        let filteredInfo = this.state.filteredInfo;
        let filteredCount = this.state.filteredCount;
        let pageInfo = this.state.pageInfo;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        pageInfo = pageInfo;

        //Configuration object for pagination of table
        var paginationConfig = {
            pagination: {
                current: pageInfo,
                pageSize: 10,
                total: filteredCount
            }
        }

        //Adding new row to table datasource when add button is clicked 
        var riskPositionsDataSource = this.props.riskPositions.slice();
        const addRiskPosition = this.state.data.get(0);
        if (!!addRiskPosition)
            riskPositionsDataSource.unshift(addRiskPosition);

        return (
            <div className="riskPositionModelsTableClass">
                <MyTable className="riskPositions-table" size="small" dataSource={riskPositionsDataSource} rowKey="Id" {...paginationConfig} onChange={this.handleSorterFilterChange}>

                    <MyColumn
                        title="Risk Position"
                        render={(text, record) => <EditableCell value={record.Name} editableStatus={record.Id === 0} editStateValue={this.handleEditableCellValueChange(record.Id, "Name")} />}
                        key="Name"
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.Name.localeCompare(a.Name) : null }}
                        sortOrder={sortedInfo.columnKey === "Name" && sortedInfo.order}
                        filters={this.props.riskPositions !== null ? [...new Set(this.props.riskPositions.map(x => x.Name))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        filteredValue={filteredInfo.Name || null}
                        onFilter={(value, record) => record.Name.indexOf(value) === 0}
                        width={"40%"}
                    />
                    <MyColumn
                        title="Difference Position"
                        render={(text, record) => <DropDownCell options={[{ Id: 1, Name: "Yes" }, { Id: 2, Name: "No" }]} value={record.DiffPositionFlag} removeDefault editableStatus={record.Id === 0} editStateValue={this.handleEditableCellValueChange(record.Id, "DiffPositionFlag")} />}
                        key="DiffPositionFlag"
                        sorter={(a, b) => { return (a !== null && b !== null) ? a.DiffPositionFlag - b.DiffPositionFlag : null }}
                        sortOrder={sortedInfo.columnKey === "DiffPositionFlag" && sortedInfo.order}
                        filters={[{ value: "1", text: "Yes" }, { value: "2", text: "No" }]}
                        filteredValue={filteredInfo.DiffPositionFlag || null}
                        onFilter={(value, record) => record.DiffPositionFlag == value}
                        width={"20%"}
                    />                    
                    <MyColumn
                        title="Valid"
                        render={(record) => (record.ValidFlag === 1) ? "Yes" : "No"}
                        key="ValidFlag"
                        sorter={(a, b) => { return (a !== null && b !== null) ? a.ValidFlag - b.ValidFlag : null }}
                        sortOrder={sortedInfo.columnKey === "ValidFlag" && sortedInfo.order}
                        filters={[{ value: "1", text: "Yes" }, { value: "0", text: "No" }]}
                        filteredValue={filteredInfo.ValidFlag || null}
                        onFilter={(value, record) => record.ValidFlag == value}
                        width={"10%"}
                    />
                    <MyColumn
                        title="Last Updated"
                        render={(text, record) => record.Id !== 0 ? moment(record.EffectiveFrom).format("D MMMM YYYY h:mm:ss a") : ""}
                        key="EffectiveFrom"
                        sorter={(a, b) => nonNumberSorter(a.EffectiveFrom, b.EffectiveFrom)}
                        sortOrder={sortedInfo.columnKey === "EffectiveFrom" && sortedInfo.order}               
                        width={"20%"}                    
                    />

                    <MyColumn
                        title="Actions"
                        key="action"
                        render={(text, record) => (
                            this.getEditable(record.Id)
                                ? <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSave(record);
                                    }}>Save</a>
                                    <span className="ant-divider" />
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleCancel(record.Id);
                                    }}>Cancel</a>
                                </span>
                                : record.ValidFlag === 1
                                    ? <span>
                                        <Popconfirm title="Sure to Delete?" onConfirm={(e) => {
                                            this.handleDelete(record.Id);

                                        }}>
                                            <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                        </Popconfirm>

                                    </span>
                                    : ""
                        )}
                        width={"10%"}
                    />                   
                </MyTable>
                <br />
                <span className="adjustments-buttons">
                    {!this.state.data.has(0) ? <Button type="primary" onClick={() => this.handleAdd()}>Add</Button> : ""}
                </span>
                <br />
            </div>
        );
    }

}
