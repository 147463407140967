import { matchPath, match} from 'react-router'
import config from "./config"
import {Action} from "./actions"


export default class UrlMap<T> {
    location: string;
    handler: (params: T) => Action[];

    constructor(location: string, handler: (params: T) => any) {
        this.location = location;
        this.handler = handler;
    }

    handle(url: string) : any {
        const match: match<T> = matchPath<T>(url, { path: config.uiBaseUrl + this.location });
        if (match) {
            return this.handler(match.params);
        } else {
            return [];
        }
    }
    
}