// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";

import * as Interfaces from "../../interfaces";
import { EquityInjectionsTable } from "../components/EquityInjectionsTable";

import { Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch } from "./imports"

const mapStateToProps = (state) => ({
    equityInjections: Selectors.getEquityInjections(state),
    mifCountries: GeneralSelectors.getCountries(state),
    mifEntities: GeneralSelectors.getEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),
    riskPositionNames: GeneralSelectors.getRiskPositionNames(state)
});

const mapDispatchToProps = (dispatch) => ({
    addSaveEquityInjection: (newEquityInjection: Interfaces.EquityInjectionEntry) => dispatch(Actions.addSaveEquityInjection(newEquityInjection)),
    deleteEquityInjection: (x: number) => dispatch(Actions.deleteEquityInjection(x)),
    editSaveEquityInjection: (x: Interfaces.EquityInjectionEntry) => dispatch(Actions.editSaveEquityInjection(x))
});

const EquityInjectionsTableContainer = connect(mapStateToProps, mapDispatchToProps)(EquityInjectionsTable);

export default EquityInjectionsTableContainer;