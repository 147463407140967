// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces"

import { Table, Icon, Button, Input } from 'antd';
const { Column, ColumnGroup } = Table;

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}
function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

class MyTable extends Table<Interfaces.MifEntryAggregated> { }
class MyColumn extends Column<Interfaces.MifEntryAggregated> { }

export interface MifPageAggregatedProps {
    pageAggregations: Interfaces.MifEntryAggregated[];
    isLoading: boolean;
}

// State is never set so we use the 'undefined' type.
export class MifPageAggregated extends React.Component<MifPageAggregatedProps, undefined> {


    render() {
        const pageAggregations = this.props.pageAggregations;
        if (pageAggregations == null) {
            return <div>
                <h2 style={{ textAlign: "center" }}>{this.props.isLoading ? "Loading..." : "No Data"}</h2>
                   </div>;
        } else {
            return (
                <MyTable className="mif-page" size="small" dataSource={pageAggregations} scroll={{ y: 300 }} pagination={false} rowKey={(record => record.Id || record.RiskPositionId * 1000 + record.MifCurrencyId).toString()}>
                    <MyColumn
                        title="Aggregated Risk Position"
                        dataIndex="RiskPositionName"
                        key="RiskPositionName"
                        filters={pageAggregations !== null ? [...new Set(pageAggregations.map(x => x.RiskPositionName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        onFilter={(value, record) => record.RiskPositionName.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.RiskPositionName, b.RiskPositionName)}
                        width={100}
                    />
                    <MyColumn
                        title="Currency"
                        dataIndex="MifCurrencyName"
                        key="MifCurrencyName"
                        sorter={(a, b) => nonNumberSorter(a.MifCurrencyName, b.MifCurrencyName)}
                        width={100}
                    />
                    <MyColumn
                        className="numeric-column"
                        title="Value"
                        dataIndex="Value"
                        render={(text, record: Interfaces.MifEntryAggregated) => (record.Value !== 0) ? numberWithCommas(record.Value, record.MifCurrencyCode) : "-"}
                        key="Value"
                        sorter={(a, b) => a.Value - b.Value}
                        width={100}
                    />
                </MyTable>
            );
        }
    }
}
