// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import { Table, Icon, Input, Tooltip, notification } from 'antd';
const { Column, ColumnGroup } = Table;


import * as React from "react";
import moment from "moment";
import { Link } from "react-router-dom"

import * as Interfaces from "../../interfaces"

export interface ErdrPageSelectorTableProps {
    availablePages: Interfaces.ErdrConsolidatedPage[];
    changePage: (page: number) => void;
}


function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}


class MyTable extends Table<Interfaces.ErdrConsolidatedPage> { }
class MyColumn extends Column<Interfaces.ErdrConsolidatedPage> { }

class MyTableInner extends Table<Interfaces.ErdrPage> { }
class MyColumnInner extends Column<Interfaces.ErdrPage> { }


// State is never set so we use the 'undefined' type.
export class ErdrPageSelectorTable extends React.Component<ErdrPageSelectorTableProps, undefined> {


    render() {

        //filters
        //const entityFilters = [...new Set(this.props.availablePages.map(x => x.EntityErdrCode))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value));
        //const entityFilters = entityFiltersUnsorted.sort((a, b) => nonNumberSorter(b.value, a.value));
        //const countryFilters = [...new Set(this.props.availablePages.map(x => x.EntityCountryName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value));
        //const countryFilters = countryFiltersUnsorted.sort((a, b) => nonNumberSorter(b.value, a.value));


        const expandedRowRender = record => {
            var eRDRPages = record.ErdrPages.slice();

            return (
                <div>
                    <MyTableInner
                        className="eRDR-table-expanded"
                        pagination={false}
                        dataSource={eRDRPages}
                        rowKey={(record) => record.ErdrDataTypeName + record.Id}
                    >
                        <MyColumnInner
                            title="Data Type"
                            render={(text, record) => record.ErdrDataTypeName == "Runoff" ? "PD" : record.ErdrDataTypeName} 
                            key="PageType"
                            width={230} />
                        <MyColumnInner
                            title="Currencies"
                            dataIndex="Currencies"
                            key="Currencies"
                            width={230} />
                        <MyColumnInner
                            title="Submitted"
                            //dataIndex="SubmissionDate"
                            render={(text, record) => <span>{moment(record.SubmissionDate).fromNow()}</span>}
                            sorter={(a, b) => nonNumberSorter(a.SubmissionDate, b.SubmissionDate)}
                            key="SubmissiondDate"
                            width={230} />
                    </MyTableInner>
                </div>
            );
        }

        return (
            <div>
                <MyTable className="mif-page-selector" size="small" dataSource={this.props.availablePages}
                    expandedRowRender={expandedRowRender} rowKey="Id" pagination={this.props.availablePages.length > 10}
                    onRowClick={(record) => {
                        notification["info"]({
                            message: 'eRDR Page Selected',
                            description: record.EntityErdrCode + " (" + record.EntityName + ")" + " - " + moment(record.ReferenceDate).format("MMMM YYYY"),
                            duration: 10
                        });
                        this.props.changePage(record.Id);
                    }}
                >
                    <MyColumn
                        title="Entity"
                        render={(text, record) => <Tooltip title={record.EntityName} placement="rightTop">{record.EntityErdrCode}</Tooltip>}
                        key="EntityName"
                        filters={[...new Set(this.props.availablePages.map(x => x.EntityErdrCode))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value))}
                        onFilter={(value, record) => record.EntityErdrCode.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.EntityErdrCode, b.EntityErdrCode)}
                        width="30%" />
                    <MyColumn
                        title="Country"
                        dataIndex="EntityCountryName"
                        key="EntityCountryName"
                        filters={[...new Set(this.props.availablePages.map(x => x.EntityCountryName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value))}
                        onFilter={(value, record) => record.EntityCountryName.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.EntityCountryName, b.EntityCountryName)}
                        width="30%" />
                    <MyColumn
                        title="Date"
                        render={(text, record) => moment(record.ReferenceDate).format("MMMM YYYY")}
                        sorter={(a, b) => -nonNumberSorter(a.ReferenceDate, b.ReferenceDate)}
                        key="ReferenceDate"
                        width="30%" />
                </MyTable>
            </div>
        );

    }
}
