import { put, takeEvery, takeLatest, fork, select } from 'redux-saga/effects'
import config from '../config'
import * as Interfaces from "../interfaces"
import * as Actions from "./actions"
import * as Top from "../reducers"
import * as Selectors from "./selectors"
import * as GeneralSelectors from "../general/selectors"
import { notification } from "antd"
import moment from "moment"
import * as MainActions from "../actions";

import AuthenticatedRequest from "../http";

function mifTakeEvery<TAction extends string>(action: TAction, reducer: (act: Actions.Action & { type: TAction }) => any) {
    return takeEvery(action, reducer);
}

function* loadPageFull(action : Actions.LoadPageFullAction) {
    if (!action.id)
        return;
    const currentPageFull : Interfaces.MifPageFull = yield (select(Selectors.getPageFull(action.id)));

    if (!currentPageFull || action.forceReload) {
        try {
            const url = config.apiBaseUrl + "/api/MifPages/" + action.id.toString();
            const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            }
            const json: Interfaces.MifPageFull = yield response.json();
            yield put(Actions.addPageFull(action.id, json));
        } catch (e) {
            notification.error({message: "Error", description: "Error loading MIF page " + action.id});
        }       
    }
}

function* watchLoadMifPageFull() {
    yield mifTakeEvery(Actions.LOAD_PAGE_FULL, loadPageFull);
}

function* loadAvailablePages(action: Actions.LoadAvailablePagesAction) {
    const selectedDate: Date = yield select(GeneralSelectors.getDate);
    const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);
    const selectedEntityId: Date = yield select(GeneralSelectors.getEntity);
    const selectedCurrencyId: Date = yield select(GeneralSelectors.getCurrency);


    const currentPageIds: Interfaces.MifPageList[] = yield (select(Selectors.getAvailablePages));
    if (currentPageIds.length === 0 || action.forceReload) {
        let url = config.apiBaseUrl + "/api/MifPages?selectedCountryId=" + selectedCountryId + "&selectedEntityId=" + selectedEntityId + "&selectedCurrencyId=" + selectedCurrencyId;

        if (selectedDate !== null) {
            var selectedDateFormatted = selectedDate.toISOString();
            url += "&selectedDate=" + selectedDateFormatted;
        }
        //Commenting below line of code to resolve "The value 'null' is not valid" while parsing to Date
        //else {
        //    url += "&selectedDate=" + null;
        //}

        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            }
            const json: Interfaces.MifPageList[] = yield response.json();
            yield put(Actions.setAvailableMifPages(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading available MIF pages" });
        }
        yield put(Actions.setLoadingStatus(false));
    }
}

function* watchLoadMifPageIds() {
    yield mifTakeEvery(Actions.LOAD_AVAILABLE_PAGES, loadAvailablePages);
}

function* startMifPageData(action : Actions.StartDataAction) {

    const url = config.apiBaseUrl + `/api/MifPages/${action.id}/Start`;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT"
            }
        )
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        } else if (!response.ok) {
                    throw new Error("Error finalising")
                }
                

    } catch (e) {
        yield put(Actions.setFinalisingStatus(false));
        notification.error({ message: "Error", description: "Error starting MIF page " + action.id });
        return;
    }

    yield put(Actions.loadAvailablePages(true));
    yield put(Actions.loadMifPageFull(action.id, true))
    yield put(Actions.setFinalisingStatus(false));
    
    notification["success"]({
        message: 'Mif Page Started',
        description: action.entityErdrCode + " (" + action.entityName + ")" + " - " + moment(action.referenceDate).format("MMMM YYYY"),
        duration: 3
    });
}

function* watchStartMifPageData() {
    yield mifTakeEvery(Actions.START_DATA, startMifPageData);
}



function* unfinaliseMifPageData(action: Actions.UnfinaliseDataAction) {
    console.log('unfinaliseMifPageData()  was called.');

    const url = config.apiBaseUrl + `/api/MifPages/${action.id}/Unfinalise`;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT"
            }
        );
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        yield put(Actions.loadAvailablePages(true));
        yield put(Actions.loadMifPageFull(action.id, true));
    } catch (e) {
        notification.error({ message: "Error", description: "Error unfinalising MIF page " + action.id });
    }
    yield put(Actions.setFinalisingStatus(false));
    //notification["success"]({ message: 'Success', description: 'Succesful unfinalisation', duration: 3 });
    notification["success"]({
        message: 'Mif Page Unfinalised',
        description: action.entityErdrCode + " (" + action.entityName + ")" + " - " + moment(action.referenceDate).format("MMMM YYYY"),
        duration: 3
    });
}

function* watchUnfinaliseMifPageData() {
    yield mifTakeEvery(Actions.UNFINALISE_DATA, unfinaliseMifPageData);
}


export function* saga() {
    yield fork(watchLoadMifPageFull);
    yield fork(watchLoadMifPageIds);
    yield fork(watchStartMifPageData);
    yield fork(watchUnfinaliseMifPageData);
}