// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";

import * as Interfaces from "../../interfaces";
import { SummaryViewTable } from "../components/SummaryViewTable";
import {Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch} from "./imports"


const mapStateToProps = (state : State, ownProps) => ({
    selectedEntity: ownProps.selectedEntity,
    selectedCurrency: ownProps.selectedCurrency,
    selectedDate: ownProps.selectedDate,
    ccyCode: ownProps.ccyCode,
    summaryData: Selectors.getSummary(state),
    totalAssets: Selectors.getTotalAssets(state),
    totalLiabilities: Selectors.getTotalLiabilities(state),
    finalised: Selectors.getFinalised(state),
    differencePositionNames: Selectors.getDifferencePositions(state)
});


const mapDispatchToProps = (dispatch : Dispatch, ownProps) => ({
    handleMapping: (map) => dispatch(Actions.saveMappings(map))
});

const SummaryViewTableContainer = connect(mapStateToProps, mapDispatchToProps)(SummaryViewTable);

export default SummaryViewTableContainer;