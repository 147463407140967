// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import { RiskPositionMappingTable } from "../components/RiskPositionMappingTable";
import * as Actions from "../actions";
import * as Interfaces from "../interfaces";
import * as GeneralSelectors from "../general/selectors";


const mapStateToProps = (state) => ({
    mifCountries: GeneralSelectors.getCountries(state),
    mifEntities: GeneralSelectors.getEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),
    availableRiskPositions: state.availableRiskPositions,
    riskPositionNames: GeneralSelectors.getRiskPositionNames(state)
});

const mapDispatchToProps = (dispatch => ({
    addSaveDetailedRiskPosition: (newDetailedRP: Interfaces.RiskPositionOverride) => dispatch(Actions.addSaveDetailedRiskPosition(newDetailedRP)),
    deleteDetailedRiskPosition: (x: number) => dispatch(Actions.deleteDetailedRiskPosition(x)),
    editSaveDetailedRiskPosition: (x: Interfaces.RiskPositionOverride) => dispatch(Actions.editSaveDetailedRiskPosition(x)),
    loadRiskPositionIds: () => dispatch(Actions.loadRiskPositionIds())

}));

const RiskPositionMappingTableContainer = connect(mapStateToProps, mapDispatchToProps)(RiskPositionMappingTable);

export default RiskPositionMappingTableContainer;
