import UrlMap from "../UrlMap"

import * as Actions from "./actions"
import * as GeneralActions from "../general/actions"

export const urls = [
    new UrlMap("EquityInjections",
        () => [
            Actions.loadEquityInjections(),
            GeneralActions.loadCountries(),
            GeneralActions.loadEntities(),
            GeneralActions.loadCurrencies(),
            GeneralActions.loadRiskPositionNames(),
        ]),
];