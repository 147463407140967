// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import { MifCurrencyFilter } from "../components/MifCurrencyFilter";
import {Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch} from "./imports"

function mapStateToProps(state: State, ownProps: RouteComponentProps<{ pageId?: string }>) {
    const page = Selectors.getPageFullFromMatch(ownProps.match)(state);
    return {
        availableCurrencies: !page ? [] : page.MifPage.MifCurrencies,
        selectedCurrency: Selectors.getCurrencyFilter(state)
    }
}

const mapDispatchToProps = (dispatch : Dispatch) => ({
    handleChange: (currencyId) => dispatch(Actions.setCurrencyFilter(currencyId))
});

function mergeProps(stateProps, dispatchProps, ownProps) {
    return { ...stateProps, ...dispatchProps };
}

const MifCurrencyFilterContainer = withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(MifCurrencyFilter));

export default MifCurrencyFilterContainer;