// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces"


import { Table, Icon, Button, Input, Select } from 'antd';
const { Column, ColumnGroup } = Table;
const Option = Select.Option;


export interface ErdrLDDataPageProps {
    page: Interfaces.ErdrLDDataEntry[];
    isLoading: boolean;
}

class MyTable extends Table<Interfaces.ErdrLDDataEntry> { }
class MyColumn extends Column<Interfaces.ErdrLDDataEntry> { }

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}


export default class ErdrLDDataPage extends React.Component<ErdrLDDataPageProps, undefined> {

    render() {


        //var page = this.props.page;

        //const planningAccountFilters = this.props.page !== null ? [...new Set(this.props.page.map(x => x.PlanningAccount))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null;
        //const planningAccountFilters = page !== null ? planningAccountFiltersUnsorted.sort((a, b) => nonNumberSorter(b.value, a.value)) : null;


        if (this.props.page == null) {
            return <div>
                <h2 style={{ textAlign: "center" }}>{this.props.isLoading ? "Loading..." : "No Data"}</h2>
                   </div>;
        } else {
            return (

                <MyTable className="erdr-page" size="small" dataSource={this.props.page} pagination={this.props.page.length > 10} rowKey="Id">
                    <MyColumn
                        title="Planning Account"
                        dataIndex="PlanningAccount"
                        key="PlanningAccount"
                        filters={this.props.page !== null ? [...new Set(this.props.page.map(x => x.PlanningAccount))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        onFilter={(value, record) => record.PlanningAccount.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.PlanningAccount, b.PlanningAccount)}
                        width={100} />
                    <MyColumn
                        title="Asset/Liability"
                        dataIndex="AssetOrLiability"
                        key="AssetOrLiability"
                        filters={[{ text: "A", value: "A" }, { text: "L", value: "L" }]}
                        onFilter={(value, record) => record.AssetOrLiability === value}
                        sorter={(a, b) => nonNumberSorter(a.AssetOrLiability, b.AssetOrLiability)}
                        width={70} />
                   
                    <MyColumn
                        title="Currency"
                        dataIndex="CurrencyName"
                        filters={this.props.page !== null ? [...new Set(this.props.page.map(x => x.CurrencyName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        onFilter={(value, record) => record.CurrencyName.indexOf(value) === 0}
                        sorter={(a, b) => a.CurrencyName.localeCompare(b.CurrencyName)} 
                        key="CurrencyName"
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Net Book Value"
                        render={(text, record: any) => (record.NetBookValue !== null) ? numberWithCommas(record.NetBookValue, record.CurrencyCode) : "-"}
                        sorter={(a, b) => nonNumberSorter(a.NetBookValue, b.NetBookValue)}
                        key="NetBookValue"
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Clean Price Value"
                        render={(text, record: any) => (record.CleanPriceValue !== null) ? numberWithCommas(record.CleanPriceValue, record.CurrencyCode) : "-"}
                        sorter={(a, b) => nonNumberSorter(a.CleanPriceValue, b.CleanPriceValue)}
                        key="CleanPriceValue"
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Accruals Value"
                        render={(text, record: any) => (record.AccrualsValue !== null) ? numberWithCommas(record.AccrualsValue, record.CurrencyCode) : "-"}
                        sorter={(a, b) => nonNumberSorter(a.AccrualsValue, b.AccrualsValue)}
                        key="AccrualsValue"
                        width={100} />



                </MyTable>
            );

        }
    }
}