// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../../interfaces";

import { Table, Icon, Button, Input, Popconfirm } from "antd";
const { Column, ColumnGroup } = Table;
import DropDownCell from "../../components/DropDownCell"

import * as Immutable from "immutable";
import moment from "moment";

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { mimimumFractionDigits: 2, maximumFractionDigits: 2});
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

function percentageWithCommas(x?) {
    try {
        return (x * 100).toLocaleString("de-DE", { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%';
    } catch (e) {
        return (x * 100).toFixed(2) + '%';
    }
}

function TrendArrow(x?) { // fgreil: Expects a fraction, not a percentage
    try {
        var percentage = Math.round(x * 100); // rounded to full percentage
        return ((Math.abs(percentage) < 1) ? "\u2022" : ((x < 0) ? "\u25bc" : ((x > 0) ? "\u25b2" : "?")));
    } catch (e) {
        return "-";
    }
}


export interface SummaryViewTableProps {   
    selectedEntity: number,
    selectedCurrency: number,
    selectedDate: any,
    ccyCode: string,
    summaryData: Interfaces.SummaryViewEntry[],
    totalAssets: number,
    totalLiabilities: number,
    differencePositionNames: Interfaces.RiskPositionName[],
    finalised: boolean,
    handleMapping: (map: Immutable.Map<number, number>) => any,
}

export interface SummaryViewTableState {
    data: Immutable.Map<number, number>
}

class MyTable extends Table<Interfaces.SummaryViewEntry> { }
class MyColumn extends Column<Interfaces.SummaryViewEntry> { }

// var myColumnStyle = { 'white-space': 'unset' };

class TotalTable extends Table<{ assetsName: string, assets: number, liabilities: number, liabilitiesName: string}> { }
class TotalColumn extends Column<{ assetsName: string, assets: number, liabilities: number, liabilitiesName: string}> { }

export class SummaryViewTable extends React.Component<SummaryViewTableProps, SummaryViewTableState> {
    constructor(props) {
        super(props);
        this.state = { data: Immutable.Map<number, number>() };
        this.handleMapping = this.handleMapping.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    UNSAFE_componentWillReceiveProps({ summaryData, selectedEntity, selectedCurrency, selectedDate }, nextContext): void {
        if (summaryData != this.props.summaryData || selectedEntity != this.props.selectedEntity || selectedCurrency != this.props.selectedCurrency || selectedDate != this.props.selectedDate )
            this.setState({ data: Immutable.Map<number, number>() });
    }

    handleMapping(generalLedger: number) {
        return (riskPos: number) => {
            var newState = this.state.data.set(generalLedger, riskPos);
            this.setState({ data: newState });
        }
    }

    handleSave() {
        this.props.handleMapping(this.state.data);
        this.setState({ data: Immutable.Map<number, number>() });
    }



    render() {
        const myColTitleStyle = {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        } as React.CSSProperties;

        const myColTitleStyleRight = {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            float: 'right'
        } as React.CSSProperties;

        const myTabTitleStyle = {
            fontWeight: 'bold'
        } as React.CSSProperties;

        return (
                <div className="summary-view-table">
                    <MyTable className="summary-view-MIF-eRDR-difference" size="small" dataSource={this.props.summaryData}
                        rowKey={(record) => (record.GeneralLedgerId).toString()}
                        locale={{ emptyText: 'No suitable summary data found' }}
                        pagination={false}
                        showHeader={true}
                        scroll={{ x: 1350 }}
                >
                        <MyColumn
                            title={<div style={myColTitleStyle}>Ledger Account</div>}
                            dataIndex="GeneralLedgerName"
                            key="Ledger"
                            width="100"
                            // fixed="left"
                        />
                        <MyColumn
                            className="numeric-column"
                                title={<div style={myColTitleStyleRight}>MIF adjusted Value</div>}
                            render={(record) => (record.AdjustedValue) ? numberWithCommas(record.AdjustedValue, this.props.ccyCode) : 0}
                                key="Mif"
                            width="100"
                            // fixed="left"
                        />
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>eRDR/ FBSR Value</div>}
                            render={(record) => (record.ErdrValue) ? numberWithCommas(record.ErdrValue, this.props.ccyCode) : 0}
                                key="ErdrValue"
                                width="100"
                        />
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>Difference (MIF-eRDR)</div>}
                            render={(record) => (record.DifferenceValue) ? numberWithCommas(record.DifferenceValue, this.props.ccyCode) : 0}
                                key="DifferenceValue"
                                width="100"
                        />
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>Diff. in %</div>}
                            render={(record) => ((record.ErdrValue + record.DifferenceValue) !== 0 ) ?
                                percentageWithCommas((record.DifferenceValue / (record.ErdrValue + record.DifferenceValue))) : "-"}
                                key="DifferenceInPercentage"
                                width="20"
                        />
                        {/* Difference Position */}
                        <MyColumn
                            title={<div style={myColTitleStyle}>Difference Position</div>}
                            key="DifferencePosition"
                                render={(record: Interfaces.SummaryViewEntry) =>
                                    <DropDownCell options={this.props.differencePositionNames}
                                        value={record.DifferenceRiskPosition}
                                        removeDefault
                                        placeholder="Please Select"
                                        editableStatus={!this.props.finalised}
                                        editStateValue={this.handleMapping(record.GeneralLedgerId)}
                                        fixedValue={record.DifferenceRiskPositionName}
                                        />}
                                width="150"
                        />
                        {/* DELTA eRDR Value */ }
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>{'\u0394'}: eRDR Value</div>}
                            render={(record) => (record.ErdrValueDifferenceInPercent !== 0) ?
                                percentageWithCommas(record.ErdrValueDifferenceInPercent) : "0.00%"}
                            key="ErdrPreviousPeriodPercentage"
                            width="20"
                        />
                        {/* Trend 1 */}
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>{'\u25b2\u25bc'}</div>}
                            render={(record) => (TrendArrow(record.ErdrValueDifferenceInPercent))}
                            key="FirstArrow"
                            width="20"
                        />

                        {/* Prev. Month: eRDR Value*/}
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>Prev. Month: eRDR/ FBSR Value</div>}
                            render={(record) => (record.PreviousErdrValue) ? numberWithCommas(record.PreviousErdrValue, this.props.ccyCode) : "-"}
                            key="ErdrPreviousPeriod"
                            width="150"
                        />

                        {/* DELTA Diff. (MIF-eRDR) */}
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>{'\u0394'}: Diff. (MIF-eRDR)</div>}
                            render={(record) => (record.DifferenceValueDifferenceInPercent !== 0) ?
                                percentageWithCommas(record.DifferenceValueDifferenceInPercent) : "-"}
                            key="DiffPreviousPeriodPercentage"
                            width="20"
                        />

                        {/* Trend 2 */}
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>{'\u25b2\u25bc'}</div>} 
                            render={(record) => (TrendArrow(record.DifferenceValueDifferenceInPercent ))}
                            key="SecondArrow"
                            width="20"
                        />
                        {/* Prev. Month: Diff. (MIF-eRDR) */}
                        <MyColumn
                            className="numeric-column"
                            title={<div style={myColTitleStyleRight}>Prev. Mon.: Diff. (MIF-eRDR)</div>}
                            render={(record) => (record.PreviousDifferenceValue) ? numberWithCommas(record.PreviousDifferenceValue, this.props.ccyCode) : "-"}
                            key="DiffPreviousPeriod"
                            width="100"
                        />
                    </MyTable>

                {/* ---------------------------------------------------------------------------------------- */}
                {/*  2nd part of the summary table                                                           */}
                {/* ---------------------------------------------------------------------------------------- */}

                    <h3 style={{ marginLeft: 5, marginTop: 30 }}>Balance Sheet after reconcilation</h3>
                <TotalTable
                        showHeader={false}
                        dataSource={[{
                            assetsName: this.props.totalAssets !== null ? "Total Assets (ILG)" : "",
                            assets: this.props.totalAssets || 0,
                            liabilitiesName: this.props.totalLiabilities !== null ? "Total Liabilities (ILG)" : "",
                            liabilities: this.props.totalLiabilities || 0
                        }]}
                                rowKey={(record) => "Totals"}
                                pagination={false}
                        size="middle"
                        locale={{ emptyText: 'No suitable total assets/ liabilites (ILG) found.' }}
                    >
                        <TotalColumn render={(record) => record.assetsName}
                            width="100px"
                            key="assetsName" />

                        <TotalColumn className="numeric-column"
                            width="100px"
                            render={(record) => (record.assets !== 0) ? numberWithCommas(record.assets, this.props.ccyCode) : 0}
                            key="assets"
                        />
                        <TotalColumn width="100px" />
                        <TotalColumn render={(record) => record.liabilitiesName}
                            width="100px"
                            key="liabilitiesName" />
                        <TotalColumn className="numeric-column"
                            width="100px"
                                    render={(record) => (record.liabilities !== 0) ? numberWithCommas(record.liabilities, this.props.ccyCode) : 0}
                                    key="liabilities"
                        />

                        <TotalColumn width = "200px" />
                    </TotalTable>


                    <div className="runoffView-buttons">
                        <Button
                            onClick={this.handleSave}
                            disabled={this.props.finalised}>Save</Button>
                    </div> 
            </div>
        );
    }
}
