// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from 'react';
import { Select } from 'antd';
import * as Interfaces from "../../interfaces"; 
const Option = Select.Option;

export interface MifCurrencyFilterProps {
    availableCurrencies: Interfaces.MifCurrency[];
    selectedCurrency: number;
    handleChange: (currency: number) => void;   
}

function createAvailableCurrencyEntity(x: Interfaces.MifCurrency) {
    return {
        Id: x.Id,
        Name: x.Name
    };
}

export class MifCurrencyFilter extends React.Component<MifCurrencyFilterProps, undefined> {

    render() {
        const availableCurrencyArray = this.props.availableCurrencies.map(x =>  createAvailableCurrencyEntity(x));
        return (
            <div>
                <span>

                    <Select
                        style={{ width: 200, paddingLeft: "25px" }}
                        placeholder="Currency Filter"
                        optionFilterProp="children"
                        onChange={x => this.props.handleChange(parseInt(x as string))}
                        defaultValue="0"
                        value={this.props.selectedCurrency.toString()}
                    >
                        <Option key="0">Currency Filter</Option>
                        {availableCurrencyArray.map(c => <Option key = {c.Id}>{c.Name}</Option>)}
                    </Select>
                </span>
                </div>
        );
    }
}