import { connect } from "react-redux";
import { AdjustmentsFilter } from "../components/AdjustmentsFilter";
import * as Interfaces from "../interfaces";
//import { Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch } from "./imports"
import * as Actions from "../actions";
import * as GeneralActions from "../general/actions"
//import * as Selectors from "../selectors"
import * as GeneralSelectors from "../general/selectors"
import * as Top from "../reducers"
type State = Top.State
type Dispatch = (action: any) => void

const mapStateToProps = (state: State) => ({
    mifCountries: GeneralSelectors.getCountries(state),
    availableEntities: GeneralSelectors.getAvailableEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),

    selectedDate: GeneralSelectors.getDate(state),
    selectedMifCountry: GeneralSelectors.getCountry(state),
    selectedMifEntity: GeneralSelectors.getEntity(state),
    selectedMifCurrency: GeneralSelectors.getCurrency(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateAdjustmentsTable: () => dispatch(Actions.loadAdjustments()),
    selectDate: (selectedDate) => dispatch(GeneralActions.selectDate(selectedDate)),
    selectCountry: (selectedCountryId) => dispatch(GeneralActions.selectCountry(selectedCountryId)),
    selectEntity: (selectedEntityId) => dispatch(GeneralActions.selectEntity(selectedEntityId)),
    selectCurrency: (selectedCurrencyId) => dispatch(GeneralActions.selectCurrency(selectedCurrencyId))
});

const AdjustmentsFilterContainer = connect(mapStateToProps, mapDispatchToProps)(AdjustmentsFilter);

export default AdjustmentsFilterContainer;

