import { combineReducers } from "redux"
import { Map } from "immutable"
import * as Actions from "./actions";
import * as Interfaces from "../interfaces";

export type State = {
    specialRules: Interfaces.SpecialRulesEntry[],
    thereofs: Interfaces.ThereofEntry[],
}

function specialRules(state : Interfaces.SpecialRulesEntry[] = [], action : Actions.Action = Actions.OtherAction) {
    switch (action.type) {
        case Actions.SET_SPECIAL_RULES:
            return action.specialRules;
        default:
            return state;
    }
}

function thereofs(state: Interfaces.ThereofEntry[] = [], action: Actions.Action = Actions.OtherAction) {
    switch(action.type) {
        case Actions.SET_THEREOFS:
            return action.thereofs;
        default:
            return state;
    }
}

export const reducer = combineReducers<State>({
    specialRules,
    thereofs,
});