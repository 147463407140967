// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import { MifPageSelectorTable } from "../components/MifPageSelectorTable";
import config from "../../config";
import {Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch} from "./imports"

const mapStateToProps = (state : State) => ({
    availablePages: Selectors.getAvailablePages(state),
    finalisingStatus: Selectors.getFinalisingStatus(state)
});
 
const mapDispatchToProps = (dispatch : Dispatch) => ({
    startPage: (mifPageId, entityErdrCode, entityName, referenceDate) => dispatch(Actions.startData(mifPageId, entityErdrCode, entityName, referenceDate)),
    unfinalisePage: (mifPageId, entityErdrCode, entityName, referenceDate) => dispatch(Actions.unfinaliseData(mifPageId, entityErdrCode, entityName, referenceDate)),
    setFinalisingStatus: (finalisingStatus) => dispatch(Actions.setFinalisingStatus(finalisingStatus))
});

function mergeProps(stateProps, dispatchProps, ownProps: RouteComponentProps<{pageId: string}>) {
    return { ...stateProps, ...dispatchProps, changePage: (id : number) => ownProps.history.push(`${config.uiBaseUrl}MifData/${id}`)};
}

const MifPageSelectorTableContainer : React.ComponentClass<{}> = withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(MifPageSelectorTable));

export default MifPageSelectorTableContainer;
