// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import { connect } from "react-redux";
import { MifPageSelector } from "../components/MifPageSelector";
import * as Interfaces from "../../interfaces";
import {Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch} from "./imports"

const mapStateToProps = (state: State) => ({
    mifCountries: GeneralSelectors.getCountries(state),
    availableEntities: GeneralSelectors.getAvailableEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),
    
    selectedDate: GeneralSelectors.getDate(state),
    selectedMifCountry: GeneralSelectors.getCountry(state),
    selectedMifEntity: GeneralSelectors.getEntity(state),
    selectedMifCurrency: GeneralSelectors.getCurrency(state),
    mifPageTableLoadingStatus: Selectors.getMifPageTableLoadingStatus(state),
});

const mapDispatchToProps = (dispatch : Dispatch) => ({
    updateMifPageSelectorTable: () => dispatch(Actions.loadAvailablePages(true)),
    selectDate: (selectedDate) => dispatch(GeneralActions.selectDate(selectedDate)),
    selectCountry: (selectedCountryId) => dispatch(GeneralActions.selectCountry(selectedCountryId)),
    selectEntity: (selectedEntityId) => dispatch(GeneralActions.selectEntity(selectedEntityId)),
    selectCurrency: (selectedCurrencyId) => dispatch(GeneralActions.selectCurrency(selectedCurrencyId))
});

const MifPageSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(MifPageSelector);

export default MifPageSelectorContainer;