import * as React from "react";
import * as Interfaces from "../../interfaces";

import { Table, Icon, Button, Input, Popconfirm, notification } from 'antd';
const { Column, ColumnGroup } = Table;

import * as Immutable from "immutable";
import { SelectableCellSpecialRules } from "./SelectableCellSpecialRules";

import moment from "moment";
import DropDownCell from "../../components/DropDownCell"


export interface ThereofsTableProps {
    thereofs: Interfaces.ThereofEntry[],
    mifCountries: Interfaces.MifCountry[],
    mifEntities: Interfaces.MifEntity[],
    mifCurrencies: Interfaces.MifCurrency[],
    riskPositionNames: Interfaces.RiskPositionName[],

    addThereof: (x: Interfaces.ThereofEntry) => void;
    deleteThereof: (x: number) => void;
    editThereof: (x: Interfaces.ThereofEntry) => void;

}

interface ThereofsTableState {
    data: Immutable.Map<number, Interfaces.ThereofEntry>,
    countryFilters: any[],
    entityFilters: any[],
    currencyFilters: any[],
    thereofRiskPositionFilters: any[],
    affectedRiskPositionFilters: any[]
}


class MyTable extends Table<Interfaces.ThereofEntry> { }
class MyColumn extends Column<Interfaces.ThereofEntry> { }

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}
function convertToFilter(Text) {
    return { text: Text, value: Text };
}

export class ThereofsTable extends React.Component<ThereofsTableProps, ThereofsTableState> {

    //constructor
    constructor(props) {
        super(props);
        
        this.state = {
            data: Immutable.Map<number, Interfaces.ThereofEntry>(),
            countryFilters: [],
            entityFilters: [],
            currencyFilters: [],
            thereofRiskPositionFilters: [],
            affectedRiskPositionFilters: []
            
        };

        this.handleEditableCellValueChange = this.handleEditableCellValueChange.bind(this);
    }


    //componentWillReceiveProps
    UNSAFE_componentWillReceiveProps(nextProps : ThereofsTableProps) {

        this.setState({
            countryFilters: [...new Set(nextProps.thereofs.map(x => x.CountryName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)),
            entityFilters: [...new Set(nextProps.thereofs.map(x => x.EntityName || " "))].map(convertToFilter),
            currencyFilters: [...new Set(nextProps.thereofs.map(x => x.CurrencyName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)),
            thereofRiskPositionFilters: [...new Set(nextProps.thereofs.map(x => x.ThereofPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)),
            affectedRiskPositionFilters: [...new Set(nextProps.thereofs.map(x => x.AffectedRiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value))
        });
    }



    //DropDownCell change function
    handleDropDownEditableCellValueChange = (recordId: number, fieldName: string) =>
        (value: any) => {
            const oldDataEntry = this.state.data.get(recordId);
            var update = {};
            update[fieldName] = parseInt(value);
            const newDataEntry: Interfaces.ThereofEntry = Object.assign({}, oldDataEntry, update);
            const newData = this.state.data.set(recordId, newDataEntry);
            this.setState({ data: newData });
        }

    //handleEditableCellValueChange
    handleEditableCellValueChange = (recordId: number, fieldName: string, value: any) => {

        const oldRecord = this.state.data.get(recordId);

        const newRecord: Interfaces.ThereofEntry = ((columnName) => {
            switch (columnName) {               
                case "AdjustmentMultiple":
                    return Object.assign({}, oldRecord, { AdjustmentMultiple: value });
                case "ValidFrom":
                    return Object.assign({}, oldRecord, { ValidFrom: (value.startOf("month").add(-value.startOf("month").toDate().getTimezoneOffset(), "minutes")).toDate() });
                case "ValidTo":
                    return Object.assign({}, oldRecord, { ValidTo: value == null ? null : value.endOf("month").add(-1, "minutes").toDate() });
                case "Comment":
                    return Object.assign({}, oldRecord, { Comment: value.target.value });
                default:
                    return oldRecord;
            }
        })(fieldName);

        var newData = this.state.data.set(recordId, newRecord);
        this.setState({ data: newData });
    }


    //handleAdd
    handleAdd = () => {
        const newData: Interfaces.ThereofEntry = {
            Id: 0,
            CountryId: null,
            CountryName: null,
            EntityId: null,
            EntityName: null,
            CurrencyId: null,
            CurrencyName: null,
            AdjustmentMultiple: null,
            AffectedRiskPositionId: null,
            AffectedRiskPositionName: null,
            ThereofPositionId: null,
            ThereofPositionName: null,
            ValidFrom: moment().startOf("month").add(-moment().startOf("month").toDate().getTimezoneOffset(), "minutes").toDate(),
            ValidTo: null,
            EffectiveFrom: moment().toDate(),
            EffectiveFromUserId: null,
            EffectiveFromUserFullName: null,
            EffectiveTo: null,
            EffectiveToUserId: null,
            EffectiveToUserFullName: null,
            Comment: null
        };

        const imm = this.state.data;
        var newStateData = imm.set(0, newData);
        this.setState({ data: newStateData });
    };


    handleSave = (record: Interfaces.ThereofEntry) => {
        var entry = this.state.data.get(record.Id);

        if(!entry.AffectedRiskPositionId) {
            notification["error"]({
                message: 'Error',
                description: "Must select Affected Risk Position",
                duration: 3
            });
        }  else if (entry.Id === 0) {
            this.props.addThereof(entry);
            const newData = this.state.data.delete(record.Id);
            this.setState({ data: newData });
        } else {
            this.props.editThereof(entry);
            const newData = this.state.data.delete(record.Id);
            this.setState({ data: newData });
        }
    }

    //handleCancel
    handleCancel = (recordId: number) => {
        const imm = this.state.data;

        var newStateData = imm.delete(recordId);
        this.setState({ data: newStateData });
    }

    //handleEdit
    handleEdit = (record: Interfaces.ThereofEntry) => {
        var newStatedata = this.state.data.set(record.Id, record);
        this.setState({ data: newStatedata });
    }

    //handleDelete
    handleDelete = (id) => {
        this.props.deleteThereof(id);
    }


    //renderColumns
    renderColumns(recordEntry: Interfaces.ThereofEntry, columnName) {
        const edit = this.state.data.get(recordEntry.Id);
        if (edit && (
            columnName === "AdjustmentMultiple" ||
            columnName === "EndValue" ||
            columnName === "ValidFrom" ||
            columnName === "ValidTo" ||                       
            columnName === "Comment" )){
            return (<SelectableCellSpecialRules
                recordId={recordEntry.Id}
                editableStatus={true}
                columnName={columnName}
                value={edit[columnName]}
                editStateValue={this.handleEditableCellValueChange}
            />
            );
        } else if (columnName === "ValidFrom") {
                return (moment(recordEntry[columnName]).format("MMMM YYYY"));
        }
          else if (columnName === "ValidTo") {
            return recordEntry[columnName] != null ? moment(recordEntry[columnName]).format("MMMM YYYY") : "";
        }
        return (recordEntry[columnName]);
    }


    //render
    render() {

        var thereofsDataSource = this.props.thereofs.slice();
        const addThereof = this.state.data.get(0);
        if (!!addThereof)
            thereofsDataSource.push(addThereof);


        //having a conditionally rendered button 
        var hasbutton: boolean = !this.state.data.has(0);

        return (
            <div className="thereofs">
                <MyTable className="thereofs-table" size="small" dataSource={thereofsDataSource} rowKey="Id" scroll={{ x: 2000 }} pagination={false}>
                    <MyColumn
                        title="Country"
                        render={(record) => <DropDownCell options={this.props.mifCountries} value={record.CountryId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CountryId")} />}
                        key="CountryName"
                        width={200}
                        fixed="left"
                        filters={this.state.countryFilters.length !== 0 ? this.state.countryFilters : null}
                        onFilter={(value, record) => (record.CountryName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CountryName || " ", b.CountryName || " ")}
                    />
                    <MyColumn
                        title="Entity"
                        render={(record) => <DropDownCell options={this.props.mifEntities} value={record.EntityId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "EntityId")} />}
                        key="EntityName"
                        width={200}
                        fixed="left"
                        filters={this.state.entityFilters.length !== 0 ? this.state.entityFilters : null}
                        onFilter={(value, record) => (record.EntityName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.EntityName || " ", b.EntityName || " ")}
                    />
                    <MyColumn
                        title="Currency"
                        render={(record) => <DropDownCell options={this.props.mifCurrencies} value={record.CurrencyId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CurrencyId")} />}
                        key="CurrencyName"                        
                        fixed="left"
                        filters={this.state.currencyFilters.length !== 0 ? this.state.currencyFilters : null}
                        onFilter={(value, record) => (record.CurrencyName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CurrencyName || " ", b.CurrencyName || " ")}
                        width={200}
                    />
                    <MyColumn
                        title="Adjustment Multiple"
                        //dataIndex="AdjustmentFactor"
                        render={(record) => this.renderColumns(record, "AdjustmentMultiple")}
                        key="AdjustmentMultiple"
                        sorter={(a, b) => a.AdjustmentMultiple - b.AdjustmentMultiple}
                        width={200} />
                    <MyColumn
                        title="Thereof Risk Position"
                        //dataIndex="ThereofPositionName"
                        render={(record) => <DropDownCell options={this.props.riskPositionNames} value={record.ThereofPositionId} removeDefault editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "ThereofPositionId")} />}
                        key="ThereofPositionName"
                        filters={this.state.thereofRiskPositionFilters.length !== 0 ? this.state.thereofRiskPositionFilters : null}
                        onFilter={(value, record) => (record.ThereofPositionName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.ThereofPositionName || " ", b.ThereofPositionName || " ")}
                        width={200} />
                    <MyColumn
                        title="Valid From"
                        //dataIndex="ValidFrom"
                        render={(record) => this.renderColumns(record, "ValidFrom")}
                        key="ValidFrom"
                        sorter={(a, b) => nonNumberSorter(a.ValidFrom, b.ValidFrom)}
                        width={150} />
                    <MyColumn
                        title="Valid To"
                        //dataIndex="ValidTo"
                        render={(record) => this.renderColumns(record, "ValidTo")}
                        key="ValidTo"
                        sorter={(a, b) => nonNumberSorter(a.ValidTo || " ", b.ValidTo || " ")}
                        width={150} />
                    <MyColumn
                        title="Affected Risk Position"
                        //dataIndex="AffectedRiskPositionName"                        
                        render={(record) => <DropDownCell options={this.props.riskPositionNames} removeDefault value={record.AffectedRiskPositionId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "AffectedRiskPositionId")} />}
                        key="AffectedRiskPositionName"
                        filters={this.state.affectedRiskPositionFilters.length !== 0 ? this.state.affectedRiskPositionFilters : null}
                        onFilter={(value, record) => (record.AffectedRiskPositionName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.AffectedRiskPositionName || " ", b.AffectedRiskPositionName || " ")}

                        width={200} />
                    <MyColumn
                        title="Comment"
                        render={(record) => this.renderColumns(record, "Comment")}
                        key="Comment"
                        width={200} />
                    <MyColumn
                        title="Last Updated"
                        //dataIndex="EffectiveFrom"
                        render={(text, record: Interfaces.ThereofEntry) =>
                            record.Id !== 0 ? record.EffectiveFromUserFullName + " on " + moment(record.EffectiveFrom).format("DD MMMM YYYY") : ""}
                        sorter={(a, b) => nonNumberSorter(a.EffectiveFrom, b.EffectiveFrom)}
                        key="EffectiveFrom"
                        width={200}
                    />
                    <MyColumn
                        fixed="right"
                        width={100}
                        title=""
                        key="action"
                        render={(text, record) => (
                            this.state.data.get(record.Id)
                                ? <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSave(record);
                                    }}>Save</a>
                                    <span className="ant-divider" />
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleCancel(record.Id);
                                    }}>Cancel</a>
                                </span>
                                : <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleEdit(record);
                                    }}>Edit</a>
                                    <span className="ant-divider" />

                                    <Popconfirm title="Sure to Delete?" onConfirm={(e) => {
                                        this.handleDelete(record.Id);

                                    }}>
                                        <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                    </Popconfirm>

                                </span>)
                        } />
                </MyTable>
                <br />
                <span className="adjustments-buttons">
                    {hasbutton ? <Button type="primary" onClick={() => this.handleAdd()}>Add</Button> : ""}
                </span>
                <br />
            </div>

        );
    }
}
