import * as React from "react";
import * as Interfaces from "../interfaces";

import { Table, Icon, Button, Input, Popconfirm, Select, DatePicker, Alert } from "antd";
const Option = Select.Option;

import * as Immutable from "immutable";
const { MonthPicker } = DatePicker;
import moment from "moment";

import AdjustmentsTableContainer from "../containers/AdjustmentsTableContainer";


export interface AdjustmentsFilterProps {
    mifCountries: Interfaces.MifCountry[],
    availableEntities: Interfaces.MifEntity[],
    mifCurrencies: Interfaces.MifCurrency[],
    selectedDate: any,
    selectedMifCountry: number,
    selectedMifEntity: number,
    selectedMifCurrency: number,

    updateAdjustmentsTable: () => void;
    selectDate: (date: any) => void;
    selectCountry: (countryId: number) => void;
    selectEntity: (entityId: number) => void;
    selectCurrency: (currencyId: number) => void;
}

export class AdjustmentsFilter extends React.Component<AdjustmentsFilterProps, any> {

    constructor(props) {
        super(props);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }


    handleSelectorChange(type: string, e: any) {
        switch (type) {
            case "Date":
                var newDate = e
                if (newDate !== null) {
                    newDate = e.toDate();
                    newDate.setDate(15);
                }
                this.props.selectDate(newDate);
                break;
            case "Country":
                this.props.selectCountry(parseInt(e));
                break;
            case "Entity":
                this.props.selectEntity(parseInt(e));
                break;
            case "Currency":
                this.props.selectCurrency(parseInt(e));
                break;
        }
        this.props.updateAdjustmentsTable();
    }


    handleButtonClick() {
        this.props.selectDate(null);
        this.props.selectCountry(0);
        this.props.selectEntity(0);
        this.props.selectCurrency(0);

        this.props.updateAdjustmentsTable();
    }

    render() {
        return (
            <div>
                <span style={{ padding: "25px" }}>
                    <MonthPicker
                        disabled={true}
                        format="MMMM YYYY"
                        style={{ width: 150 }}
                        placeholder="Date"
                        value={this.props.selectedDate !== null ? moment(this.props.selectedDate) : null}
                    >
                    </MonthPicker>
                    <div className="dateDivider" />
                    <Select
                        showSearch
                        style={{ width: 150 }}
                        placeholder="Country"
                        optionFilterProp="children"
                        filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                            var optionValue;
                            for (var optionProp in option) {
                                if (optionProp = 'props') {
                                    optionValue = option[optionProp].children.toLowerCase();
                                }
                            }
                            return optionValue.indexOf(input.toLowerCase()) >= 0;
                        }}
                        onChange={(e) => { this.handleSelectorChange("Country", e) }}
                        value={this.props.selectedMifCountry.toString()}

                    >
                        <Option value="0">Country</Option>
                        {this.props.mifCountries.map((mifCountry) => (<Option key={mifCountry.Id.toString()} value={mifCountry.Id.toString()}>{mifCountry.Name}</Option>))}
                    </Select>
                    <div className="divider" />
                    <Select
                        showSearch
                        style={{ width: 150 }}
                        placeholder="Entity"
                        optionFilterProp="children"
                        filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                            var optionValue;
                            for (var optionProp in option) {
                                if (optionProp = 'props') {
                                    optionValue = option[optionProp].children.toLowerCase();
                                }
                            }
                            return optionValue.indexOf(input.toLowerCase()) >= 0;
                        }}
                        onChange={(e) => { this.handleSelectorChange("Entity", e) }}
                        value={this.props.selectedMifEntity.toString()}
                    >
                        <Option value="0">Entity</Option>
                        {this.props.availableEntities.map((mifEntity) => (<Option key={mifEntity.Id.toString()} value={mifEntity.Id.toString()}>{mifEntity.Name}</Option>))}
                    </Select>
                    <div className="divider" />
                    <Select
                        showSearch
                        style={{ width: 150 }}
                        placeholder="Currency"
                        optionFilterProp="children"
                        filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                            var optionValue;
                            for (var optionProp in option) {
                                if (optionProp = 'props') {
                                    optionValue = option[optionProp].children.toLowerCase();
                                }
                            }
                            return optionValue.indexOf(input.toLowerCase()) >= 0;
                        }}
                        onChange={(e) => { this.handleSelectorChange("Currency", e) }}
                        value={this.props.selectedMifCurrency.toString()}
                    >
                        <Option value="0">Currency</Option>
                        {this.props.mifCurrencies.map((mifCurrency) => (<Option key={mifCurrency.Id.toString()} value={mifCurrency.Id.toString()}>{mifCurrency.Name}</Option>))}
                    </Select>
                    <div className="divider" />
                    <Button
                        type="dashed"
                        onClick={this.handleButtonClick}
                        ghost={this.props.selectedDate == null && this.props.selectedMifCountry == 0 && this.props.selectedMifEntity == 0 && this.props.selectedMifCurrency == 0}
                    >Reset</Button>
                </span>

                <AdjustmentsTableContainer />

            </div>
        );
    }
}