import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ErdrTabs } from "../components/ErdrTabs";
import {Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch} from "./imports"

function mapStateToProps(state: State, ownProps: RouteComponentProps<{ pageId?: string }>) {
    const page = Selectors.getPageFullFromMatch(ownProps.match)(state);
    return {
        page: !page ? null : page,
        isLoading: ownProps.match && ownProps.match.params.pageId
    }
}

const mapDispatchToProps = (dispatch : Dispatch) => ({

});


function mergeProps(stateProps, dispatchProps, ownProps) {
    return { ...stateProps, ...dispatchProps };
}

const ErdrTabsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ErdrTabs));

export default ErdrTabsContainer;