// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";

import { Table, Icon, Button, Input, Popconfirm, Tooltip, notification } from "antd";
const { Column, ColumnGroup } = Table;

import * as Immutable from "immutable";
import { SelectableCellAdjustments } from "./SelectableCellAdjustments";
import {NumericInputCell} from "./NumericInputCell";

import moment from "moment";
import DropDownCell from "./DropDownCell"

export interface AdjustmentsTableProps {
    adjustments: Interfaces.AdjustmentEntry[],
    mifCountries: Interfaces.MifCountry[],
    mifEntities: Interfaces.MifEntity[],
    mifCurrencies: Interfaces.MifCurrency[],
    riskPositionNames: Interfaces.RiskPositionName[],

    addSaveAdjustment: (x: Interfaces.AdjustmentEntry) => void;
    deleteAdjustment: (x: number) => void;
    editSaveAdjustment: (x: Interfaces.AdjustmentEntry) => void;

}

interface AdjustmentsTableState {
    data: Immutable.Map<number, Interfaces.AdjustmentEntry>,
    filteredInfo: any,
    filteredCount: number,
    sortedInfo: any,
    pageInfo: any  
}


class MyTable extends Table<Interfaces.AdjustmentEntry> { }
class MyColumn extends Column<Interfaces.AdjustmentEntry> { }


function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}
function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function numberWithCommas(x, ccy?) {
    if (!x) return null;
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

export class AdjustmentsTable extends React.Component<AdjustmentsTableProps, AdjustmentsTableState> {

    //constructor
    constructor(props) {
        super(props);       
        this.state = {
            data: Immutable.Map<number, Interfaces.AdjustmentEntry>(),
            filteredInfo: null,
            filteredCount: this.props.adjustments.length,
            sortedInfo: null,
            pageInfo: 1
        };

        //binding callback functions        
        this.handleEditableCellValueChange = this.handleEditableCellValueChange.bind(this);
        this.handleSorterFilterChange = this.handleSorterFilterChange.bind(this);


    }

    //DropDownCell change function
    handleDropDownEditableCellValueChange = (recordId: number, fieldName: string) =>
        (value: any) => {
            const oldDataEntry = this.state.data.get(recordId);
            var update = {};
            update[fieldName] = parseInt(value);
            const newDataEntry: Interfaces.AdjustmentEntry = Object.assign({}, oldDataEntry, update);
            const newData = this.state.data.set(recordId, newDataEntry);
            this.setState({ data: newData });
        }


    //handleEditableCellValueChange
    handleEditableCellValueChange = (recordId: number, fieldName: string, value: any) => {

        const oldRecord = this.state.data.get(recordId);
               
        const newRecord: Interfaces.AdjustmentEntry = ((columnName) => {
            switch (columnName) {
                case "Value":
                    return Object.assign({}, oldRecord, { Value: parseFloat(value)});
                case "Comment":
                    return Object.assign({}, oldRecord, { Comment: value.target.value });
                case "ValidFrom":
                    return Object.assign({}, oldRecord, { ValidFrom: (value.startOf("month").add(-value.startOf("month").toDate().getTimezoneOffset(), "minutes")).toDate()});                    
                case "ValidTo":
                    return Object.assign({}, oldRecord, { ValidTo: value == null ? null : value.endOf("month").add(-1, "minutes").toDate() });
                default:
                    return oldRecord;
            }
        })(fieldName);

        //var newStateEditableData = immEditable.set(recordId, newRecord);
        var newData = this.state.data.set(recordId, newRecord);
        this.setState({ data: newData });

    }


    handleSorterFilterChange(pagination, filters, sorter) {

        //Re-calculating number of table entries after application of filters
        var filteredAdjustmentsListCount = this.props.adjustments.filter(
            x => (filters.CountryName == null || filters.CountryName.length === 0) ? true : filters.CountryName.indexOf(x.CountryName || " ") !== -1).filter(
                x => (filters.EntityName == null || filters.EntityName.length === 0) ? true : filters.EntityName.indexOf(x.EntityName || " ") !== -1).filter(
                    x => (filters.CurrencyName == null || filters.CurrencyName.length === 0) ? true : filters.CurrencyName.indexOf(x.CurrencyName || " ") !== -1).filter(
                        x => (filters.FromRiskPositionName == null || filters.FromRiskPositionName.length === 0) ? true : filters.FromRiskPositionName.indexOf(x.FromRiskPositionName || " ") !== -1).filter(
                            x => (filters.ToRiskPositionName == null || filters.ToRiskPositionName.length === 0) ? true : filters.ToRiskPositionName.indexOf(x.ToRiskPositionName || " ") !== -1).length;

        this.setState({
            filteredInfo: filters,
            filteredCount: filteredAdjustmentsListCount,
            sortedInfo: sorter,
            pageInfo: pagination.current
        })
    }

    //handleAdd
    handleAdd = () => {
        const newData: Interfaces.AdjustmentEntry = {
            Id: 0,
            CountryId: null,
            CountryName: null,
            EntityId: null,
            EntityName: null,
            EntityErdrCode: null,
            CurrencyId: null,
            CurrencyName: null,
            Value: null,
            ValidFrom: moment().startOf("month").add(-moment().startOf("month").toDate().getTimezoneOffset(), "minutes").toDate(),
            ValidFromUserId: null,
            ValidTo: null,
            EffectiveFrom: moment().toDate(),
            EffectiveFromUserId: null,
            EffectiveFromUserFullName: null,
            EffectiveTo: null,
            EffectiveToUserId: null,
            FromRiskPositionId: null,
            FromRiskPositionName: null,
            ToRiskPositionId: null,
            ToRiskPositionName: null,
            Comment: null,
        };

        const imm = this.state.data;
        var newStateData = imm.set(0, newData);
        this.setState({ data: newStateData, filteredInfo: null, filteredCount: this.props.adjustments.length, sortedInfo: null, pageInfo: 1 });
    };


    //handleSave
    handleSave = (record: Interfaces.AdjustmentEntry) => {
        var entry = this.state.data.get(record.Id);

        if (!entry.CountryId && !entry.EntityId && !entry.CurrencyId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select Currency and exactly one of Country and Entity',
                duration: 3
            });
        } else if (!entry.CountryId && !entry.EntityId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select exactly one of Country and Entity',
                duration: 3
            });
        } else if (!!entry.CountryId && !!entry.EntityId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select exactly one of Country and Entity',
                duration: 3
            });
        } else if (!entry.CurrencyId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select Currency',
                duration: 3
            });
        } else if (!entry.ToRiskPositionId ) {
            notification["error"]({
                message: 'Error',
                description: "Must select To Risk Position",
                duration: 3
            });
        } 

        else if (entry.Id === 0) {
            this.props.addSaveAdjustment(entry);
            const newData = this.state.data.delete(record.Id);                   
            this.setState({ data: newData });
        } else {
            this.props.editSaveAdjustment(entry);
            const newData = this.state.data.delete(record.Id);              
            this.setState({ data: newData });
        }
    }


    //handleCancel
    handleCancel = (recordId: number) => {
        const imm = this.state.data;
       
        var newStateData = imm.delete(recordId);
        this.setState({ data: newStateData});
    }


    //handleEdit
    handleEdit = (record: Interfaces.AdjustmentEntry) => {
        var newStatedata = this.state.data.set(record.Id, record);
        this.setState({ data: newStatedata });
    }


    //handleDelete
    handleDelete = (id) => {
        this.props.deleteAdjustment(id);
    }


    //renderColumns
    renderColumns( recordEntry: Interfaces.AdjustmentEntry, columnName) {
        const edit = this.state.data.get(recordEntry.Id);
        if (edit && (
            columnName === "ValidFrom" ||
            columnName === "ValidTo" ||
            columnName === "Comment" ||
            columnName === "Value"
        )) {
            return (<SelectableCellAdjustments
                recordId={recordEntry.Id}
                editableStatus={true}
                columnName={columnName}
                value={edit[columnName]}
                editStateValue={this.handleEditableCellValueChange}
            />
            );
        } else if (columnName === "ValidFrom") {
            return (moment(recordEntry[columnName]).format("MMM YYYY"));
        }
        else if (columnName === "ValidTo") {
            return recordEntry[columnName] != null ? moment(recordEntry[columnName]).format("MMM YYYY") : "";
        } else if (columnName === "EntityName") {
            return (<Tooltip title={recordEntry[columnName]} placement="rightTop"> {recordEntry
                .EntityErdrCode} </Tooltip >);
        } else if (columnName === "Value") {
            return recordEntry.Value !== 0 ? numberWithCommas(recordEntry.Value) : "-";
        } else if (
            columnName === "Comment") {
            return (
                recordEntry[columnName] == null ?
                    "-" :
                    recordEntry[columnName].length <= 25 ? recordEntry[columnName] : <Tooltip title={recordEntry[columnName]} placement="top"> {recordEntry[columnName].slice(0, 25) + "..."} </Tooltip >);
        } else {
            return recordEntry[columnName];
        }
    }


    render() {

        //defining the filter options for each relevant column based on all existing adjustments
        const entityFilters = [...new Set(this.props.adjustments.map(x => x.EntityName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value));
        const countryFilters = [...new Set(this.props.adjustments.map(x => x.CountryName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value));
        const currencyFilters = [...new Set(this.props.adjustments.map(x => x.CurrencyName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value));
        const fromRiskPositionNameFilters = [...new Set(this.props.adjustments.map(x => x.FromRiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value));
        const toRiskPositionNameFilters = [...new Set(this.props.adjustments.map(x => x.ToRiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value));

        let sortedInfo = this.state.sortedInfo;
        let filteredInfo = this.state.filteredInfo;
        let filteredCount = this.state.filteredCount;
        let pageInfo = this.state.pageInfo;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        pageInfo = pageInfo;

        // Configuration object for pagination of table
        var paginationConfig = {
            pagination: {
                current: pageInfo,
                pageSize: 15,
                total: filteredCount
            }
        }

        // Add new adjustment when 'Add' button pressed
        var adjustmentsDataSource = this.props.adjustments.slice();
        const addAdjustment = this.state.data.get(0);
        if (!!addAdjustment)
            adjustmentsDataSource.unshift(addAdjustment);

        // Add 'None' to options for 'From Risk Position'
        var fromRiskPositionNames = this.props.riskPositionNames.slice();       
        var newNullRiskPositionName = { Id: 0, Name: "None", IsDiffPosition: 1, IsValid: 0 }
        fromRiskPositionNames.unshift(newNullRiskPositionName);

        // Indicate whether 'Add' button should be visible at bottom of table
        var hasbutton: boolean = !this.state.data.has(0);


        return (
            <div className="adjustments">

                <MyTable
                    className="adjustments-table"
                    size="small"
                    dataSource={adjustmentsDataSource}
                    rowKey="Id"
                    {...paginationConfig}
                    onChange={this.handleSorterFilterChange}
                >
                    <MyColumn
                        title="Country"
                        render={(record) => <DropDownCell options={this.props.mifCountries} value={record.CountryId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CountryId")} />}
                        key="CountryName"  

                        sorter={(a, b) => nonNumberSorter(a.CountryName || " ", b.CountryName || " ")}
                        sortOrder={sortedInfo.columnKey === "CountryName" && sortedInfo.order}
                        filters={countryFilters}
                        filteredValue={filteredInfo.CountryName || null}
                        onFilter={(value, record) => (record.CountryName || " ").indexOf(value) === 0}                                                 
                        width="8%"
                    />
                    <MyColumn
                        title="Entity"
                        render={(record) =>
                            !!!this.state.data.get(record.Id) ?
                                (record.EntityErdrCode == null ?
                                    "-" :
                                    <Tooltip title={record.EntityName} placement="rightTop">{record.EntityErdrCode}</Tooltip>
                                ) :
                                <DropDownCell
                                    options={this.props.mifEntities}
                                    value={record.EntityId}
                                    editableStatus={!!this.state.data.get(record.Id)}
                                    editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "EntityId")}
                                    cellWidth={100}
                                />
                        }
                        key="EntityName"
                        sorter={(a, b) => nonNumberSorter(a.EntityName || " ", b.EntityName || " ")}
                        sortOrder={sortedInfo.columnKey === "EntityName" && sortedInfo.order}
                        filters={entityFilters}
                        filteredValue={filteredInfo.EntityName || null}
                        onFilter={(value, record) => (record.EntityName || " ").indexOf(value) === 0}
                        width="8%"
                    />                     
                    <MyColumn
                        title="Currency"
                        render={(record) =>
                            <DropDownCell
                                options={this.props.mifCurrencies}
                                value={record.CurrencyId}
                                editableStatus={!!this.state.data.get(record.Id)}
                                editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CurrencyId")}
                            />
                        }
                        key="CurrencyName"   
                        sorter={(a, b) => nonNumberSorter(a.CurrencyName, b.CurrencyName)}
                        sortOrder={sortedInfo.columnKey === "CurrencyName" && sortedInfo.order}
                        filters={currencyFilters}
                        filteredValue={filteredInfo.CurrencyName || null}
                        onFilter={(value, record) => (record.CurrencyName).indexOf(value) === 0}
                        width="9.5%"
                    />
                    <MyColumn className="numeric-column"
                        title="Value"
                        render={(record) => this.renderColumns(record, "Value")}
                        sorter={(a, b) => nonNumberSorter(a.Value, b.Value)}
                        sortOrder={sortedInfo.columnKey === "Value" && sortedInfo.order}
                        key="Value"
                        width="9%"
                    />
                    <MyColumn
                        title="Valid From"
                        render={(record) => this.renderColumns(record, "ValidFrom")}
                        key="ValidFrom"
                        sorter={(a, b) => -nonNumberSorter(a.ValidFrom, b.ValidFrom)}
                        sortOrder={sortedInfo.columnKey === "ValidFrom" && sortedInfo.order}
                        width="7%"
                    />
                    <MyColumn
                        title="Valid To"
                        render={(record) => this.renderColumns(record, "ValidTo")}
                        key="ValidTo"
                        sorter={(a, b) => -nonNumberSorter(a.ValidTo || " ", b.ValidTo || " ")}
                        sortOrder={sortedInfo.columnKey === "ValidTo" && sortedInfo.order}
                        width="7%"
                    />
                    <MyColumn
                        title="From Risk Position"
                        render={(record) =>
                            !this.state.data.get(record.Id) ?
                                (record.FromRiskPositionName == null ? "-" : (record.FromRiskPositionName.length <= 25 ? record.FromRiskPositionName : <Tooltip title={record.FromRiskPositionName} placement="top"> {record.FromRiskPositionName.slice(0, 25) + "..."}</Tooltip >))
                                :
                                <DropDownCell
                                    options={fromRiskPositionNames}
                                    value={record.FromRiskPositionId}
                                    removeDefault
                                    editableStatus={!!this.state.data.get(record.Id)}
                                    editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "FromRiskPositionId")}
                                    cellWidth={170}
                                />
                        }
                        key="FromRiskPositionName"                      
                        sorter={(a, b) => nonNumberSorter(a.FromRiskPositionName || " ", b.FromRiskPositionName || " ")}
                        sortOrder={sortedInfo.columnKey === "FromRiskPositionName" && sortedInfo.order}
                        filters={fromRiskPositionNameFilters}
                        filteredValue={filteredInfo.FromRiskPositionName || null}
                        onFilter={(value, record) => (record.FromRiskPositionName || " ").indexOf(value) === 0}
                        width="12.5%"
                    />
                    <MyColumn
                        title="To Risk Position"
                        render={(record) =>
                            !this.state.data.get(record.Id) ?
                                (record.ToRiskPositionName == null
                                    ? "-"
                                    : (record.ToRiskPositionName.length <= 25 ? record.ToRiskPositionName : <Tooltip title={record.ToRiskPositionName} placement="top"> {record.ToRiskPositionName.slice(0, 25) + "..."} </Tooltip >))
                                :
                                <DropDownCell
                                    options={this.props.riskPositionNames}
                                    value={record.ToRiskPositionId}
                                    removeDefault
                                    editableStatus={!!this.state.data.get(record.Id)}
                                    editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "ToRiskPositionId")}
                                    cellWidth={170}
                                />
                        }
                       
                        key="ToRiskPositionName"
                        sorter={(a, b) => nonNumberSorter(a.ToRiskPositionName, b.ToRiskPositionName)}
                        sortOrder={sortedInfo.columnKey === "ToRiskPositionName" && sortedInfo.order}
                        filters={toRiskPositionNameFilters}
                        filteredValue={filteredInfo.ToRiskPositionName || null}
                        onFilter={(value, record) => record.ToRiskPositionName.indexOf(value) === 0}
                        width="12.5%"
                    />
                    <MyColumn
                        title="Comment"
                        render={(record) => this.renderColumns(record, "Comment")}
                        key="Comment"
                        width="12.5%"
                    />
                    <MyColumn
                        title="Last Updated"
                        render={(text, record) =>
                            record.Id !== 0 ? <Tooltip title={record.EffectiveFromUserFullName} placement="top">{moment(record.EffectiveFrom).format("DD MMM YYYY")}</Tooltip> : ""}
                        sorter={(a, b) => -nonNumberSorter(a.EffectiveFrom, b.EffectiveFrom)}
                        sortOrder={sortedInfo.columnKey === "EffectiveFrom" && sortedInfo.order}

                        key="EffectiveFrom"
                        width="7%"
                    />
                    <MyColumn                        
                        title=""
                        key="action"
                        render={(text, record) => (
                            this.state.data.get(record.Id)
                                ? <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSave(record);
                                    }}>Save</a>
                                    <span className="ant-divider" />
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleCancel(record.Id);
                                    }}>Cancel</a>
                                </span>
                                : <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleEdit(record);
                                    }}>Edit</a>
                                    <span className="ant-divider" />

                                    <Popconfirm title="Sure to Delete?" onConfirm={(e) => {
                                        this.handleDelete(record.Id);

                                    }}>
                                        <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                    </Popconfirm>  

                                </span>)
                        }
                        width="6%"
                    />

                </MyTable>
                <br/>
                <span className="adjustments-buttons">
                    {hasbutton ? <Button type="primary" onClick={() => this.handleAdd()}>Add</Button> : ""}
                </span>
                <br/>
            </div>

);
}
}
