import * as React from "react";
import * as ReactDOM from "react-dom";
import ErdrPageSelectorContainer from "../containers/ErdrPageSelectorContainer";
import ErdrTabsContainer from "../containers/ErdrTabsContainer";

const ErdrData = (props) =>
    <div>
        <h1 style={{ paddingTop: "37px" }} />
        <ErdrPageSelectorContainer />
        <ErdrTabsContainer />
    </div>;

export default ErdrData;
