// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces"

import { Table, Icon, Button , Input, Spin} from 'antd';
const { Column, ColumnGroup } = Table;

export interface MifPageProps {
    page: Interfaces.MifEntry[];
    isLoading: boolean;
}

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: "currency", currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

class MyTable extends Table<Interfaces.MifEntry> { }
class MyColumnInner extends Column<Interfaces.MifEntry> { }
const MyColumn: any = MyColumnInner; 

// State is never set so we use the 'undefined' type.
export class MifPage extends React.Component<MifPageProps, any> {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.page,

            mifAccountNumberfilterDropdownVisible: false,
            mifAccountNumbersearchText: '',
            mifAccountNumberfiltered: false,

            mifAccountNamefilterDropdownVisible: false,
            mifAccountNamesearchText: '',
            mifAccountNamefiltered: false,
            
        }
    }

    //ensure state.data is correct after initial population of props
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.page != nextProps.page) {
            this.setState({
                data: nextProps.page
            });
        }              
    }


    onInputChange = (e, columnName) => {
        switch (columnName) {
            case "MifAccountNumber":
                this.setState({ mifAccountNumbersearchText: e.target.value.toString() });
                break;
            case "MifAccountName":
                this.setState({ mifAccountNamesearchText: e.target.value });
                break;
            default:
                break;
        }
    }

    onSearch = (columnName) => {

        const { mifAccountNumbersearchText, mifAccountNamesearchText } = this.state;
                this.setState(() => {
                    return {
                        mifAccountNamefilterDropdownVisible: false,
                        mifAccountNamefiltered: !!mifAccountNamesearchText,
                        mifAccountNumberfilterDropdownVisible: false,
                        mifAccountNumberfiltered: !!mifAccountNumbersearchText,
                        data: this.props.page.filter((record) => record.MifAccountAccountNo.toString().indexOf(mifAccountNumbersearchText) >= 0).filter((record) => record.MifAccountName.toLowerCase().indexOf(mifAccountNamesearchText.toLowerCase()) >= 0)
                    };
                })
    }

    render() {

        if (this.props.page == null) {
            return (this.props.isLoading
                ? (<div style={{ textAlign: "center" }}>
                       <Spin
                           tip="Loading..."
                           size="large"/>
                   </div>)
                : <h2 style={{ textAlign: "center" }}>No Data</h2>
            );

        } else {
            return (
                <MyTable className="mif-page" size="small" dataSource={this.state.data} rowKey="Id" scroll={{ y: 300 }} pagination={false}>
                    <MyColumn
                        className="numeric-column"
                        title="Account Number"
                        dataIndex="MifAccountAccountNo"
                        key="MifAccountAccountNo"                        
                        filterDropdown={(
                            <div style={{ width: 175 }}>
                                <Input.Search
                                    placeholder="Search Account Number"
                                    ref={elem => { if (elem) elem.input.focus() }}
                                    value={this.state.mifAccountNumbersearchText}
                                    //enterButton
                                    onChange={(e) => this.onInputChange(e, "MifAccountNumber")}
                                    onSearch={this.onSearch}
                                />
                            </div>
                        )}
                        filterIcon={<Icon type="search" style={{ color: this.state.mifAccountNumberfiltered ? '#108ee9' : '#aaa' }} />}
                        filterDropdownVisible={this.state.mifAccountNumberfilterDropdownVisible}
                        onFilterDropdownVisibleChange={(visible) => {
                            this.setState({
                                mifAccountNumberfilterDropdownVisible: visible,
                            });
                        }}                                              
                        sorter={(a, b) => a.MifAccountAccountNo - b.MifAccountAccountNo}                       
                        width={100}
                    />
                    <MyColumn
                        title="Account Name"
                        dataIndex="MifAccountName"
                        key="MifAccountName"
                        filterDropdown={(
                            <div style={{ width: 275 }}>
                                <Input.Search
                                    placeholder="Search Account Name"
                                    ref={elem => { if (elem) elem.input.focus() }}
                                    value={this.state.mifAccountNamesearchText}
                                    //enterButton
                                    onChange={(e) => this.onInputChange(e, "MifAccountName")}
                                    onSearch={this.onSearch}
                                />
                            </div>
                        )}
                        filterIcon={<Icon type="search" style={{ color: this.state.mifAccountNamefiltered ? '#108ee9' : '#aaa' }} />}
                        filterDropdownVisible={this.state.mifAccountNamefilterDropdownVisible}
                        onFilterDropdownVisibleChange={(visible) => {
                            this.setState({
                                mifAccountNamefilterDropdownVisible: visible,
                            });
                        }} 
                        sorter={(a, b) => a.MifAccountName.localeCompare(b.MifAccountName)}
                        width={200}
                    />
                    <MyColumn
                        title="Currency"
                        dataIndex="MifCurrencyName"
                        key="MifCurrencyName"
                        sorter={(a, b) => a.MifCurrencyName.localeCompare(b.MifCurrencyName)}
                        width={100}
                    />
                    <MyColumn
                        className="numeric-column"
                        title="Value"
                        dataIndex="Value"
                        render={(text, record: Interfaces.MifEntry) => (record.Value !== 0) ? numberWithCommas(record.Value, record.MifCurrencyCode) : "-"}
                        key="Value"
                        sorter={(a, b) => a.Value - b.Value}
                        width={100}
                    />
                    <MyColumn
                        title="Default Risk Position"
                        dataIndex="DefaultRiskPositionName"
                        key="DefaultRiskPositionName"
                        filters={this.props.page !== null ? [...new Set(this.props.page.map(x => x.DefaultRiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        onFilter={(value, record) => record.DefaultRiskPositionName && record.DefaultRiskPositionName.indexOf(value || " ") === 0}
                        sorter={(a, b) => nonNumberSorter(a.DefaultRiskPositionName || " ", b.DefaultRiskPositionName || " ")}
                        width={100}
                    />
                    <MyColumn
                        title="Override Risk Position"
                        render={(text, record: Interfaces.MifEntry) => record.OverrideRiskPositionName || "-"}
                        key="OverrideRiskPositionName"
                        filters={this.props.page !== null ? [...new Set(this.props.page.map(x => x.OverrideRiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        onFilter={(value, record) => (record.OverrideRiskPositionName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.OverrideRiskPositionName || " ", b.OverrideRiskPositionName || " ")}
                        width={100}
                    />
                    <MyColumn
                        title="Final Risk Position"
                        dataIndex="FinalRiskPositionName"
                        key="FinalRiskPositionName"
                        filters={this.props.page !== null ? [...new Set(this.props.page.map(x => x.FinalRiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        onFilter={(value, record) => record.FinalRiskPositionName && record.FinalRiskPositionName.indexOf(value || " ") === 0}
                        sorter={(a, b) => nonNumberSorter(a.FinalRiskPositionName || " ", b.FinalRiskPositionName || " ")}
                        width={100}
                    />
                </MyTable>
            );

        }
    }
}

