// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces"
import ErdrSummary from "../components/ErdrSummary"



import { Table, Icon, Button, Input, Select } from 'antd';
const { Column, ColumnGroup } = Table;
const Option = Select.Option;


export interface ErdrSummaryPageProps {
    page: Interfaces.ErdrPageFull;
    isLoading: boolean;
}

export interface ErdrSummaryPageState {
    selectedCurrency: number;
    page: Interfaces.ErdrPageFull;
}


function isInArray(value, array) {
    return array.indexOf(value) > -1;
}


export default class ErdrSummaryPage extends React.Component<ErdrSummaryPageProps, ErdrSummaryPageState> {

    constructor(props) {
        super(props);

        this.state = {
            //Initial currency
            selectedCurrency: props.page != null ? props.page.AvailableCurrencies[0].Id : null, 
            page: props.page
        };

        this.handleChange = this.handleChange.bind(this);
    }


    UNSAFE_componentWillReceiveProps(nextProps: any) {
        var nextSelectedCurrency = nextProps.page != null ? nextProps.page.AvailableCurrencies[0].Id : -1;
        this.setState({ selectedCurrency: nextSelectedCurrency, page: nextProps.page });
    }


    handleChange(newValue: number) {
        this.setState({
            selectedCurrency: newValue
        });
    }

    render() {

        if (this.props.page == null) {
            return <div>
                <h2 style={{ textAlign: "center" }}>{this.props.isLoading ? "Loading..." : "No Data"}</h2>
                   </div>;
        }

        return (
            <div>
                <span>
               <Select
                    style={{ width: 200}}
                    onChange={x => this.handleChange(parseInt(x as string))}
                    defaultValue={this.props.page.AvailableCurrencies[0].Name}
                    value={this.state.selectedCurrency.toString()}
                >
                        {this.props.page.AvailableCurrencies.map(c => <Option key={c.Id.toString()} value={c.Id.toString()} >{c.Name}</Option>)}
                </Select>
                <br /><br />
                <ErdrSummary page={this.props.page} currency={this.state.selectedCurrency} isLoading={this.props.isLoading}/>
                
                </span>
            </div>
          

        );
    }

}