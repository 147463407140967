// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";
import { Table, Badge, Menu, Icon, Button, Input} from 'antd';
const { Column, ColumnGroup } = Table;
import * as Immutable from "immutable"
import moment from "moment";

interface RunoffIrrCellProps {
    editableStatus: boolean,
    value: any,
    editStateValue: (value: any) => void,
    irrType: number,
}

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

function percentageNumberWithCommas(x) {
    return x.toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "%";
}

export default class RunoffIrrCell extends React.Component<RunoffIrrCellProps, {}> {

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    //Change the state of the RiskPositionMappingTable parent component through it's function
    handleChange(e) {
        this.props.editStateValue(e.target.value);

    };
    
    render() {
        const value = this.props.value;
        const editableStatus = this.props.editableStatus;
        const irrType = this.props.irrType;

        if (irrType === 1 ) {

            return (
                <div className="editable-row-text">
                    Retail Rate
                </div>
            );

        }

        else if (irrType === 2) {
            return (
                <div className="editable-row-text">
                    Wholesale Rate
                </div>
            );
        }

        else if (irrType === 3) {
            return (
                <div className="editable-row-text">
                    TM1 Wholesale Rate
                </div>
            );
        }

        else if (irrType === 4 || irrType == 7) {

            return (
                editableStatus
                    ? <div>
                        <Input
                            defaultValue={this.props.value}
                            onChange={e => this.handleChange(e)}
                            placeholder={"Value in %"}
                        />
                    </div>
                    :
                    <div className="editable-row-text">
                        {value != null ? percentageNumberWithCommas(value) : ""}
                    </div>

            );
        }

        else if (irrType === 5) {

            return (
                <div className="editable-row-text">
                    0
                </div>
            );
        }

        else if (irrType === 6) {

            return (
                <div className="editable-row-text">
                </div>
            );
        }

        else {
            return (
                <div className="editable-row-text">
                    {value != null ? value.toString() : ""}
                </div>
            );
        }
    }
};