import * as React from "react";
import * as ReactDom from "react-dom";
import AdjustmentsFilterContainer from "../containers/AdjustmentsFilterContainer";

const AdjustmentsData = (props) =>
    <div>
        <h1 style={{ paddingTop: "37px" }} />
        <AdjustmentsFilterContainer />

    </div>;

export default AdjustmentsData; 