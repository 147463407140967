import { connect } from "react-redux";
import { EquityInjectionsFilter } from "../components/EquityInjectionsFilter";
import * as Interfaces from "../../interfaces";
import { Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch } from "./imports"


const mapStateToProps = (state: State) => ({
    mifCountries: GeneralSelectors.getCountries(state),
    availableEntities: GeneralSelectors.getAvailableEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),

    selectedDate: GeneralSelectors.getDate(state),
    selectedMifCountry: GeneralSelectors.getCountry(state),
    selectedMifEntity: GeneralSelectors.getEntity(state),
    selectedMifCurrency: GeneralSelectors.getCurrency(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateEquityInjectionsTable: () => dispatch(Actions.loadEquityInjections()),
    selectDate: (selectedDate) => dispatch(GeneralActions.selectDate(selectedDate)),
    selectCountry: (selectedCountryId) => dispatch(GeneralActions.selectCountry(selectedCountryId)),
    selectEntity: (selectedEntityId) => dispatch(GeneralActions.selectEntity(selectedEntityId)),
    selectCurrency: (selectedCurrencyId) => dispatch(GeneralActions.selectCurrency(selectedCurrencyId))
});

const EquityInjectionsFilterContainer = connect(mapStateToProps, mapDispatchToProps)(EquityInjectionsFilter);

export default EquityInjectionsFilterContainer;

