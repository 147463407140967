// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import { MifPageAggregated } from "../components/MifPageAggregated";
import {Actions, GeneralActions, Selectors, GeneralSelectors, State, Dispatch} from "./imports"
import * as Interfaces from "../../interfaces";

function mapStateToProps(state: State, ownProps: RouteComponentProps<{ pageId?: string }>) {
    const page = Selectors.getPageFullFromMatch(ownProps.match)(state);
    const mifCurrencyFilter = Selectors.getCurrencyFilter(state);
    return {
        pageAggregations: !page ? null : (page.MifPageAggregated.MifEntryAggregateds.filter((x: Interfaces.MifEntryAggregated) => x.MifCurrencyId === mifCurrencyFilter || mifCurrencyFilter === 0)),
        isLoading: ownProps.match && ownProps.match.params.pageId
    }
}

const mapDispatchToProps = (dispatch : Dispatch) => ({
});

function mergeProps(stateProps, dispatchProps, ownProps) {
    return { ...stateProps, ...dispatchProps };
}

const MifPageAggregatedContainer = withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(MifPageAggregated));

export default MifPageAggregatedContainer;