// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX


//This component is a mix of presentational and container.

import * as React from "react";
import { connect } from "react-redux";
import { Table, message, Upload, Button, Icon, Select, Form } from "antd";
const FormItem = Form.Item;
const Option = Select.Option;
const { Column, ColumnGroup } = Table;
import * as Actions from "../actions";
import * as Interfaces from "../interfaces";
import moment from "moment";
import UploadFileFormContainer from "./UploadFileFormContainer";



function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}


class MyTable extends Table<Interfaces.InputFile> { }
class MyColumn extends Column<Interfaces.InputFile> { }

//Need to add the Overwrite button here

export class FileUploader extends React.Component<any, any> {

    render() {
        let form;

        return (
            <div>

                <div style={{ paddingLeft: "25px", paddingTop: "17px" }}>
                    <UploadFileFormContainer />
                </div>

              
                <div style={{ paddingLeft: "25px", paddingTop:"17px", display: "inline", float: "left", width: "100%" }} >
                    <MyTable className="import-page" dataSource={this.props.inputFiles} rowKey={x => x.FileType + x.Id}>
                        <MyColumn
                            title="Filename"
                            dataIndex="FileName"
                            sorter={(a, b) => nonNumberSorter(a.FileName, b.FileName)}
                            width={150}
                        />

                        <MyColumn
                            title="Type"
                            render={(text, record) => record.FileType}
                            key="FileType"                           
                            filters={this.props.inputFiles !== null ? [...new Set(this.props.inputFiles.map(x => x.FileType))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value.toLowerCase(), a.value.toLowerCase())) : null}
                            onFilter={(value, record) => record.FileType.indexOf(value) === 0}
                            sorter={(a, b) => nonNumberSorter(a.FileType.toLowerCase(), b.FileType.toLowerCase())}
                            width={100}
                        />

                        <MyColumn
                            title="Status"
                            dataIndex="Status"                           
                            filters={this.props.inputFiles !== null ? [...new Set(this.props.inputFiles.map(x => x.Status))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                            onFilter={(value, record) => record.Status.indexOf(value) === 0}
                            sorter={(a, b) => nonNumberSorter(a.Status.toLowerCase(), b.Status.toLowerCase())}
                            width={100}
                        />
                        
                        <MyColumn
                            title="Date"
                            render={(text, record) => moment(record.DateSubmitted).format("D MMMM YYYY h:mm:ss a")}
                            key="DateSubmitted"
                            sorter={(a, b) => nonNumberSorter(a.DateSubmitted, b.DateSubmitted)}
                            width={100}
                        />

                        <MyColumn
                            title="User Name"
                            dataIndex="UserFullName"                           
                            filters={this.props.inputFiles !== null ? [...new Set(this.props.inputFiles.map(x => x.UserFullName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                            onFilter={(value, record) => record.UserFullName.indexOf(value) === 0}
                            sorter={(a, b) => nonNumberSorter(a.UserFullName.toLowerCase(), b.UserFullName.toLowerCase())}
                            width={100}
                        />

                    </MyTable>
                </div>
            </div>
        );
    }

}

const FileUploaderContainer: any = connect(
    (state: any)  => ({ inputFiles: state.inputFiles }),
    dispatch => ({} as any)
)(FileUploader);

export default FileUploaderContainer;



