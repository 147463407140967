import UrlMap from "../UrlMap"

import * as Actions from "./actions"
import * as GeneralActions from "../general/actions"

export const urls = [
    new UrlMap("SummaryView",
        () => [
            Actions.load(),
            GeneralActions.loadCountries(),
            GeneralActions.loadEntities(),
            GeneralActions.loadCurrencies(),
            GeneralActions.loadRiskPositionNames(),
            Actions.loadDifferencePositions()
        ])
];