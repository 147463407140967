import { put, fork, select } from 'redux-saga/effects'
import config from '../config';
import * as Interfaces from "../interfaces"
import * as Actions from "./actions";
import * as Top from "../reducers"
import * as Selectors from "./selectors"
import * as GeneralSelectors from "../general/selectors"
import { notification } from "antd"
import * as MainActions from "../actions";

import AuthenticatedRequest from "../http";

import * as Eff from 'redux-saga/effects'
const takeEvery: any = Eff.takeEvery;

function runoffViewTakeEvery<TAction extends string>(action: TAction, reducer: (act: Actions.Action & { type: TAction }) => any) {
    return takeEvery(action, reducer);
}

function* load(action: Actions.LoadAction) {
    const selectedDate = yield select(GeneralSelectors.getDate);
    const selectedEntityId = yield select(GeneralSelectors.getEntity);
    const selectedCurrencyId = yield select(GeneralSelectors.getCurrency);
    const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);
    
    let url = config.apiBaseUrl + "/api/RunoffView?selectedEntityId="  + selectedEntityId + "&selectedCurrencyId=" + selectedCurrencyId;
    if (selectedDate !== null) {
        var selectedDateFormatted = selectedDate.toISOString();
        url += "&selectedDate=" + selectedDateFormatted;
    }
    //Commenting below line of code to resolve "The value 'null' is not valid" while parsing to Date
    //else {
    //    url += "&selectedDate=" + null ;
    //}

    url += "&selectedCountryId=" + selectedCountryId;
    
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include",

        });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        const json: Interfaces.TotalRunoff = yield response.json();
        yield put(Actions.set(json));
    } catch (e) {
        notification.error({ message: "Error loading Summary View", description: "Failed to access "+url });
    }
    yield put(Actions.setLoadingStatus(false));
}

function* loadPopoverCurrency() {
    const selectedDate = yield select(GeneralSelectors.getDate);
    const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);

    let url = config.apiBaseUrl + "/api/RunoffView/AllTotals?&selectedCountryId=" + selectedCountryId;
    if (selectedDate !== null) {
        var selectedDateFormatted = selectedDate.toISOString();
        url += "&selectedDate=" + selectedDateFormatted;
    } else {
        url += "&selectedDate=" + null;
    }
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",

            });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            }
        const json: Interfaces.CheckAllCurrencies = yield response.json();
        yield put(Actions.setAllTotals(json));
    } catch (e) {
        notification.error({ message: "Error loading totals for all currencies", description: "Failed to access " + url });
    }
    // yield put(Actions.setLoadingStatus(false));
}

function* watchAllTotals() {
    yield runoffViewTakeEvery(Actions.LOAD_ALL_TOTALS, loadPopoverCurrency);
}


function* watchLoad() {
    yield runoffViewTakeEvery(Actions.LOAD, load);
}

function* exportSaga(action: Actions.ExportAction) {
    
    try { 

        const selectedEntityId = yield select(GeneralSelectors.getEntity);
        const selectedCurrencyId = yield select(GeneralSelectors.getCurrency);
        const selectedDate = yield select(GeneralSelectors.getDate);
        const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);
        if (selectedEntityId == 0 || selectedCurrencyId == 0 || selectedDate == null) {
            throw new Error("Selection Error");
        }

        let url = config.apiBaseUrl + "/api/RunoffView/FileShareExport?selectedEntityId=" + selectedEntityId + "&selectedCurrencyId=" + selectedCurrencyId;
        var selectedDateAltered = new Date(selectedDate);
        selectedDateAltered.setDate(15); // fgreil 2019-08-21: What is this? Setting it to the mid of the month?
        var selectedDateAlteredFinal = selectedDateAltered.toISOString();
        url += "&selectedDate=" + selectedDateAlteredFinal;
        url += "&selectedCountryId=" + selectedCountryId;

        //const responseTest: Response = yield AuthenticatedRequest(fetch, url,
        //    {
        //        mode: "cors",
        //        credentials: "include",
        //        method: "POST"
        //    });


        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include",
            method: "POST"
            })
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            } else if (!response.ok) {
                    throw new Error("Unsuccessful Export")
                }
                
        notification.success({ message: "Success", description: "Export Successful" });
    }
    catch (e) {
        switch (e.message) {
            case "Selection Error":
                notification.error({ message: "Error - Export", description: "Invalid selection" });
                break;
            case "Unsuccessful Export":
                notification.error({ message: "Error - Export", description: "Export Failed" });
                break;
            default:
                notification.error({ message: "Error - Export", description: "Export Failed" });
                break;
        }
    }
    yield put(Actions.setExportingStatus(false));
}

function* watchExport() {
    yield runoffViewTakeEvery(Actions.EXPORT, exportSaga);
}

function* saveMappings(action : Actions.SaveMappingsAction) {
    const selectedEntityId = yield select(GeneralSelectors.getEntity);
    const selectedCurrencyId = yield select(GeneralSelectors.getCurrency);
    const selectedDate = yield select(GeneralSelectors.getDate);
    const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);
    let url = config.apiBaseUrl + "/api/RunoffView/SaveMappings?selectedEntityId="  + selectedEntityId + "&selectedCurrencyId=" + selectedCurrencyId;
    if (selectedDate !== null) {
        var selectedDateAltered = new Date(selectedDate);
        selectedDateAltered.setDate(15) ;
        var selectedDateAlteredFinal = selectedDateAltered.toISOString();
        url += "&selectedDate="+ selectedDateAlteredFinal;
    } else {
        url += "&selectedDate=" + null ;
    }
    url += "&selectedCountryId=" + selectedCountryId;
    const mappings  = action.map.entrySeq().map(([key, value]) => ({
        GeneralLedger: key,
        RiskPosition: value,
    })).toArray();


    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include",
            method: "POST",
            headers: new Headers({ "content-type": "text/json" }),
            body: JSON.stringify(mappings)
        });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        notification.success({ message: "Success", description: "Endpoint to save mappings called successfully called." });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving mappings" });
    }

    yield put(Actions.load());
}

function* watchSaveMappings() {
    yield runoffViewTakeEvery(Actions.SAVE_MAPPINGS, saveMappings);
}

function* loadDifferencePositions() {
    const url = config.apiBaseUrl + "/api/DifferencePositions";
    // console.log("UI/src/runoffView/sagas.ts/loadDifferencePositions() --> " + url)

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include"
        });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        }
        const json: Interfaces.RiskPositionName[] = yield response.json();
        yield put(Actions.setDifferencePositions(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading Difference Positions" });
    }
}

function* watchLoadDifferencePositions() {
    yield takeEvery(Actions.LOAD_DIFFERENCE_POSITIONS, loadDifferencePositions);
}


export function* saga() {
    yield fork(watchLoad);
    yield fork(watchSaveMappings);
    yield fork(watchExport);
    yield fork(watchLoadDifferencePositions);
    yield fork(watchAllTotals);
}