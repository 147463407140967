import { combineReducers } from "redux"
import { Map } from "immutable"
import * as Actions from "./actions";
import * as Interfaces from "../interfaces";

export type State = {
    availablePages: Interfaces.ErdrPage[],
    pagesFull: Map<number, Interfaces.ErdrPageFull>,
}

function availablePages(state = [], action : Actions.Action) {
    switch (action.type) {
        case Actions.SET_AVAILABLE_PAGES:
            return action.pages;
        case Actions.LOAD_AVAILABLE_PAGES:
            return action.forceReload ? [] : state;
        default:
            return state;
    }
}

function pagesFull(state = Map<number, Interfaces.ErdrPageFull>(), action) {
    switch (action.type) {
        case Actions.ADD_PAGE_FULL:
            return state.set(action.id, action.pageFull);
        default:
            return state;
    }
}

export const reducer = combineReducers<State>({
    availablePages,
    pagesFull
});