// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../../interfaces";

import { Table, Icon, Button, Input, Popconfirm } from "antd";
const { Column, ColumnGroup } = Table;

import * as Immutable from "immutable";
import moment from "moment";

export interface RunoffViewTableProps {
    selectedEntity: number,
    selectedCurrency: number,
    selectedDate: any,
    ccyCode: string,
    runoffDataLiability: Interfaces.RunoffViewEntry[],
}


function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { mimimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

function percentageWithCommas(x?) {
    try {
        return (x * 100).toLocaleString("de-DE", { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%';
    } catch (e) {
        return (x * 100).toFixed(2) + '%';
    }
}

function TrendArrow(x?) { // fgreil: Expects a fraction, not a percentage
    try {
        var percentage = x * 100; 
        return ((Math.abs(percentage) < 1) ? "\u2022" : ((x < 0) ? "\u25bc" : ((x > 0) ? "\u25b2" : "?")));
    } catch (e) {
        return "-";
    }
}

class MyTable extends Table<Interfaces.RunoffViewEntry> { }
class MyColumn extends Column<Interfaces.RunoffViewEntry> { }

function specialRulesRender(record: Interfaces.RunoffViewEntry) {
    if (record.children !== null) {
        return (record.children.filter(x => x.SpecialRules !== null).length > 0 ? <Icon type="check" /> : "-") // go through children and see if one has an special rule
    }
    else {
        return (record.SpecialRules !== null ? "-1" : "-")
    }
}


export class RunoffViewTableLiability extends React.Component<RunoffViewTableProps, any> {
    render() {
        const myColTitleStyle = {
            textOverflow: 'ellipsis',
            // overflow: "hidden",
            whiteSpace: 'nowrap'
        } as React.CSSProperties;

        const myColTitleStyleRight = {
            textOverflow: 'ellipsis',
            // overflow: "hidden",
            whiteSpace: 'nowrap',
            float: 'right'
        } as React.CSSProperties;

        const myFooterStyle = {
            float: "right",
            marginTop: "-10px"
            // textAlign: "center"
        } as React.CSSProperties;

        const myFooterElement = {
            float: "left",
            width: "150px",
            textAlign: "right"
        } as React.CSSProperties;

        const myBoldFooterElement = {
            fontWeight: "bold",
            float: "left",
            width: "200px",
            textAlign: "right"
        } as React.CSSProperties;

        const myFooterRight = {
            fontWeight: "bold",
            float: "left",
            width: "200px",
            marginRight: "10px",
            textAlign: "right"
        } as React.CSSProperties;

        const myData = this.props.runoffDataLiability;
        let sumAdjValue = 0;
        let sumPrevAdjValue = 0;
        let fracAdjValueToPrev = 0;
        if (myData) {
            myData.forEach(entry => {
                sumAdjValue += entry.ValueAdjusted;
                sumPrevAdjValue += entry.PreviousValueAdjusted;
            });
            if (sumPrevAdjValue != 0) {
                fracAdjValueToPrev = (sumAdjValue - sumPrevAdjValue) / sumPrevAdjValue;
            }
        }

        const sortDataByDisplayOrder = (data) => {
            if (data != null) {
                return data.slice().sort((a, b) => a.GeneralLedgerDisplayOrder - b.GeneralLedgerDisplayOrder); // VALUE to be replaced with GeneralLedgerDisplayOrder
            } else {
                return null;
            }
        }

        return (
                <MyTable className="runoff-view-table-liability" size="small"
                    dataSource={sortDataByDisplayOrder(this.props.runoffDataLiability)}
                    rowKey={(record) => (record.GeneralLedgerId * 100 + record.RiskPositionId).toString()} pagination={false} 
                    locale={{ emptyText: 'No suitable records for liability table found.' }}
                    showHeader={true}
                    footer={
                        () => {
                            return <div style={myFooterStyle}>
                                <div style={myBoldFooterElement}>
                                    {(this.props.runoffDataLiability) ? "Total: ".concat(
                                        numberWithCommas(sumAdjValue, this.props.ccyCode).toString()) : null}
                                </div>
                                <div style={myFooterElement}>
                                    {(this.props.runoffDataLiability && sumPrevAdjValue != 0) ?
                                        "Prev month: ".concat(
                                            TrendArrow(fracAdjValueToPrev * 100),
                                            percentageWithCommas(fracAdjValueToPrev)) : null}
                                </div>
                                <div style={myFooterRight}>
                                    {(this.props.runoffDataLiability && sumPrevAdjValue != 0) ?
                                        numberWithCommas(sumPrevAdjValue, this.props.ccyCode).toString() : null}
                                </div>
                            </div>
                        }

                    }
                    >
                    <MyColumn
                        title={<div style={myColTitleStyle}>Ledger Account</div>}
                        dataIndex="GeneralLedgerName"
                        key="GeneralLedgerName"
                        width="150px"
                    />
                    <MyColumn
                        title={<div style={myColTitleStyle}>Risk Position</div>}
                        dataIndex="RiskPositionName"
                        key="RiskPositionName"
                        width="150px"
                    />
                    { /* <MyColumn
                        title={<div style={myColTitleStyle}>General Ledger Display Order</div>}
                        dataIndex="GeneralLedgerDisplayOrder"
                        key="GeneralLedgerDisplayOrder"
                        width="40px"
                    /> */ }
                    <MyColumn
                        className="numeric-column"
                        title={<div style={myColTitleStyleRight}>Value</div>}
                        render={(text, record: Interfaces.RunoffViewEntry) => (record.Value !== 0) ?
                            numberWithCommas(record.Value, this.props.ccyCode) : "-"}
                        key="Value"
                        width="100px"
                    />
                    <MyColumn
                        className="numeric-column"
                        title={<div style={myColTitleStyleRight}>Thereof</div>}
                        render={(record) => (record.AdjustmentsThereof) ? numberWithCommas(record.AdjustmentsThereof, this.props.ccyCode) : "-"}
                        key="AdjustmentsThereof"
                        width="100px"
                    />
                    <MyColumn
                        className="numeric-column"
                        title={<div style={myColTitleStyleRight}>Adjustments</div>}
                        render={(record) => (record.Adjustments) ? numberWithCommas(record.Adjustments, this.props.ccyCode) : "-"}
                        key="Adjustments"
                        width="100px"
                    />
                    <MyColumn
                        className="numeric-column"
                        width="30px"
                    /> 

                    <MyColumn
                        className="numeric-column"
                        title={<div style={myColTitleStyleRight}>Adj. Value</div>}
                        render={(record) => (record.ValueAdjusted) ? numberWithCommas(record.ValueAdjusted, this.props.ccyCode) : "-"}
                        key="ValueAdjusted"
                        width="100px"
                    />
                    <MyColumn
                        className="numeric-column"
                        title={<div style={myColTitleStyleRight}>{'\u25b2\u25bc'}</div>}
                        render={(record) => (TrendArrow(record.ValueAdjustedDeltaPercentage))}
                        key="Arrow"
                        width="30px"
                    />
                    <MyColumn
                        className="numeric-column"
                        title={<div style={myColTitleStyleRight}>in %</div>}
                        render={(record) => ((record.ValueAdjustedDeltaPercentage !== 0) ?
                            percentageWithCommas(record.ValueAdjustedDeltaPercentage) : "0.0%")}
                key="ValueAdjustedDeltaPercentage"
                width="40px"
            />
                    <MyColumn
                        className="numeric-column"
                        title={<div style={myColTitleStyleRight}>Prev. Month: Adj. Value</div>}
                        render={(record) => (record.PreviousValueAdjusted) ? numberWithCommas(record.PreviousValueAdjusted, this.props.ccyCode) : "-"}
                        key="PreviousValueAdjusted"
                        width="100px"
                    />
                </MyTable>
        );
    }
}
