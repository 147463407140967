// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";
import { Table, Badge, Menu, Icon, Button, Input, DatePicker } from 'antd';
const { Column, ColumnGroup } = Table;
import * as Immutable from "immutable"
import moment from "moment";

interface MovAvgCellProps {
    editableStatus: boolean,
    dateValue: Date,
    editStateDateValue: (value: any) => void,
    RunOffModelType: number,
}

export default class MovAvgCell extends React.Component<MovAvgCellProps, {}> {

    constructor(props: any) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    //Change the state of the RiskPositionMappingTable parent component through it's function

    handleDateChange(e) {
        this.props.editStateDateValue(e);

    };

    disabledDate(current) {
        // Disable days before today and today
        return current && current > moment().endOf('day');
    }

    render() {
        const dateValue = this.props.dateValue;
        const editableStatus = this.props.editableStatus;
        const RunOffModelType = this.props.RunOffModelType;
        
        if (RunOffModelType === 3) {

            return (
                editableStatus
                    ? <div>
                        <DatePicker
                            format="DD MMMM YYYY"
                            defaultValue={this.props.dateValue !== null ? moment(this.props.dateValue) : null}
                            placeholder={"Insert Date"} disabledDate={this.disabledDate}
                            onChange={this.handleDateChange}>
                        </DatePicker>
                    </div>
                    : <div className="row-text">
                        {dateValue != null ? moment(dateValue).format("DD MMMM YYYY") : ""}
                    </div>
            );
        }

        else {
            return (
                <div className="row-text">
                    
                </div>
            );
        }
    }
};