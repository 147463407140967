import * as Interfaces from "../interfaces";
import * as Immutable from "immutable"

export type LOAD = "RUNOFF_VIEW/LOAD";
export const LOAD: LOAD = "RUNOFF_VIEW/LOAD";
export type LoadAction = {
    type: LOAD
}

export function load() : Action {
    return {
        type: LOAD
    }
}

export type SET = "RUNOFF_VIEW/SET";
export const SET: SET = "RUNOFF_VIEW/SET";
export type SetAction = {
    type: SET,
    totalRunoff: Interfaces.TotalRunoff,
}
export function set(totalRunoff: Interfaces.TotalRunoff) : Action {
    return {
        type: SET,
        totalRunoff,
    }
}

export type LOAD_ALL_TOTALS = "RUNOFF_VIEW/LOAD_ALL_TOTALS ";
export const LOAD_ALL_TOTALS: LOAD_ALL_TOTALS = "RUNOFF_VIEW/LOAD_ALL_TOTALS ";
export type LoadAllTotalsAction = {
    type: LOAD_ALL_TOTALS ,
}
export function loadAllTotals(): Action {
    return {
        type: LOAD_ALL_TOTALS 
    }
}

export type SET_ALL_TOTALS = "RUNOFF_VIEW/SET_ALL_TOTALS";
export const SET_ALL_TOTALS: SET_ALL_TOTALS = "RUNOFF_VIEW/SET_ALL_TOTALS";
export type SetAllTotalsAction = {
    type: SET_ALL_TOTALS,
    CheckAllCurrencies: Interfaces.CheckAllCurrencies,
}
export function setAllTotals(CheckAllCurrencies: Interfaces.CheckAllCurrencies): Action {
    return {
        type: SET_ALL_TOTALS,
        CheckAllCurrencies,
    }
}

export type LOAD_DIFFERENCE_POSITIONS = "RUNOFF_VIEW/LOAD_DIFFERENCE_POSITIONS";
export const LOAD_DIFFERENCE_POSITIONS: LOAD_DIFFERENCE_POSITIONS = "RUNOFF_VIEW/LOAD_DIFFERENCE_POSITIONS";
export type LoadDifferencePositionsAction = {
    type: LOAD_DIFFERENCE_POSITIONS,
}
export function loadDifferencePositions() : Action {
    return {
        type: LOAD_DIFFERENCE_POSITIONS
    }
}

export type SET_DIFFERENCE_POSITIONS = "RUNOFF_VIEW/SET_DIFFERENCE_POSITIONS";
export const SET_DIFFERENCE_POSITIONS: SET_DIFFERENCE_POSITIONS = "RUNOFF_VIEW/SET_DIFFERENCE_POSITIONS";
export type SetDifferencePositionsAction = {
    type: SET_DIFFERENCE_POSITIONS,
    differencePositions: Interfaces.RiskPositionName[],
}
export function setDifferencePositions(differencePositions: Interfaces.RiskPositionName[]) : Action {
    return {
        type: SET_DIFFERENCE_POSITIONS,
        differencePositions
    }
}

export type EXPORT = "RUNOFF_VIEW/EXPORT";
export const EXPORT: EXPORT = "RUNOFF_VIEW/EXPORT";
export type ExportAction = {
    type: EXPORT,
}
export function exportAction() : Action {
    return {
        type: EXPORT
    }
}

export type SAVE_MAPPINGS = "RUNOFF_VIEW/SAVE_MAPPINGS";
export const SAVE_MAPPINGS: SAVE_MAPPINGS = "RUNOFF_VIEW/SAVE_MAPPINGS";
export type SaveMappingsAction = {
    type: SAVE_MAPPINGS,
    map: Immutable.Map<number, number>,
}
export function saveMappings(map : Immutable.Map<number, number>) : Action {
    return {
        type: SAVE_MAPPINGS,
        map
    }
}


export type SET_LOADING_STATUS = "RUNOFF_VIEW/SET_LOADING_STATUS";
export const SET_LOADING_STATUS: SET_LOADING_STATUS = "RUNOFF_VIEW/SET_LOADING_STATUS";
export type SetLoadingStatusAction = {
    type: SET_LOADING_STATUS,
    isLoading: Boolean
}

export function setLoadingStatus(isLoading: Boolean): Action {
    return {
        type: SET_LOADING_STATUS,
        isLoading: isLoading
    }
}

export type SET_EXPORTING_STATUS = "RUNOFF_VIEW/SET_EXPORTING_STATUS";
export const SET_EXPORTING_STATUS: SET_EXPORTING_STATUS = "RUNOFF_VIEW/SET_EXPORTING_STATUS";
export type SetExportingStatusAction = {
    type: SET_EXPORTING_STATUS,
    isExporting: Boolean
}

export function setExportingStatus(isExporting: Boolean): Action {
    return {
        type: SET_EXPORTING_STATUS,
        isExporting: isExporting
    }
}

export type OtherAction = { type: "RUNOFF_VIEW/Default" }
export const OtherAction : OtherAction = {type: "RUNOFF_VIEW/Default"}

export type Action = LoadAction | SetAction | LoadDifferencePositionsAction | SetDifferencePositionsAction | ExportAction | SaveMappingsAction | SetLoadingStatusAction | SetExportingStatusAction | OtherAction | SetAllTotalsAction | LoadAllTotalsAction
