import * as Interfaces from "../interfaces"
import * as Actions from "./actions"
import { combineReducers } from "redux"

export type State = {
    countries: Interfaces.MifCountry[],
    entities: Interfaces.MifEntity[],
    currencies: Interfaces.MifCurrency[],
    date: Date,
    country: number,
    entity: number,
    currency: number,
    riskPositionNames: Interfaces.RiskPositionName[],
}

function countries(state : Interfaces.MifCountry[] = [], action : Actions.Action) {
    switch (action.type) {
        case Actions.SET_COUNTRIES:
            return action.files;
        case Actions.LOAD_COUNTRIES:
            return [];
        default:
            return state;
    }
}

function entities(state : Interfaces.MifEntity[] = [], action : Actions.Action) {
    switch (action.type) {
        case Actions.SET_ENTITIES:
            return action.files;
        case Actions.LOAD_ENTITIES:
            return [];
        default:
            return state;
    }
}

function currencies(state : Interfaces.MifCurrency[] = [], action : Actions.Action) {
    switch (action.type) {
        case Actions.SET_CURRENCIES:
            return action.files;
        case Actions.LOAD_CURRENCIES:
            return [];
        default:
            return state;
    }
}

function date(state = null, action: Actions.Action) {
    switch (action.type) {
        case Actions.SELECT_DATE:
            return action.date;
        default:
            return state;
    }
}

function country(state = 0, action : Actions.Action) {
    switch (action.type) {
        case Actions.SELECT_COUNTRY:
            return action.id;
        default:
            return state;
    }
}

function entity(state = 0, action : Actions.Action) {
    switch (action.type) {
        case Actions.SELECT_ENTITY:
            return action.id;
        case Actions.SELECT_COUNTRY:
            return 0;
        default:
            return state;
    }
}

function currency(state = 0, action : Actions.Action) {
    switch (action.type) {
        case Actions.SELECT_CURRENCY:
            return action.id;
        case Actions.SELECT_COUNTRY:
            return 0;
        case Actions.SELECT_ENTITY:
            return 0;
        default:
            return state;
    }
}

function riskPositionNames(state : Interfaces.RiskPositionName[] = [], action : Actions.Action) {
    switch (action.type) {
        case Actions.SET_RISK_POSITION_NAMES:
            return action.riskPositionNames;
        case Actions.LOAD_RISK_POSITION_NAMES:
            return [];
        default:
            return state;
    }
}

export const reducer = combineReducers<State>({
    countries,
    entities,
    currencies,
    date,
    country,
    entity,
    currency,
    riskPositionNames,
});
