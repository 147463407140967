import {State} from "./reducers"
import { general } from "../selectors"
import * as Top from "../reducers"
import {createSelector} from "reselect"

const getCountriesInner = (state: State) => state.countries;
export const getCountries = (state: Top.State) => getCountriesInner(general(state));

const getEntitiesInner = (state: State) => state.entities;
export const getEntities = (state: Top.State) => getEntitiesInner(general(state));

const getCurrenciesInner = (state: State) => state.currencies;
export const getCurrencies = (state: Top.State) => getCurrenciesInner(general(state));

const getDateInner = (state: State) => state.date;
export const getDate = (state: Top.State) => getDateInner(general(state));

const getCountryInner = (state: State) => state.country;
export const getCountry = (state: Top.State) => getCountryInner(general(state));

const getEntityInner = (state: State) => state.entity;
export const getEntity = (state: Top.State) => getEntityInner(general(state));

const getCurrencyInner = (state: State) => state.currency;
export const getCurrency = (state: Top.State) => getCurrencyInner(general(state));

const getCurrencyByIdInner = (state: State) => state.currencies.find(x => x.Id === getCurrencyInner(state));
export const getCurrencyById = (state: Top.State) => getCurrencyByIdInner(general(state));

export const getAvailableEntities = createSelector(getEntities,
    getCountry,
    (entities, country) => entities.filter(x => x.CountryId === country || country === 0));

const getRiskPositionNamesInner = (state: State) => state.riskPositionNames;
export const getRiskPositionNames = (state : Top.State) => getRiskPositionNamesInner(general(state))