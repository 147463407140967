// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as ReactDOM from "react-dom";

import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from "react-redux";
// https://github.com/ReactTraining/history/issues/680
import { createBrowserHistory } from 'history';

import { Route } from 'react-router';
import { ConnectedRouter, routerMiddleware, push } from 'connected-react-router'
import App from "./app";

import * as Actions from "./actions"

//No British English library by default so have to use US English
import enUS from 'antd/lib/locale-provider/en_US';
import { Layout, LocaleProvider } from "antd";

import * as Interfaces from "./interfaces"
import bstbApp from './reducers'
import rootSaga from "./sagas"

const historyService = createBrowserHistory();
const myRouterMiddleware = routerMiddleware(historyService);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(bstbApp(historyService), applyMiddleware(sagaMiddleware, myRouterMiddleware));
sagaMiddleware.run(rootSaga);

//const user = JSON.parse(localStorage.getItem('user'));
//if (user) {
//    store.dispatch(Actions.userLoggedIn(user))
//}

console.log("store----", store.getState())

ReactDOM.render(

    <LocaleProvider locale={enUS}>
        <Provider store={store}>
            <App />
        </Provider>
    </LocaleProvider>,
    document.getElementById("content")
);

