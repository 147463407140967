import {match} from "react-router-dom"
import * as Interfaces from "../interfaces"
import { State } from "./reducers"
import { specialRules } from "../selectors"
import * as Top from "../reducers"

const getSpecialRulesInner = (state: State) => state.specialRules;
export const getSpecialRules = (state: Top.State) => getSpecialRulesInner(specialRules(state));

const getThereofsInner = (state: State) => state.thereofs;
export const getThereofs = (state: Top.State) => getThereofsInner(specialRules(state));
