// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../../interfaces";

import { Table, Icon, Button, Input, Popconfirm, notification } from 'antd';
const { Column, ColumnGroup } = Table;

import * as Immutable from "immutable";
import { SelectableCellSpecialRules } from "./SelectableCellSpecialRules";

import moment from "moment";
import DropDownCell from "../../components/DropDownCell"


export interface SpecialRulesTableProps {
    specialRules: Interfaces.SpecialRulesEntry[],
    mifCountries: Interfaces.MifCountry[],
    mifEntities: Interfaces.MifEntity[],
    mifCurrencies: Interfaces.MifCurrency[],
    riskPositionNames: Interfaces.RiskPositionName[],

    addSaveSpecialRule: (x: Interfaces.SpecialRulesEntry) => void;
    deleteSpecialRule: (x: number) => void;
    editSaveSpecialRule: (x: Interfaces.SpecialRulesEntry) => void;

}

interface SpecialRulesTableState {
    data: Immutable.Map<number, Interfaces.SpecialRulesEntry>,
    countryFilters: any[],
    entityFilters: any[],
    currencyFilters: any[],
    affectedRiskPositionFilters: any[]
    
}


class MyTable extends Table<Interfaces.SpecialRulesEntry> { }
class MyColumn extends Column<Interfaces.SpecialRulesEntry> { }

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}
function convertToFilter(Text) {
    return { text: Text, value: Text };
}

export class SpecialRulesTable extends React.Component<SpecialRulesTableProps, SpecialRulesTableState> {

    //constructor
    constructor(props) {
        super(props);
        
        this.state = {
            data: Immutable.Map<number, Interfaces.SpecialRulesEntry>(),
            countryFilters: [],
            entityFilters: [],
            currencyFilters: [],
            affectedRiskPositionFilters: []
            
        };

        this.handleEditableCellValueChange = this.handleEditableCellValueChange.bind(this);
    }


    //componentWillReceiveProps
    UNSAFE_componentWillReceiveProps(nextProps) {

        this.setState({
            countryFilters: [...new Set(nextProps.specialRules.map(x => x.CountryName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)),
            entityFilters: [...new Set(nextProps.specialRules.map(x => x.EntityName || " "))].map(convertToFilter),
            currencyFilters: [...new Set(nextProps.specialRules.map(x => x.CurrencyName || " "))].map(convertToFilter),
            affectedRiskPositionFilters: [...new Set(nextProps.specialRules.map(x => x.AffectedRiskPositionName || " "))].map(convertToFilter)
        });
    }


    //DropDownCell change function
    handleDropDownEditableCellValueChange = (recordId: number, fieldName: string) =>
        (value: any) => {
            const oldDataEntry = this.state.data.get(recordId);
            var update = {};
            update[fieldName] = parseInt(value);
            const newDataEntry: Interfaces.SpecialRulesEntry = Object.assign({}, oldDataEntry, update);
            const newData = this.state.data.set(recordId, newDataEntry);
            this.setState({ data: newData });
        }

    //handleEditableCellValueChange
    handleEditableCellValueChange = (recordId: number, fieldName: string, value: any) => {

        const oldRecord = this.state.data.get(recordId);

        const newRecord: Interfaces.SpecialRulesEntry = ((columnName) => {
            switch (columnName) {               
                case "ValidFrom":
                    return Object.assign({}, oldRecord, { ValidFrom: (value.startOf("month").add(-value.startOf("month").toDate().getTimezoneOffset(), "minutes")).toDate() });
                case "ValidTo":
                    return Object.assign({}, oldRecord, { ValidTo: value == null ? null : value.endOf("month").add(-1, "minutes").toDate() });
                case "Comment":
                    return Object.assign({}, oldRecord, { Comment: value.target.value });
                default:
                    return oldRecord;
            }
        })(fieldName);

        var newData = this.state.data.set(recordId, newRecord);
        this.setState({ data: newData });
    }


    //handleAdd
    handleAdd = () => {

        var test = moment().startOf("month").add(-moment().startOf("month").toDate().getTimezoneOffset(), "minutes");
        console.log(test);

        const newData: Interfaces.SpecialRulesEntry = {
            Id: 0,
            CountryId: null,
            CountryName: null,
            EntityId: null,
            EntityName: null,
            CurrencyId: null,
            CurrencyName: null,
            AffectedRiskPositionId: null,
            AffectedRiskPositionName: null,
            EndValue: null,
            ValidFrom: moment().startOf("month").add(-moment().startOf("month").toDate().getTimezoneOffset(), "minutes").toDate(),
            ValidTo: null,
            EffectiveFrom: moment().toDate(), 
            EffectiveFromUserId: null,
            EffectiveFromUserFullName: null,
            EffectiveTo: null,
            EffectiveToUserId: null,
            EffectiveToUserFullName: null,
            Comment: null

        };

        const imm = this.state.data;
        var newStateData = imm.set(0, newData);
        this.setState({ data: newStateData });
    };


    handleSave = (record: Interfaces.SpecialRulesEntry) => {
        var entry = this.state.data.get(record.Id);

        if (!entry.AffectedRiskPositionId) {
            notification["error"]({
                message: 'Error',
                description: "Must select Affected Risk Position",
                duration: 3
            });
        }  else if (entry.Id === 0) {
            this.props.addSaveSpecialRule(entry);
            const newData = this.state.data.delete(record.Id);
            this.setState({ data: newData });
        } else {
            this.props.editSaveSpecialRule(entry);
            const newData = this.state.data.delete(record.Id);            
            this.setState({ data: newData });
        }
    }

    //handleCancel
    handleCancel = (recordId: number) => {
        const imm = this.state.data;

        var newStateData = imm.delete(recordId);
        this.setState({ data: newStateData });
    }

    //handleEdit
    handleEdit = (record: Interfaces.SpecialRulesEntry) => {
        var newStatedata = this.state.data.set(record.Id, record);
        this.setState({ data: newStatedata });
    }

    //handleDelete
    handleDelete = (id) => {
        this.props.deleteSpecialRule(id);
    }

    
    //renderColumns
    renderColumns(recordEntry: Interfaces.SpecialRulesEntry, columnName) {
        const edit = this.state.data.get(recordEntry.Id);
        if (edit && (
            columnName === "EndValue" ||
            columnName === "ValidFrom" ||
            columnName === "ValidTo" ||                       
            columnName === "Comment" )){
            return (<SelectableCellSpecialRules
                recordId={recordEntry.Id}
                editableStatus={true}
                columnName={columnName}
                value={edit[columnName]}
                editStateValue={this.handleEditableCellValueChange}
            />
            );
        } else if (columnName === "ValidFrom") {
                return (moment(recordEntry[columnName]).format("MMMM YYYY"));
        }
          else if (columnName === "ValidTo") {
            return recordEntry[columnName] != null ? moment(recordEntry[columnName]).format("MMMM YYYY") : "";
        }
        return (recordEntry[columnName]);
    }


    //render
    render() {

        var specialRulesDataSource = this.props.specialRules.slice();
        const addSpecialRule = this.state.data.get(0);
        if (!!addSpecialRule)
            specialRulesDataSource.push(addSpecialRule);


        //having a conditionally rendered button 
        var hasbutton: boolean = !this.state.data.has(0);

        return (
            <div className="specialRules">
                <MyTable className="specialrules-table" size="small" dataSource={specialRulesDataSource} rowKey="Id" scroll={{ x:1600 }} pagination={false}>
                    <MyColumn
                        title="Country"
                        render={(record) => <DropDownCell options={this.props.mifCountries} value={record.CountryId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CountryId")} />}
                        key="CountryName"                        
                        fixed="left"
                        filters={this.state.countryFilters.length !== 0 ? this.state.countryFilters : null}
                        onFilter={(value, record) => (record.CountryName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CountryName || " ", b.CountryName || " ")}
                        width={200}
                    />
                    <MyColumn
                        title="Entity"
                        render={(record) => <DropDownCell options={this.props.mifEntities} value={record.EntityId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "EntityId")} />}
                        key="EntityName"                        
                        fixed="left"
                        filters={this.state.entityFilters.length !== 0 ? this.state.entityFilters : null}
                        onFilter={(value, record) => (record.EntityName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.EntityName || " ", b.EntityName || " ")}
                        width={200}
                    />
                    <MyColumn
                        title="Currency"
                        render={(record) => <DropDownCell options={this.props.mifCurrencies} value={record.CurrencyId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CurrencyId")} />}
                        key="CurrencyName"                        
                        fixed="left"
                        filters={this.state.currencyFilters.length !== 0 ? this.state.currencyFilters : null}
                        onFilter={(value, record) => (record.CurrencyName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CurrencyName || " ", b.CurrencyName || " ")}
                        width={200}
                    />
                    <MyColumn
                        title="Valid From"
                        //dataIndex="ValidFrom"
                        render={(record) => this.renderColumns(record, "ValidFrom")}
                        key="ValidFrom"
                        sorter={(a, b) => nonNumberSorter(a.ValidFrom, b.ValidFrom)}
                        width={150} />
                    <MyColumn
                        title="Valid To"
                        //dataIndex="ValidTo"
                        render={(record) => this.renderColumns(record, "ValidTo")}
                        key="ValidTo"
                        sorter={(a, b) => nonNumberSorter(a.ValidTo || " ", b.ValidTo || " ")}
                        width={150} />
                    <MyColumn
                        title="Affected Risk Position"
                        //dataIndex="AffectedRiskPositionName"                        
                        render={(record) => <DropDownCell options={this.props.riskPositionNames} value={record.AffectedRiskPositionId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "AffectedRiskPositionId")} />}
                        key="AffectedRiskPositionName"
                        filters={this.state.affectedRiskPositionFilters.length !== 0 ? this.state.affectedRiskPositionFilters : null}
                        onFilter={(value, record) => (record.AffectedRiskPositionName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.AffectedRiskPositionName || " ", b.AffectedRiskPositionName || " ")}
                        width={200} />
                    <MyColumn
                        title="Comment"
                        render={(record) => this.renderColumns(record, "Comment")}
                        key="Comment"
                        sorter={(a, b) => nonNumberSorter(a.Comment || " ", b.Comment || " ")}
                        width={200} />
                    <MyColumn
                        title="Last Updated"
                        //dataIndex="EffectiveFrom"
                        render={(text, record: Interfaces.SpecialRulesEntry) =>
                            record.Id !== 0 ? record.EffectiveFromUserFullName + " on " + moment(record.EffectiveFrom).format("DD MMMM YYYY") : ""}
                        sorter={(a, b) => nonNumberSorter(a.EffectiveFrom, b.EffectiveFrom)}
                        key="EffectiveFrom"
                        width={200}
                    />
                    <MyColumn
                        fixed="right"
                        width={100}
                        title=""
                        key="action"
                        render={(text, record) => (
                            this.state.data.get(record.Id)
                                ? <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSave(record);
                                    }}>Save</a>
                                    <span className="ant-divider" />
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleCancel(record.Id);
                                    }}>Cancel</a>
                                </span>
                                : <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleEdit(record);
                                    }}>Edit</a>
                                    <span className="ant-divider" />

                                    <Popconfirm title="Sure to Delete?" onConfirm={(e) => {
                                        this.handleDelete(record.Id);

                                    }}>
                                        <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                    </Popconfirm>

                                </span>)
                        } />
                </MyTable>
                <br />
                <span className="adjustments-buttons">
                    {hasbutton ? <Button type="primary" onClick={() => this.handleAdd()}>Add</Button> : ""}
                </span>
                <br />
            </div>

        );
    }
}
