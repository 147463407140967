import { combineReducers } from "redux"
import { Map } from "immutable"
import * as Actions from "./actions";
import * as Interfaces from "../interfaces";

export type State = {
    availablePages: Interfaces.MifPageList[],
    pagesFull: Map<number, Interfaces.MifPageFull>,
    currencyFilter: number,
    finalisingStatus: Boolean,  
    mifPageTableLoadingStatus: Boolean
}

function availablePages(state : Interfaces.MifPageList[] = [], action : Actions.Action) {
    switch (action.type) {
    case Actions.SET_AVAILABLE_PAGES:
        return action.pages;
    case Actions.LOAD_AVAILABLE_PAGES:
        return action.forceReload ? [] : state;
    default:
        return state;
    }
}

function pagesFull(state = Map<number, Interfaces.MifPageFull>(), action : Actions.Action) {
    switch (action.type) {
        case Actions.ADD_PAGE_FULL:
            return state.set(action.id, action.pageFull);
        case Actions.RESET_PAGES_FULL:
            return !action.id ? Map<number, Interfaces.MifPageFull>() : state;
        default:
            return state;
    }
}

function currencyFilter(state = 0, action : Actions.Action) {
    switch(action.type) {
        case Actions.SET_CURRENCY_FILTER:
            return action.id;
        case Actions.LOAD_AVAILABLE_PAGES:
            return 0;
        default:
            return state;
    }
}

function finalisingStatus(state: boolean = false, action: Actions.Action) {
    switch (action.type) {
        case Actions.SET_FINALISING_STATUS:            
            return action.isFinalising;
        default:
            return state;
    }
}

function mifPageTableLoadingStatus(state: boolean = true, action: Actions.Action) {
    switch (action.type) {
        case Actions.SET_LOADING_STATUS:
            return action.isLoading;
        default:
            return state;
    }
}


export const reducer = combineReducers<State>({
    availablePages,
    pagesFull,
    currencyFilter,
    finalisingStatus,
    mifPageTableLoadingStatus
});