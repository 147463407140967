import UrlMap from "../UrlMap"

import * as Actions from "./actions"
import * as GeneralActions from "../general/actions"

export const urls = [
    new UrlMap("ErdrData/:pageId?",
        (params: { pageId?: string }) => [
            Actions.loadAvailablePages(true),
            GeneralActions.loadCountries(),
            GeneralActions.loadEntities(),
            GeneralActions.loadCurrencies(),
            Actions.loadPageFull(params.pageId && parseInt(params.pageId)),
        ])
];