declare const config : {
    apiBaseUrl : string;
    uiBaseUrl: string;
    env: string;
    sso: string;
    client: string;
    redirect: string;
    accessDeniedRedirect: string;
}

export default config;