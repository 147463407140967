import * as React from "react";
import * as ReactDom from "react-dom";
import ThereofsTableContainer from "../containers/ThereofsTableContainer";

const ThereofsData = (props) =>
    <div>
        <h1 style={{ paddingTop: "25px" }} />
        <ThereofsTableContainer />

    </div>;

export default ThereofsData; 