// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces"
import { Table, Icon, Button, Input, Select } from 'antd';
const { Column, ColumnGroup } = Table;
const Option = Select.Option;



export interface ErdrSecDepPageProps {
    page: Interfaces.ErdrSecDepEntry[];
    isLoading: boolean;
}



function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}



class MyTable extends Table<Interfaces.ErdrSecDepEntry> { }
class MyColumn extends Column<Interfaces.ErdrSecDepEntry> { }



export default class ErdrSecDepPage extends React.Component<ErdrSecDepPageProps, undefined> {

    render() {

        var page = this.props.page;

        if (this.props.page == null) {
            return <div>
                <h2 style={{ textAlign: "center" }}>{this.props.isLoading ? "Loading..." : "No Data"}</h2>
            </div>;
        } else {
            return (

                <MyTable className="erdr-page" size="small" dataSource={this.props.page} pagination={this.props.page.length > 10} rowKey="Id">
                    <MyColumn
                        title="Currency Name"
                        dataIndex="CurrencyName"
                        key="CurrencyName"
                        filters={[...new Set(this.props.page.map(x => x.CurrencyName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value))}
                        onFilter={(value, record) => record.CurrencyName.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CurrencyName, b.CurrencyName)}
                        width={100} />
                    <MyColumn
                        className="numeric-column"
                        title="Security Deposit"
                        render={(text, record: any) => (record.SecurityDeposit !== null) ? numberWithCommas(record.SecurityDeposit, record.CurrencyCode) : "-"}
                        key="SecurityDeposit"
                        sorter={(a, b) => a.SecurityDeposit - b.SecurityDeposit}
                        width={100} />
                </MyTable>
            );

        }
    }
}