import * as React from "react";
import * as ReactDom from "react-dom";
import EquityInjectionsFilterContainer from "../containers/EquityInjectionsFilterContainer";

const EquityInjectionsData = (props) =>
    <div>
        <h1 style={{ paddingTop: "37px" }} />
        <EquityInjectionsFilterContainer />

    </div>;

export default EquityInjectionsData; 