import * as React from "react";
import * as ReactDom from "react-dom";
import RunoffViewContainer from "../containers/RunoffViewContainer";

const RunoffData = (props) =>
    <div>
        <RunoffViewContainer />
    </div>;

export default RunoffData;  

