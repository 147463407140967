import * as React from "react";
import * as ReactDOM from "react-dom";
import { Menu, Icon } from "antd"
// import { Router } from "react-router"
import { Link, BrowserRouter as Router } from "react-router-dom"
import { Location } from "history";
import config from "../config";
const DodgyMenu: any = Menu; //workaround for missing selectable prop on Menu, remove when fixed upstream
const SubMenu = Menu.SubMenu;


export interface MainMenuState {
    openKeys: string[];
}

export interface MainMenuProps {
    location: Location;
}

export default class MainMenu extends React.Component<MainMenuProps, MainMenuState> {
    //constructor
    constructor(props) {
        super(props);

        this.state = {
            openKeys: []
        };


    }

    handleTitleClick = (e) => {
        if (this.state.openKeys.indexOf(e.key.toString()) === -1) {

            this.setState((prevState, props) => {
                return {
                    openKeys: prevState.openKeys.concat(e.key.toString())
                }
            });
        } else {
            var filteredOpenKeys = this.state.openKeys.filter(f => f !== e.key.toString());
            this.setState({
                openKeys: filteredOpenKeys
            })

        }
    }


    render() {
        const re = RegExp("/([a-zA-Z]+)(/.*)?");
        const match = re.exec(this.props.location.pathname);
        const current = match ? match[1] : null;
        let newOpenKeys = [];
        switch (current) {
            case "MifData":
            case "ErdrData":
            case "Adjustments":
            case "EquityInjections":
            case "SummaryView":
            case "FileUpload":
                newOpenKeys = ["Data"];
                break;
            case "RiskPositionMappings":
            case "RiskPositionModels":
            case "RiskPositions":
            case "DrmFileUpload":
                newOpenKeys = ["Settings"];
                break;
            case "Thereofs":
                newOpenKeys = ["AdjustmentRules", "Settings"];
                break;
        }

        return (
            <DodgyMenu
                selectedKeys={[current]}
                mode="inline"
                selectable={false}
                className="main-menu"
                theme="dark"
                openKeys={this.state.openKeys.concat(newOpenKeys)}
                style={{ height: '100%', width: '100%' }}
            >
                <SubMenu title={<span><Icon type="user" />Profile</span>} key="Profile" onTitleClick={this.handleTitleClick}>
                    <Menu.Item disabled={true}>
                        <Link to={config.uiBaseUrl + ""}><Icon type="bell" />Notifications</Link>
                    </Menu.Item>
                    <Menu.Item disabled={true}>
                        <Link to={config.uiBaseUrl + ""}><Icon type="lock" />Permissions</Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu title={<span><Icon type="area-chart" />Data</span>} key="Data" onTitleClick={this.handleTitleClick}>
                    <Menu.Item key="FileUpload">
                        <Link to={config.uiBaseUrl + "FileUpload"}><Icon type="upload" />Import</Link>
                    </Menu.Item>
                    <Menu.Item key="MifData">
                        <Link to={config.uiBaseUrl + "MifData/"}><Icon type="database" />MIF Data</Link>
                    </Menu.Item>
                    <Menu.Item key="ErdrData">
                        <Link to={config.uiBaseUrl + "ErdrData/"}><Icon type="database" />eRDR/ FBSR Data</Link>
                    </Menu.Item>
                    <Menu.Item key="Adjustments">
                        <Link to={config.uiBaseUrl + "Adjustments"}><Icon type="edit" />Adjustments</Link>
                    </Menu.Item>
                    <Menu.Item key="EquityInjections">
                        <Link to={config.uiBaseUrl + "EquityInjections"}><Icon type="arrow-right" />Equity Measures</Link>
                    </Menu.Item>
                    <Menu.Item key="SummaryView">
                        <Link to={config.uiBaseUrl + "SummaryView/"}><Icon type="layout" />Summary View</Link>
                    </Menu.Item>

                </SubMenu>

                <SubMenu title={<span><Icon type="setting" />Settings</span>} key="Settings" onTitleClick={this.handleTitleClick}>
                    <Menu.Item key="RiskPositionMappings">
                        <Link to={config.uiBaseUrl + "RiskPositionMappings"}><Icon type="share-alt" />Risk Position Mappings</Link>
                    </Menu.Item>
                    <Menu.Item key="RiskPositionModels">
                        <Link to={config.uiBaseUrl + "RiskPositionModels"}><Icon type="book" />Risk Position Model</Link>
                    </Menu.Item>
                    <Menu.Item key="RiskPositions">
                        <Link to={config.uiBaseUrl + "RiskPositions"}><Icon type="book" />Risk Positions</Link>
                    </Menu.Item>
                    <SubMenu title={<span><Icon type="setting" />Adjustment Rules</span>} key="AdjustmentRules" onTitleClick={this.handleTitleClick}>
                        {/* -- removed as per https://www.bugtracking-d-fine.de/browse/BSTB-674 fgreil 2019-11-07
                         <Menu.Item key="SpecialRules">
                          <Link to={config.uiBaseUrl + "SpecialRules"}><Icon type="edit" />Special Rules</Link>
                         </Menu.Item> 
                       */}
                        <Menu.Item key="Thereofs">
                            <Link to={config.uiBaseUrl + "Thereofs"}><Icon type="edit" />Thereof</Link>
                        </Menu.Item>
                    </SubMenu>
                   <Menu.Item key="DrmFileUpload">
                        <Link to={config.uiBaseUrl + "DrmFileUpload"}><Icon type="upload" />Import Configuration </Link>
                        </Menu.Item>
            </SubMenu>
                </DodgyMenu >
        );        
    }
}