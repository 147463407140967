import * as Interfaces from "./interfaces";

import * as General from "./general"
import * as Mif from "./mif"
import * as Erdr from "./mif"
import * as RunoffView from "./runoffView"
import * as SpecialRules from "./specialRules"

export type Action = General.Actions.Action | Mif.Actions.Action | Erdr.Actions.Action | RunoffView.Actions.Action | SpecialRules.Actions.Action

export const LOAD_INPUT_FILES = "LOAD_INPUT_FILES";
export const SET_INPUT_FILES = "SET_INPUT_FILES";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const AUTO_UPLOAD_FILE = "AUTO_UPLOAD_FILE";
export const LOAD_DRM_INPUT_FILES = "LOAD_DRM_INPUT_FILES";
export const SET_DRM_INPUT_FILES = "SET_DRM_INPUT_FILES";
export const UPLOAD_DRM_FILE = "UPLOAD_DRM_FILE";
export const SET_AVAILABLE_RISK_POSITIONS = "SET_AVAILABLE_RISK_POSITIONS";
export const LOAD_RISK_POSITION_IDS = "LOAD_RISK_POSITION_IDS";
export const ADD_DETAILED_RISK_POSITION = "ADD_RISK_POSITION";
export const ADD_SAVE_DETAILED_RISK_POSITION = "ADD_SAVE_DETAILED_RISK_POSITION";
export const DELETE_DETAILED_RISK_POSITION = "DELETE_DETAILED_RISK_POSITION";
export const EDIT_SAVE_DETAILED_RISK_POSITION = "EDIT_SAVE_DETAILED_RISK_POSITION";
//----------------------------------------------------------
//adjustment Actions
export const LOAD_ADJUSTMENTS = "LOAD_ADJUSTMENTS";
export const SET_ADJUSTMENTS = "SET_ADJUSTMENTS";
export const ADD_SAVE_ADJUSTMENT = "ADD_SAVE_ADJUSTMENT";
export const EDIT_SAVE_ADJUSTMENT = "EDIT_SAVE_ADJUSTMENT";
export const DELETE_ADJUSTMENT = "DELETE_ADJUSTMENT";
// --------------------------------------------------------
//Risk Position Model Actions
export const LOAD_RISK_POSITION_MODELS = "LOAD_RISK_POSITION_MODELS";
export const ADD_SAVE_RISK_POSITION_MODEL = "ADD_SAVE_RISK_POSITION_MODEL";
export const DELETE_RISK_POSITION_MODEL = "DELETE_RISK_POSITION_MODEL";
export const EDIT_SAVE_RISK_POSITION_MODEL = "EDIT_SAVE_RISK_POSITION_MODEL";
export const SET_RISK_POSITION_MODELS = "SET_RISK_POSITION_MODELS";
export const SET_GENERAL_LEDGERS = "SET_GENERAL_LEDGERS";
export const LOAD_GENERAL_LEDGERS = "LOAD_GENERAL_LEDGERS";
export const SET_RUNOFF_MODELS = "SET_RUNOFF_MODELS";
export const LOAD_RUNOFF_MODELS = "LOAD_RUNOFF_MODELS";
export const SET_RUNOFF_MATURITY_TYPES = "SET_RUNOFF_MATURITY_TYPES";
export const LOAD_RUNOFF_MATURITY_TYPES = "LOAD_RUNOFF_MATURITY_TYPES";
export const SET_RUNOFF_IRR_TYPES = "SET_RUNOFF_IRR_TYPES";
export const LOAD_RUNOFF_IRR_TYPES = "LOAD_RUNOFF_IRR_TYPES";
export const SET_RUNOFF_MFR_MFS_TYPES = "SET_RUNOFF_MFR_MFS_TYPES";
export const LOAD_RUNOFF_MFR_MFS_TYPES = "LOAD_RUNOFF_MFR_MFS_TYPES";

// Risk Position Actions
export const LOAD_RISK_POSITIONS = "LOAD_RISK_POSITIONS";
export const ADD_SAVE_RISK_POSITION = "ADD_SAVE_RISK_POSITION";
export const DELETE_RISK_POSITION = "DELETE_RISK_POSITION";
export const SET_RISK_POSITIONS = "SET_RISK_POSITIONS";

// Modelable Risk Position Actions
export const SET_MODELABLE_RISK_POSITIONS = "SET_MODELABLE_RISK_POSITIONS";
export const LOAD_MODELABLE_RISK_POSITIONS = "LOAD_MODELABLE_RISK_POSITIONS";

export const USER_LOGGEDIN = "USER_LOGGEDIN";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_LOCAL_USER = "LOGIN_LOCAL_USER";
export const INIT_DATA_WITH_TOKEN = "INIT_DATA_WITH_TOKEN";
export const GET_REFRESH_TOKEN = "GET_REFRESH_TOKEN";

//-----------------------------------------------------------------------------------------------------
//Risk Position Actions

//SET_AVAILABLE_RISK_POSITIONS
//LOAD_RISK_POSITIONS
//ADD_RISK_POSITION

export function setAvailableRiskPositions(riskPositions: Interfaces.RiskPosition[]) {
    return {
        type: SET_AVAILABLE_RISK_POSITIONS,
        riskPositions: riskPositions
    }
}

export function loadRiskPositionIds(forceReload = false) {
    return {
        type: LOAD_RISK_POSITION_IDS,
        forceReload: forceReload
    }
}

export function addDetailedRiskPosition(detailedRiskPosition: Interfaces.RiskPositionOverride) {
    return {
        type: ADD_DETAILED_RISK_POSITION,
        detailedRiskPosition: detailedRiskPosition
    }
}

export function addSaveDetailedRiskPosition(detailedRiskPosition: Interfaces.RiskPositionOverride) {
    return {
        type: ADD_SAVE_DETAILED_RISK_POSITION,
        detailedRiskPosition: detailedRiskPosition
    }
}

export function deleteDetailedRiskPosition(riskPositionId: number) {
    return {
        type: DELETE_DETAILED_RISK_POSITION,
        riskPositionId: riskPositionId
    }
}

export function editSaveDetailedRiskPosition(detailedRiskPosition: Interfaces.RiskPositionOverride) {
    return {
        type: EDIT_SAVE_DETAILED_RISK_POSITION,
        detailedRiskPosition: detailedRiskPosition
    }
}


//------------------------------------------ Import page ------------------------------------------------------------

export function loadInputFiles() {
    return {
        type: LOAD_INPUT_FILES
    }
}

export function setInputFiles(files: Interfaces.InputFile[]) {
    return {
        type: SET_INPUT_FILES,
        files: files
    }
}

export function uploadFile(form: any) {
    return {
        type: UPLOAD_FILE,
        data: form
    }
}


export function autoUploadFiles(form: any) {
    return {
        type: AUTO_UPLOAD_FILE,
        data: form
    }
}

//-----------------------------------------------

//DRM Upload File
export function uploadDrmFile(form: any) {
    return {
        type: UPLOAD_DRM_FILE,
        data: form
    }
}

export function loadDrmInputFiles() {
    return {
        type: LOAD_DRM_INPUT_FILES
    }
}

export function setDrmInputFiles(files: Interfaces.DrmInputFile[]) {
    return {
        type: SET_DRM_INPUT_FILES,
        files: files
    }
}

//-----------------------------------------------
//Adjustments

export function loadAdjustments() {
    return {
        type: LOAD_ADJUSTMENTS
    }
}

export function setAdjustments(adjustments: Interfaces.AdjustmentEntry[]) {
    return {
        type: SET_ADJUSTMENTS,
        adjustments: adjustments

    }
}

export function addSaveAdjustment(adjustment: Interfaces.AdjustmentEntry) {
    return {
        type: ADD_SAVE_ADJUSTMENT,
        adjustment: adjustment
    }
}

export function deleteAdjustment(adjustmentId: number) {
    return {
        type: DELETE_ADJUSTMENT,
        adjustmentId: adjustmentId
    }
}

export function editSaveAdjustment(adjustment: Interfaces.AdjustmentEntry) {
    return {
        type: EDIT_SAVE_ADJUSTMENT,
        adjustment: adjustment
    }
}
//-------------------------------------------------------------------------------------------------------------------

// Risk Position Model Actions ---
export function loadRiskPositionModels() {
    return {
        type: LOAD_RISK_POSITION_MODELS
    }
}

export function setRiskPositionModels(riskPositionModels: Interfaces.RiskPositionModelEntry[]) {
    return {
        type: SET_RISK_POSITION_MODELS,
        riskPositionModels: riskPositionModels

    }
}

export function addSaveRiskPositionModel(riskPositionModel: Interfaces.RiskPositionModelEntry) {
    return {
        type: ADD_SAVE_RISK_POSITION_MODEL,
        riskPositionModel: riskPositionModel
    }
}

export function deleteRiskPositionModel(riskPositionModelId: number) {
    return {
        type: DELETE_RISK_POSITION_MODEL,
        riskPositionModelId: riskPositionModelId
    }
}

export function editSaveRiskPositionModel(riskPositionModel: Interfaces.RiskPositionModelEntry) {
    return {
        type: EDIT_SAVE_RISK_POSITION_MODEL,
        riskPositionModel: riskPositionModel
    }
}

//--- Risk Positions ----

export function loadRiskPositions() {
    return {
        type: LOAD_RISK_POSITIONS
    }
}

export function setRiskPositions(riskPositions: Interfaces.RiskPositionEntry[]) {
    return {
        type: SET_RISK_POSITIONS,
        riskPositions: riskPositions

    }
}

export function addSaveRiskPosition(riskPosition: Interfaces.RiskPositionEntry) {
    return {
        type: ADD_SAVE_RISK_POSITION,
        riskPosition: riskPosition
    }
}

export function deleteRiskPosition(riskPositionId: number) {
    return {
        type: DELETE_RISK_POSITION,
        riskPositionId: riskPositionId
    }
}

//--------------------------

export function loadModelableRiskPositions() {
    return {
        type: LOAD_MODELABLE_RISK_POSITIONS
    }
}

export function setModelableRiskPositions(modelableRiskPositions: Interfaces.RiskPositionName[]) {
    return {
        type: SET_MODELABLE_RISK_POSITIONS,
        modelableRiskPositions: modelableRiskPositions
    }
}

// ---------------------------

export function loadGeneralLedgers() {
    return {
        type: LOAD_GENERAL_LEDGERS
    }
}

export function setGeneralLedgers(generalLedgers: Interfaces.GeneralLedger[]) {
    return {
        type: SET_GENERAL_LEDGERS,
        generalLedgers: generalLedgers
    }
}

export function loadRunoffModels() {
    return {
        type: LOAD_RUNOFF_MODELS
    }
}

export function setRunoffModels(runoffModels: Interfaces.RunoffModel[]) {
    return {
        type: SET_RUNOFF_MODELS,
        runoffModels: runoffModels
    }
}

export function loadRunoffMaturityTypes() {
    return {
        type: LOAD_RUNOFF_MATURITY_TYPES
    }
}

export function setRunoffMaturityTypes(runoffMaturityTypes: Interfaces.RunoffMaturityType[]) {
    return {
        type: SET_RUNOFF_MATURITY_TYPES,
        runoffMaturityTypes: runoffMaturityTypes
    }
}

export function loadRunoffIrrTypes() {
    return {
        type: LOAD_RUNOFF_IRR_TYPES
    }
}

export function setRunoffIrrTypes(runoffIrrTypes: Interfaces.RunoffIrrType[]) {
    return {
        type: SET_RUNOFF_IRR_TYPES,
        runoffIrrTypes: runoffIrrTypes
    }
}

export function loadRunoffMfrMfsTypes() {
    return {
        type: LOAD_RUNOFF_MFR_MFS_TYPES
    }
}

export function setRunoffMfrMfsTypes(runoffMfrMfsTypes: Interfaces.RunoffMfrMfsType[]) {
    return {
        type: SET_RUNOFF_MFR_MFS_TYPES,
        runoffMfrMfsTypes: runoffMfrMfsTypes
    }
}



export function userLoggedIn(user: any) {
    return {
        type: USER_LOGGEDIN,
        user
    }
}

export const loginUserAction = (creds: any) => {
    return {
        type: LOGIN_USER,
        creds
    }
}

export const localUserLogin = () => {
    return {
        type: LOGIN_LOCAL_USER
    }
}

export const initDataWithDataAction = (token: any) => {
    return {
        type: INIT_DATA_WITH_TOKEN,
        token
    }
}

export const getRefreshTokenAction = () => {
    return {
        type: GET_REFRESH_TOKEN
    }
}