import { put, fork, select, call, take } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects'
import { LOCATION_CHANGE } from "connected-react-router"
import { matchPath, match } from 'react-router'
import * as Interfaces from "./interfaces"
import * as Actions from "./actions";
import * as GeneralActions from "./general/actions"
import config from './config';
import { notification } from "antd"

import * as General from "./general"
import * as GeneralSelectors from "./general/selectors"
import * as Mif from "./mif"
import * as Erdr from "./erdr"
import * as RunoffView from "./runoffView"
import * as EquityInjections from "./equityInjections"
import * as SpecialRules from "./specialRules"
import UrlMap from "./UrlMap";
import history from './history';

import AuthenticatedRequest from "./http";

const takeEvery: any = Eff.takeEvery;
const takeLatest: any = Eff.takeLatest;

//--------------------------------------------------------------------------------------------------------------------
//Risk Position sagas
function* loadRiskPositionIds({ forceReload }: { forceReload: boolean, type: string }) {
    const currentRiskPositions: Interfaces.RiskPosition[] = yield (select<any>(x => x.availableRiskPositions));
    if (currentRiskPositions.length === 0 || forceReload) {
        const url = config.apiBaseUrl + "/api/RiskPositionMappings";
        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
                {
                    mode: "cors",
                    credentials: "include"
                });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(Actions.getRefreshTokenAction())
            }
            const json = yield response.json();
            yield put(Actions.setAvailableRiskPositions(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading Risk Position Mappings" });
        }
    }
}

function* watchLoadRiskPositionIds() {
    yield takeEvery(Actions.LOAD_RISK_POSITION_IDS, loadRiskPositionIds);
}


function* addSaveDetailedRiskPosition({ detailedRiskPosition }) {

    const url = config.apiBaseUrl + "/api/RiskPositionMappings";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(detailedRiskPosition)
            }
        );

        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (response.status == 400) {
            notification["warning"]({ message: 'Existing Override', description: "Must edit existing override to save" });
        } else {
            notification["success"]({ message: 'Success', description: "Risk position successfuly added" });
            yield put(Actions.loadRiskPositionIds());
        }
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Risk Position Mapping" });
    }

}

function* watchAddSaveDetailedRiskPosition() {
    yield takeEvery(Actions.ADD_SAVE_DETAILED_RISK_POSITION, addSaveDetailedRiskPosition);
}
//-------------------------------------------

//editdetailedRiskPosition - adding new risk position and editing the previous risk position. Using the api url instead of the standard url (NB the addDetailedRiskPosition needs to be merged to change to the api url as well)

//NB this needs to be a PUT action
function* editSaveDetailedRiskPosition({ detailedRiskPosition }) {

    const url = config.apiBaseUrl + "/api/RiskPositionMappings/" + detailedRiskPosition.Id;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(detailedRiskPosition)
            }
        );
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        yield put(Actions.loadRiskPositionIds());
        notification["success"]({ message: 'Success', description: "Risk position successfuly edited" });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Risk Position Mapping" });
    }

}

function* watchEditSaveDetailedRiskPosition() {
    yield takeEvery(Actions.EDIT_SAVE_DETAILED_RISK_POSITION, editSaveDetailedRiskPosition);
}


function* deleteDetailedRiskPosition({ riskPositionId }) {
    const url = config.apiBaseUrl + "/api/RiskPositionMappings/" + riskPositionId.toString();
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "DELETE"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        yield put(Actions.loadRiskPositionIds());
        notification["success"]({ message: 'Success', description: "Risk position successfuly deleted" });
    } catch (e) {
        notification.error({ message: "Error", description: "Error deleting Risk Position Mapping" });
    }

}

function* watchdeleteDetailedRiskPosition() {
    yield takeEvery(Actions.DELETE_DETAILED_RISK_POSITION, deleteDetailedRiskPosition);
}


//--------------------------------------------------------------------------------------------------------------------

function* parseUrl({ payload }) {
    const pathName = payload.location.pathname;
    const uploadFiles: match<{}> = matchPath(pathName, { path: config.uiBaseUrl + "FileUpload" });
    if (uploadFiles) {
        yield put(GeneralActions.loadEntities());
        yield put(Actions.loadInputFiles());
    }

    const uploadDrmFiles: match<{}> = matchPath(pathName, { path: config.uiBaseUrl + "DrmFileUpload" });
    if (uploadDrmFiles) {
        yield put(Actions.loadDrmInputFiles());
    }

    const riskPositionMappings: any = matchPath(pathName, { path: config.uiBaseUrl + "RiskPositionMappings" });
    if (riskPositionMappings) {
        yield put(Actions.loadRiskPositionIds());
        yield put(GeneralActions.loadCountries());
        yield put(GeneralActions.loadEntities());
        yield put(GeneralActions.loadCurrencies());
        yield put(GeneralActions.loadRiskPositionNames());
    }

    const adjustments: match<{}> = matchPath(pathName, { path: config.uiBaseUrl + "Adjustments" });
    if (adjustments) {
        yield put(Actions.loadAdjustments());
        yield put(GeneralActions.loadCountries());
        yield put(GeneralActions.loadEntities());
        yield put(GeneralActions.loadCurrencies());
        yield put(GeneralActions.loadRiskPositionNames());
    }

    const riskPositionModels: match<{}> = matchPath(pathName, { path: config.uiBaseUrl + "RiskPositionModels" });
    if (riskPositionModels) {
        yield put(Actions.loadRiskPositionModels());
        yield put(Actions.loadGeneralLedgers()); //loading in data
        yield put(Actions.loadRunoffModels());
        yield put(Actions.loadRunoffMaturityTypes());
        yield put(Actions.loadRunoffIrrTypes());
        yield put(Actions.loadRunoffMfrMfsTypes());
        yield put(Actions.loadModelableRiskPositions());
    }

    const riskPositions: match<{}> = matchPath(pathName, { path: config.uiBaseUrl + "RiskPositions" });
    if (riskPositions) {
        yield put(Actions.loadRiskPositions());
    }
}

function* watchParseUrl() {
    yield takeLatest(LOCATION_CHANGE, parseUrl);
}


function* parseUrls(url: string, maps: UrlMap<any>[]) {
    for (var map of maps) {
        for (var action of map.handle(url)) {
            yield put(action);
        }
    }
}

function* parseAllUrls(input: { payload: { location: { pathname: string } } }) {
    const pathName = input.payload.location.pathname;
    yield call(parseUrls, pathName, Mif.urls);
    yield call(parseUrls, pathName, Erdr.urls);
    yield call(parseUrls, pathName, RunoffView.urls);
    yield call(parseUrls, pathName, EquityInjections.urls);
    yield call(parseUrls, pathName, SpecialRules.urls);
}

function* watchParseAllUrls() {
    yield takeLatest(LOCATION_CHANGE, parseAllUrls);
}

//------------------------------------------------------------------

//------------------------------------------------------------------
//InputFiles & DrmInputFiles

function* loadInputFiles() {
    const url = config.apiBaseUrl + "/api/InputFiles";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.InputFile[] = yield response.json();
        yield put(Actions.setInputFiles(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading files" });
    }
}

function* watchLoadInputFiles() {
    yield takeEvery(Actions.LOAD_INPUT_FILES, loadInputFiles);
}

function* loadDrmInputFiles() {
    const url = config.apiBaseUrl + "/api/DrmInputFiles";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.DrmInputFile[] = yield response.json();
        yield put(Actions.setDrmInputFiles(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading files" });
    }
}

function* watchLoadDrmInputFiles() {
    yield takeEvery(Actions.LOAD_DRM_INPUT_FILES, loadDrmInputFiles);
}


function* uploadFile({ data }) {

    var formData = new FormData();
    var selectBox = data['selectFileType'];
    //formData.append("selectBox", data['selectFileType']);

    var file: File = data['upload'][0]['originFileObj'];
    formData.append("file", file, file.name);
   // formData.append('body', file);

    try {
        const response: Response = yield AuthenticatedRequest(fetch, config.apiBaseUrl + '/api/FileUpload/MifErdr?selectBox=' + selectBox,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "multipart/form-data" }),
                body: formData
            }
        )

        // NB Cannot look at response status because of cors policy
        // To show notification, we would have to present it in the subsequent re-loading of the uplodaded pages

        yield put(Actions.loadInputFiles());

    } catch (e) {
        notification.error({ message: "Error", description: "Error uploading file" });
        yield put(Actions.loadInputFiles());
    }
}

function* autoUploadFiles({ data }) {
    try {
        let url = config.apiBaseUrl + '/api/AutoUploadFileShare?selectedEntityId=' + data.selectEntity
            + '&selectedYear=' + data.selectRepDate.format('YYYY')
            + '&selectedMonth=' + data.selectRepDate.format('MM');

        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "GET"
            }
        );
        const responseText: string = yield call([response, response.text]);
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (!response.ok) {
            throw new Error(responseText)
        } else {
            notification.success({ message: "Success", description: "Automatic upload: " + responseText });
        }
        yield put(Actions.loadInputFiles());
    } catch (e) {
        notification.error({ message: "Error", description: "Error in automatic upload: " + e });
        yield put(Actions.loadInputFiles());
    }
}



function* watchUploadFile() {
    yield takeEvery(Actions.UPLOAD_FILE, uploadFile);
}

function* watchAutoUploadFile() {
    yield takeEvery(Actions.AUTO_UPLOAD_FILE, autoUploadFiles);
}


function* uploadDrmFile({ data }) {

    var formData = new FormData();

    var file: File = data['upload'][0]['originFileObj'];
    formData.append("file", file, file.name);
   // formData.append('body', file);

    try {
        const response: Response = yield AuthenticatedRequest(fetch, config.apiBaseUrl + '/api/FileUpload/Drm',
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "multipart/form-data" }),
                body: formData
            }
        )

        console.log(response)
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (response.status == 400) {
            throw new Error("Error updloading file")
        }
        else if (response.status == 200) {
            notification.success({ message: "Success", description: "File successfully uploaded" });
        }

        yield put(Actions.loadDrmInputFiles());
    } catch (e) {
        notification.error({ message: "Error", description: "Error uploading file" });
        yield put(Actions.loadDrmInputFiles());
    }

}

function* watchDrmUploadFile() {
    yield takeEvery(Actions.UPLOAD_DRM_FILE, uploadDrmFile);
}

//------------------------------------------------------------------

//------------------------------------------------------------------
//Adjustments

function* loadAdjustments({ forceReload = true }) {

    const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);
    const selectedEntityId: Date = yield select(GeneralSelectors.getEntity);
    const selectedCurrencyId: Date = yield select(GeneralSelectors.getCurrency);

    const currentAdjustments: Interfaces.AdjustmentEntry[] = yield (select<any>(x => x.adjustments));
    if (currentAdjustments.length === 0 || forceReload) {

        let url = config.apiBaseUrl + "/api/Adjustments?selectedCountryId=" + selectedCountryId + "&selectedEntityId=" + selectedEntityId + "&selectedCurrencyId=" + selectedCurrencyId;

        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
                {
                    mode: "cors",
                    credentials: "include"
                });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(Actions.getRefreshTokenAction())
            }
            const json: Interfaces.AdjustmentEntry[] = yield response.json();
            yield put(Actions.setAdjustments(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading adjustments" });
        }

    }
}

function* watchLoadAdjustments() {
    yield takeEvery(Actions.LOAD_ADJUSTMENTS, loadAdjustments);
}


function* addSaveAdjustment({ adjustment }) {

    const url = config.apiBaseUrl + "/api/Adjustments";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(adjustment)
            }
        )
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (!response.ok) {
            throw new Error("Error adding adjustment")
        }
        yield put(Actions.loadAdjustments());
        notification["success"]({ message: 'Success', description: 'Adjustment successfully added', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving adjustment" });
    }
}

function* watchAddSaveAdjustment() {
    yield takeEvery(Actions.ADD_SAVE_ADJUSTMENT, addSaveAdjustment);
}


//NB this needs to be a PUT action
function* editSaveAdjustment({ adjustment }) {

    const url = config.apiBaseUrl + "/api/Adjustments/" + adjustment.Id;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT",
                headers: new Headers({ "content-type": "application/json" }),
                body: JSON.stringify(adjustment)
            })

        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (!response.ok) {
                throw new Error("Error editing adjustment")
            }

        yield put(Actions.loadAdjustments());
        notification["success"]({ message: 'Success', description: 'Adjustment successfully edited', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving adjustment" });
    }
}

function* watchEditSaveAdjustment() {
    yield takeEvery(Actions.EDIT_SAVE_ADJUSTMENT, editSaveAdjustment);
}


function* deleteAdjustment({ adjustmentId }) {
    const url = config.apiBaseUrl + "/api/Adjustments/" + adjustmentId.toString();
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "DELETE"
            })

        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (!response.ok) {
                throw new Error("Error deleting adjustment")
            }

        yield put(Actions.loadAdjustments());
        notification["success"]({ message: 'Success', description: 'Adjustment successfully deleted' });

    } catch (e) {
        notification.error({ message: "Error", description: "Error Deleting Adjustment" });
    }
}

function* watchDeleteAdjustment() {
    yield takeEvery(Actions.DELETE_ADJUSTMENT, deleteAdjustment);
}


//------------------------------------------------------------------
//Special Rules


//------------------------------------------------------------------
//Risk Position Models
function* loadRiskPositionModels({ forceReload = true }) {
    const currentRiskPositionModels: Interfaces.RiskPositionModelEntry[] = yield (select<any>(x => x.riskPositionModels)
    );
    if (currentRiskPositionModels.length === 0 || forceReload) {
        const url = config.apiBaseUrl + "/api/RiskPositionModels";
        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
                {
                    mode: "cors",
                    credentials: "include"
                });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(Actions.getRefreshTokenAction())
            }
            const json: Interfaces.RiskPositionModelEntry[] = yield response.json();
            yield put(Actions.setRiskPositionModels(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading Risk Position Models" });
        }

    }
}

function* watchLoadRiskPositionModels() {
    yield takeEvery(Actions.LOAD_RISK_POSITION_MODELS, loadRiskPositionModels);
}

//NB this needs to be a PUT action
function* editSaveRiskPositionModel({ riskPositionModel }) {

    const url = config.apiBaseUrl + "/api/RiskPositionModels/" + riskPositionModel.Id;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(riskPositionModel)
            }
        )
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (!response.ok) {
            throw new Error("Error saving edited risk position model")
        }

        yield put(Actions.loadRiskPositionModels());
        notification["success"]({ message: 'Success', description: 'Risk position model successfully edited' });

    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Risk Position Model" });
    }
}

function* watchEditSaveRiskPositionModels() {
    yield takeEvery(Actions.EDIT_SAVE_RISK_POSITION_MODEL, editSaveRiskPositionModel);
}


function* addSaveRiskPositionModel({ riskPositionModel }) {

    const url = config.apiBaseUrl + "/api/RiskPositionModels";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(riskPositionModel)
            }
        )

        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } if (!response.ok) {
            throw new Error("Error saving added risk position model")
        }

        yield put(Actions.loadRiskPositionModels());
        notification["success"]({ message: 'Success', description: 'Risk position model successfully added' });

    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Risk Position Model" });
    }
}

function* watchAddSaveRiskPositionModel() {
    yield takeEvery(Actions.ADD_SAVE_RISK_POSITION_MODEL, addSaveRiskPositionModel);
}


function* deleteRiskPositionModel({ riskPositionModelId }) {
    const url = config.apiBaseUrl + "/api/RiskPositionModels/" + riskPositionModelId.toString();
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "DELETE"
            })

        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (!response.ok) {
                throw new Error("Risk Position Model in use")
            }

        yield put(Actions.loadRiskPositionModels());
        notification["success"]({ message: 'Success', description: 'Risk position model successfully deleted' });

    } catch (e) {
        switch (e.message) {
            case "Risk Position Model in use":
                notification.error({ message: "Error", description: "Risk Position Model used elsewhere in application" });
                break;
            default:
                notification.error({ message: "Error", description: "Error deleting Risk Position Model" });
        }

    }
}

function* watchDeleteRiskPositonModels() {
    yield takeEvery(Actions.DELETE_RISK_POSITION_MODEL, deleteRiskPositionModel);
}

//------ Risk Positions ----

function* loadRiskPositions({ forceReload = true }) {
    const currentRiskPositions: Interfaces.RiskPositionEntry[] = yield (select<any>(x => x.riskPositions));
    if (currentRiskPositions.length === 0 || forceReload) {
        const url = config.apiBaseUrl + "/api/RiskPositions";
        try {
            const response: Response = yield AuthenticatedRequest(fetch, url, {
                mode: "cors",
                credentials: "include"
            })
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(Actions.getRefreshTokenAction())
            }
            const json: Interfaces.RiskPositionEntry[] = yield response.json();
            yield put(Actions.setRiskPositions(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading risk positions" });
        }

    }
}

function* watchLoadRiskPositions() {
    yield takeEvery(Actions.LOAD_RISK_POSITIONS, loadRiskPositions);
}

function* addSaveRiskPosition({ riskPosition }) {

    const url = config.apiBaseUrl + "/api/RiskPositions";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(riskPosition)
            }
        );
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        yield put(Actions.loadRiskPositions());
        notification["success"]({ message: 'Success', description: "Risk position successfuly added" });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving risk position" });
    }

}

function* watchAddSaveRiskPosition() {
    yield takeEvery(Actions.ADD_SAVE_RISK_POSITION, addSaveRiskPosition);
}

function* deleteRiskPosition({ riskPositionId }) {
    const url = config.apiBaseUrl + "/api/RiskPositions/" + riskPositionId.toString();
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "DELETE"
            })

        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        } else if (!response.ok) {
                throw new Error("Risk position in use")
            }

        yield put(Actions.loadRiskPositions());
        notification["success"]({ message: 'Success', description: "Risk position successfuly deleted" });
    } catch (e) {
        switch (e.message) {
            case "Risk position in use":
                notification.error({ message: "Error", description: "Risk position used elsewhere in application" });
                break;
            default:
                notification.error({ message: "Error", description: "Error deleting risk position" });
        }
    }
}


function* watchDeleteRiskPositions() {
    yield takeEvery(Actions.DELETE_RISK_POSITION, deleteRiskPosition);
}

// ----- Modelable Risk positions

function* loadModelableRiskPositions() {
    const url = config.apiBaseUrl + "/api/ModelableRiskPositions";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.RiskPositionName[] = yield response.json();
        yield put(Actions.setModelableRiskPositions(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading risk positions" });
    }
}

function* watchLoadModelableRiskPositions() {
    yield takeEvery(Actions.LOAD_MODELABLE_RISK_POSITIONS, loadModelableRiskPositions);
}

// -------

function* loadGeneralLedgers() {
    const url = config.apiBaseUrl + "/api/GeneralLedgers";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.GeneralLedger[] = yield response.json();
        yield put(Actions.setGeneralLedgers(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading general ledgers" });
    }
}

function* watchLoadGeneralLedgers() {
    yield takeEvery(Actions.LOAD_GENERAL_LEDGERS, loadGeneralLedgers);
}

function* loadRunoffModels() {
    const url = config.apiBaseUrl + "/api/RunoffModels";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.RunoffModel[] = yield response.json();
        yield put(Actions.setRunoffModels(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading runoff models" });
    }
}

function* watchLoadRunoffModels() {
    yield takeEvery(Actions.LOAD_RUNOFF_MODELS, loadRunoffModels);
}

//-----------------------------------------------------------------------

function* loadRunoffMaturityTypes() {
    const url = config.apiBaseUrl + "/api/RunoffMaturityTypes";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.RunoffMaturityType[] = yield response.json();
        yield put(Actions.setRunoffMaturityTypes(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading maturity types" });
    }
}

function* watchLoadRunoffMaturityTypes() {
    yield takeEvery(Actions.LOAD_RUNOFF_MATURITY_TYPES, loadRunoffMaturityTypes);
}

//-----------------------------------------------------------------------

function* loadRunoffIrrTypes() {
    const url = config.apiBaseUrl + "/api/RunoffIrrTypes";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.RunoffIrrType[] = yield response.json();
        yield put(Actions.setRunoffIrrTypes(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading irr types" });
    }
}

function* watchLoadRunoffIrrTypes() {
    yield takeEvery(Actions.LOAD_RUNOFF_IRR_TYPES, loadRunoffIrrTypes);
}

//-----------------------------------------------------------------------

function* loadRunoffMfrMfsTypes() {
    const url = config.apiBaseUrl + "/api/RunoffMfrMfsTypes";

    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(Actions.getRefreshTokenAction())
        }
        const json: Interfaces.RunoffMfrMfsType[] = yield response.json();
        yield put(Actions.setRunoffMfrMfsTypes(json));
    } catch (e) {
        notification.error({ message: "Error", description: "Error loading mfr mfs types" });
    }
}

function* watchLoadRunoffMfrMfsTypes() {
    yield takeEvery(Actions.LOAD_RUNOFF_MFR_MFS_TYPES, loadRunoffMfrMfsTypes);
}

function* loginFromOIDC(code) {
    try {
        let url = config.apiBaseUrl + "/api/User/OIDCToken?authCode=" + code.creds;

        const response: Response = yield call(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST"
            }
        );
        if (!response.ok) {
            throw new Error("Error fetching token")
        } else {
            const responseBody = yield call([response, response.json]);
            window.sessionStorage.setItem("token", JSON.stringify(responseBody));
            yield put(Actions.userLoggedIn(responseBody))
            history.push('/web')
        }
    } catch (e) {
        console.log(e);
    }
}


function* loginFromLocal() {
    try {
        let url = config.apiBaseUrl + "/api/User/token";

        const response: Response = yield call(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "GET"
            }
        );
        if (!response.ok) {
            throw new Error("Error fetching token")
        } else {
            const responseBody = yield call([response, response.json]);
            window.sessionStorage.setItem("token", responseBody["token"]);
            yield put(Actions.userLoggedIn(responseBody))
        }
    } catch (e) {
        console.log(e);
    }

}

function* watchLoginUser() {
    yield takeEvery(Actions.LOGIN_USER, loginFromOIDC)
}

function* watchLoginLocalUser() {
    yield takeEvery(Actions.LOGIN_LOCAL_USER, loginFromLocal)
}

function* initDataWithTokenSaga(state) {
    //history.push(history.location.pathname)
    yield put(Actions.userLoggedIn(state.token))
}

function* watchInitDataWithToken() {
    yield takeEvery(Actions.INIT_DATA_WITH_TOKEN, initDataWithTokenSaga)
}

function* getRefreshToken() {
    try {
        let url = config.apiBaseUrl + "/api/User/Refreshtoken";
        const headerData = yield (select<any>(x => x.user));
        const response = yield call(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${headerData.hashcode}`
                }
            }
        );
        if (!response.ok) {
            throw new Error("Error fetching refresh token")
        } else {
            const responseBody = yield call([response, response.json]);
            window.sessionStorage.setItem("token", JSON.stringify(responseBody));
            yield put(Actions.userLoggedIn(responseBody))
            notification["success"]({ message: 'Success', description: 'Access Token fetched Successfully' });
            //history.push('/web')
        }
    } catch (e) {
        console.log(e);
    }
}

function* watchRefreshToken() {
    yield takeEvery(Actions.GET_REFRESH_TOKEN, getRefreshToken)
}
//-----------------------------------------------------------------------


export default function* rootSaga() {
    yield fork(General.saga);
    yield fork(Mif.saga);
    yield fork(Erdr.saga);
    yield fork(EquityInjections.saga);
    yield fork(RunoffView.saga);
    yield fork(SpecialRules.saga);
    yield fork(watchParseUrl);
    yield fork(watchParseAllUrls);
    yield fork(watchUploadFile);
    yield fork(watchAutoUploadFile);
    yield fork(watchLoadRiskPositionIds);
    yield fork(watchAddSaveDetailedRiskPosition);
    yield fork(watchdeleteDetailedRiskPosition);
    yield fork(watchLoadInputFiles);
    yield fork(watchLoadDrmInputFiles);
    yield fork(watchDrmUploadFile);
    yield fork(watchEditSaveDetailedRiskPosition);
    yield fork(watchLoadAdjustments);
    yield fork(watchAddSaveAdjustment);
    yield fork(watchEditSaveAdjustment);
    yield fork(watchDeleteAdjustment);
    yield fork(watchLoadRiskPositionModels);
    yield fork(watchEditSaveRiskPositionModels);
    yield fork(watchAddSaveRiskPositionModel);
    yield fork(watchDeleteRiskPositonModels);
    yield fork(watchLoadRiskPositions);
    yield fork(watchAddSaveRiskPosition);
    yield fork(watchDeleteRiskPositions);
    yield fork(watchLoadModelableRiskPositions);
    yield fork(watchLoadGeneralLedgers);
    yield fork(watchLoadRunoffModels);
    yield fork(watchLoadRunoffMaturityTypes);
    yield fork(watchLoadRunoffIrrTypes);
    yield fork(watchLoadRunoffMfrMfsTypes);
    yield fork(watchLoginUser);
    yield fork(watchLoginLocalUser);
    yield fork(watchInitDataWithToken);
    yield fork(watchRefreshToken);
}
