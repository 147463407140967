// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";
import { Table, Badge, Menu, Icon, Button, Input, Popconfirm, message, notification} from 'antd';
const { Column, ColumnGroup } = Table;
import * as Immutable from "immutable";
import moment from "moment";
import DropDownCell from "./DropDownCell"
import EditableCell from "./EditableCell"


export interface RiskPositionMappingTableProps {
    availableRiskPositions: Interfaces.RiskPosition[],
    mifCountries: Interfaces.MifCountry[],
    mifEntities: Interfaces.MifEntity[],
    mifCurrencies: Interfaces.MifCurrency[],
    riskPositionNames: Interfaces.RiskPositionName[],
    addSaveDetailedRiskPosition: (newDetailedRP: Interfaces.RiskPositionOverride) => void;
    deleteDetailedRiskPosition: (x: number) => void;
    editSaveDetailedRiskPosition: (x: Interfaces.RiskPositionOverride) => void;
    loadRiskPositionIds: () => void;
}

type InnerMap = Immutable.Map<number, Interfaces.RiskPositionOverride>;

interface State {
    //in the inner map id 0 is the newly added row.
    data: Immutable.Map<number, InnerMap>,
    numberOfRiskPositions: number,
}

//Outer table class extensions
class MyTable extends Table<Interfaces.RiskPosition> { }
class MyColumnTyped extends Column<Interfaces.RiskPosition> { }
const MyColumn: any = MyColumnTyped;

//Inner table class extensions
class MyTableInner extends Table<Interfaces.RiskPositionOverride> { }
class MyColumnInner extends Column<Interfaces.RiskPositionOverride> { }


function totalOverrides (prop: Interfaces.RiskPosition[]) {
    var total = 0;
    for (var i = 0, _len = prop.length; i < _len; i++) {
        total += prop[i].Overrides.length;
    }
    return total;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}


export class RiskPositionMappingTable extends React.Component<RiskPositionMappingTableProps, any> {
    //Add all the data to the React state
    constructor(props) {
        super(props);       
        this.state = {
            data: Immutable.Map<number, InnerMap>(),
            availableRiskPositions: this.props.availableRiskPositions,
            numberOfRiskPositions: this.props.availableRiskPositions.length,

            mifAccountNumberfilterDropdownVisible: false,
            mifAccountNumbersearchText: '',
            mifAccountNumberfiltered: false,

            mifAccountNamefilterDropdownVisible: false,
            mifAccountNamesearchText: '',
            mifAccountNamefiltered: false,
        }

        this.handleEditableCellValueChange = this.handleEditableCellValueChange.bind(this);
    }

    getOverride(parentId: number, childId: number) {
        return this.state.data.get(parentId, Immutable.Map<number, Interfaces.RiskPositionOverride>()).get(childId);
    }

    setOverride(parentId: number, childId: number, override: Interfaces.RiskPositionOverride) {
        const oldMiddle = this.state.data.get(parentId, Immutable.Map<number, Interfaces.RiskPositionOverride>());
        const newMiddle = oldMiddle.set(childId, override);
        const newOuter = this.state.data.set(parentId, newMiddle);
        this.setState({ data: newOuter });
    }

    removeOverride(parentId: number, childId: number) {
        const oldMiddle = this.state.data.get(parentId, Immutable.Map<number, Interfaces.RiskPositionOverride>());
        const newMiddle = oldMiddle.delete(childId);
        const newOuter = this.state.data.set(parentId, newMiddle);
        this.setState({ data: newOuter });
    }

    UNSAFE_componentWillReceiveProps(nextProps: RiskPositionMappingTableProps) {

        var overridesCount = totalOverrides(nextProps.availableRiskPositions);
        if (overridesCount > this.state.numberOfRiskPositions) {
            this.setState({ numberOfRiskPositions: overridesCount, availableRiskPositions: nextProps.availableRiskPositions });
        } else if (overridesCount < this.state.numberOfRiskPositions && overridesCount !== 0 ) {
            this.setState({ numberOfRiskPositions: overridesCount, availableRiskPositions: nextProps.availableRiskPositions });
        } else if (this.props.availableRiskPositions != nextProps.availableRiskPositions) {
            this.setState({availableRiskPositions: nextProps.availableRiskPositions });
        }
        

    }

    onInputChange = (e, columnName) => {
        switch (columnName) {
            case "MifAccountNumber":
                this.setState({ mifAccountNumbersearchText: e.target.value.toString() });
                break;
            case "MifAccountName":
                this.setState({ mifAccountNamesearchText: e.target.value });
                break;
            default:
                break;
        }
    }

    onSearch = () => {
        
        const { mifAccountNumbersearchText, mifAccountNamesearchText } = this.state;

        console.log("mifAccountNumbersearchText", mifAccountNumbersearchText);
        console.log("mifAccountNamesearchText", mifAccountNamesearchText);

        this.setState(() => {
            return {
                mifAccountNamefilterDropdownVisible: false,
                mifAccountNamefiltered: !!mifAccountNamesearchText,
                mifAccountNumberfilterDropdownVisible: false,
                mifAccountNumberfiltered: !!mifAccountNumbersearchText,
                availableRiskPositions: this.props.availableRiskPositions.filter((record) => record.MifAccountAccountNo.toString().indexOf(mifAccountNumbersearchText.toLowerCase()) >= 0).filter((record) => record.MifAccountName.toLowerCase().indexOf(mifAccountNamesearchText.toLowerCase()) >= 0)
            };
        })
    }


    //This function is used to change the parent state whenever an Editable cell's value is changed
    handleEditableCellValueChange = (recordId: number, record2Id: number, fieldName: string) =>
        (value: any) => {
            const oldInner = this.getOverride(recordId, record2Id);
            var update = {};
            update[fieldName] = parseInt(value);
            const newInner = Object.assign({}, oldInner, update);
            this.setOverride(recordId, record2Id, newInner);
        }

    handleEditableCellStringValueChange = (recordId: number, record2Id: number, fieldName: string) =>
        (value: any) => {
            const oldInner = this.getOverride(recordId, record2Id);
            var update = {};
            update[fieldName] = value;
            const newInner = Object.assign({}, oldInner, update);
            this.setOverride(recordId, record2Id, newInner);
        }

    handleAdd = (record: Interfaces.RiskPosition) => {
        const newData: Interfaces.RiskPositionOverride = {
            Id: 0,
            MifAccountId: record.MifAccountId,
            CountryId: null,
            CountryName: null,
            EntityId: null,
            EntityName: null,
            CurrencyName: null,
            CurrencyId: null,
            RiskPositionId: 1,
            RiskPositionName: null,
            Comment: null,
        };
        this.setOverride(record.Id, 0, newData);
    };

    handleSave = (parentId: number, record2Id: number) => {
        var newData = this.state.data.get(parentId).get(record2Id);
        //var foo = this.state.data.get(parentId, Immutable.Map<number, Interfaces.RiskPositionOverride>());

        // -------------------------------------------------------------------------------
        //Add conditional logic here for warnings and validation
        
        if  (!newData.CountryId && !newData.EntityId && !newData.CurrencyId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select Currency and exactly one of Country and Entity',
                duration: 3
            });
        } else if (!newData.CountryId && !newData.EntityId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select exactly one of Country and Entity',
                duration: 3
            });
        } else if (!!newData.CountryId && !!newData.EntityId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select exactly one of Country and Entity',
                duration: 3
            });
        } else if (!newData.CurrencyId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select Currency',
                duration: 3
            });
        } else if (!newData.RiskPositionId) {
            notification["error"]({
                message: 'Error',
                description: "Must select Risk Position",
                duration: 3
            });
        }
        // -------------------------------------------------------------------------------

        else if (record2Id === 0) {
            this.props.addSaveDetailedRiskPosition(newData);
            this.removeOverride(parentId, record2Id);
        } else {
            this.props.editSaveDetailedRiskPosition(newData);
            this.removeOverride(parentId, record2Id);
        }

    }

    handleCancel = (recordId: number, record2Id: number) => {
        const imm = this.state.data;
        var newMiddle = imm.get(recordId).delete(record2Id);
        var newStatedata = imm.set(recordId, newMiddle);
        this.setState({ data: newStatedata });
    }

    handleDelete = (id) => {
        this.props.deleteDetailedRiskPosition(id);
    }

    handleEdit = (recordId: number, record2: Interfaces.RiskPositionOverride) => {
        this.setOverride(recordId, record2.Id, record2);
    }

    render() {

        const expandedRowRender = record => {
            var overrides = record.Overrides.slice();
            const addOverride = this.getOverride(record.Id, 0);
            if (!!addOverride)
                overrides.push(addOverride);
        
            return (
                <div>
                    <MyTableInner
                        className="risk-position-mapping-table-expanded"
                        pagination={false}
                        dataSource={overrides}
                        size="small"
                        key="Id">
                        <MyColumnInner
                            title="Country"
                            render={(text, record1) => <DropDownCell options={this.props.mifCountries} value={record1.CountryId} editableStatus={!!this.getOverride(record.Id, record1.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, record1.Id, "CountryId")}/>}
                            key="MifCountryCountry"
                            sorter={(a, b) => nonNumberSorter(a.CountryName || " ", b.CountryName || " ")}
                            width="10%"
                        />
                        <MyColumnInner
                            title="Entity"
                            render={(text, record1) => <DropDownCell options={this.props.mifEntities} value={record1.EntityId} editableStatus={!!this.getOverride(record.Id, record1.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, record1.Id, "EntityId")}/>}
                            key="MifEntityEntity"
                            sorter={(a, b) => nonNumberSorter(a.EntityName || " ", b.EntityName || " ")}
                            width="22%"
                        />
                        <MyColumnInner
                            title="Currency"
                            render={(text, record1) => <DropDownCell options={this.props.mifCurrencies} value={record1.CurrencyId} editableStatus={!!this.getOverride(record.Id, record1.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, record1.Id, "CurrencyId")}/>}
                            key="MifCurrencyMifCurrency"
                            sorter={(a, b) => nonNumberSorter(a.CurrencyName, b.CurrencyName)}
                            width="11%"
                        />
                        <MyColumnInner
                            title="Risk Position"
                            render={(text, record1) => <DropDownCell options={this.props.riskPositionNames} value={record1.RiskPositionId} editableStatus={!!this.getOverride(record.Id, record1.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, record1.Id, "RiskPositionId")}/>}
                            key="RiskPositionRiskPosition"
                            sorter={(a, b) => nonNumberSorter(a.RiskPositionName, b.RiskPositionName)}
                            width="26%"
                        />
                        <MyColumnInner
                            title="Comment"
                            render={(text, record1) => <EditableCell value={record1.Comment} editableStatus={!!this.getOverride(record.Id, record1.Id)} editStateValue={this.handleEditableCellStringValueChange(record.Id, record1.Id, "Comment")} />}
                            key="Comment"
                            width="22%"
                        />
                        <MyColumnInner
                            title="Actions"
                            key="action"
                            render={(text1, record1) => (
                                this.getOverride(record.Id, record1.Id)
                                    ? <span>
                                          <a href="#" onClick={(e) => {
                                              e.preventDefault();
                                              this.handleSave(record.Id, record1.Id);
                                          }}>Save</a>
                                          <span className="ant-divider"/>
                                          <a href="#" onClick={(e) => {
                                              e.preventDefault();
                                              this.handleCancel(record.Id, record1.Id);
                                          }}>Cancel</a>
                                      </span>
                                    : <span>
                                          <a href="#" onClick={(e) => {
                                              e.preventDefault();
                                              this.handleEdit(record.Id, record1);
                                          }}>Edit</a>
                                          <span className="ant-divider" />
                                          <Popconfirm title="Sure to Delete?" onConfirm={(e) => {
                                              this.handleDelete(record1.Id);
                                           
                                          }}>
                                              <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                          </Popconfirm>  
                        
                                    </span>)

                            }
                            width="10%"
                        />
                    </MyTableInner>
                    {(!this.state.data.has(record.Id) || !this.state.data.get(record.Id).has(0)) ? <div style={{paddingTop: "5px" }}><Button  type="primary" className="adjustments-buttons" onClick={() => this.handleAdd(record)}>Add</Button></div> : ""}
                </div>
            );
        };

        return (
            <div>
                <MyTable className="risk-position-mapping-table" dataSource={this.state.availableRiskPositions} size="small"
                    expandedRowRender={expandedRowRender} rowKey="Id" pagination = {true}>
                    <MyColumn
                        className="numeric-column"
                        title="Account Number"
                        dataIndex="MifAccountAccountNo"
                        key="MifAccountAccountNo"                       
                        filterDropdown={(
                            <div style={{width: "175"}}>
                                <Input.Search
                                    placeholder="Search Account Number"
                                    ref={elem => { if (elem) elem.input.focus() }}
                                    value={this.state.mifAccountNumbersearchText}
                                    // enterButton
                                    onChange={(e) => this.onInputChange(e, "MifAccountNumber")}
                                    onSearch={this.onSearch}
                                />
                            </div>
                        )}
                        filterIcon={<Icon type="search" style={{ color: this.state.mifAccountNumberfiltered ? '#108ee9' : '#aaa' }} />}
                        filterDropdownVisible={this.state.mifAccountNumberfilterDropdownVisible}
                        onFilterDropdownVisibleChange={(visible) => {
                            this.setState({
                                mifAccountNumberfilterDropdownVisible: visible,
                            });
                        }}
                        sorter={(a, b) => a.MifAccountAccountNo - b.MifAccountAccountNo}
                        width="15%"
                    />
                    <MyColumn
                        title="Accounting Type"
                        render={(text, record) => (record.MifAccountAssetLiability == 0) ? "Assets" : "Equity&Liability"}
                        key="MifAccountAssetLiability"                        
                        filters={[{text: "Assets", value: "0"},{text: "Equity&Liability", value:"1"}]}
                        onFilter={(value, record) => record.MifAccountAssetLiability === parseInt(value)}
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.MifAccountAssetLiability - a.MifAccountAssetLiability : null }}
                        width="10%"
                    />
                    <MyColumn
                        title="Name (MIF)"
                        dataIndex="MifAccountName"
                        key="MifAccountName"                        
                        filterDropdown={(
                            <div style={{ width: "275" }}>
                                <Input.Search
                                    placeholder="Search Account Name"
                                    ref={elem => { if (elem) elem.input.focus() }}
                                    value={this.state.mifAccountNamesearchText}
                                    // enterButton
                                    onChange={(e) => this.onInputChange(e, "MifAccountName")}
                                    onSearch={this.onSearch}
                                />
                            </div>
                        )}
                        filterIcon={<Icon type="search" style={{ color: this.state.mifAccountNamefiltered ? '#108ee9' : '#aaa' }} />}
                        filterDropdownVisible={this.state.mifAccountNamefilterDropdownVisible}
                        onFilterDropdownVisibleChange={(visible) => {
                            this.setState({
                                mifAccountNamefilterDropdownVisible: visible,
                            });
                        }} 
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.MifAccountName.localeCompare(a.MifAccountName) : null }}
                        width="30%"
                    />
                    <MyColumn
                        title="Risk Position"
                        dataIndex="RiskPositionName"
                        key="RiskPositionName"                        
                        filters={this.props.availableRiskPositions !== null ? [...new Set(this.props.availableRiskPositions.map(x => x.RiskPositionName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        onFilter={(value, record) => record.RiskPositionName.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.RiskPositionName, b.RiskPositionName)}
                        width="25%"
                        
                    />
                    <MyColumn
                        title="No. of Overrides"
                        render={(text, record) =>
                            record.Overrides.length > 0 ? record.Overrides.length.toString() : "0"}
                        key="NumberOfOverrides"
                        sorter={(a, b) => a.Overrides.length - b.Overrides.length}
                        width="10%"
                    />
                </MyTable>
            </div>
        );
    }
};