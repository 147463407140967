import * as React from "react";
import * as ReactDOM from "react-dom";
import RiskPositionMappingTableContainer from "../containers/RiskPositionMappingTableContainer";

const RiskPositionMappingData = (props) =>(
    <div>
        <div style={{ paddingLeft: "25px", paddingTop: "25px"}} />
        <RiskPositionMappingTableContainer />

    </div>
);

export default RiskPositionMappingData;


    