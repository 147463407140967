import * as React from "react";
import { connect } from "react-redux";
import { Form, Select, Button, Upload, Icon, Spin, Popconfirm, message, notification} from 'antd';
import * as Actions from "../actions";

const FormItem = Form.Item;
const Option = Select.Option;
const spinIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

/* export interface RunoffViewProps {
    importStatus: boolean

    setImportingStatus: (importingStatus: boolean) => void;
} */ 

class UploadDRMFileForm extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    /* componentDidUpdate(prevProps) {
        if (this.props.importStatus !== prevProps.importStatus && this.props.importStatus == false) {
            message.destroy()
        }
    } */ 

    handleSubmit = (e) => {
        message.config({ top: 0 });
        // time(seconds) before auto-dismiss, don't dismiss if set to 0
        // https://ant.design/components/message/ 
        message.loading('Importing in progress...', 3);
        // this.props.setImportingStatus(true);
        e.preventDefault();
        this.props.uploadDrmFile(this.props.form.getFieldsValue());
        notification["info"]({
            message: 'Files uploaded',
            description: '',
            duration: 10
        });
    }

    disabledImportButtonChecker = () => {
        if (this.props.form.getFieldsValue() === {})
            return true;
        else if (this.props.form.getFieldsValue().upload == undefined)
            return true;
        else if (this.props.form.getFieldsValue().upload.length == 0)
            return true;
        else
            return false
    }


    render() {

        const { getFieldDecorator } = this.props.form;

        const formItemLayoutFile = {
            labelCol: { span: 1, offset: 0 },
            wrapperCol: { span: 5, offset: 0 },
        };

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem
                    {...formItemLayoutFile}
                    label="File"
                >
                    {getFieldDecorator('upload', {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => e.fileList.slice(-1)
                    })(
                        <Upload name="importFile" action={' '} multiple={false}>
                            <Button>
                                <Icon type="upload" /> Click to upload
                            </Button>
                        </Upload>
                        )}
                </FormItem>
                <FormItem
                    wrapperCol={{ span: 1, offset: 1 }}
                >
                    <Button type="primary" htmlType="submit" disabled={this.disabledImportButtonChecker()}>Import</Button>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(UploadDRMFileForm);

