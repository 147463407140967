import * as React from "react";
import { Tabs } from "antd";
import ErdrSummaryPage from "../components/ErdrSummaryPage";
const TabPane = Tabs.TabPane;
import * as Interfaces from "../../interfaces";
import ErdrLdDataPage from "../components/ErdrLDDataPage";
import ErdrSecDepPage from "../components/ErdrSecDepPage";
import ErdrRunOffPage from "../components/ErdrRunOffPage";

export interface ErdrTabsProps {
    page: Interfaces.ErdrPageFull;
    isLoading : boolean;
}


export class ErdrTabs extends React.Component<ErdrTabsProps, undefined> {
    render() {
        return (
            <div style={{ paddingLeft: "25px" }}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Liquity Data (LD)" key="1"><ErdrLdDataPage page={this.props.page && this.props.page.LDDataEntries} isLoading={this.props.isLoading}/></TabPane>
                    <TabPane tab="Security Deposits" key="2"><ErdrSecDepPage page={this.props.page && this.props.page.SecDepEntries} isLoading={this.props.isLoading}/></TabPane>
                    <TabPane tab="Portfolio Data (PD)" key="3"><ErdrRunOffPage page={this.props.page && this.props.page.RunOffEntries} isLoading={this.props.isLoading}/></TabPane>
                    <TabPane tab="Summary" key="4"><ErdrSummaryPage page={this.props.page} isLoading={this.props.isLoading}/></TabPane>                  
                </Tabs>
            </div>
        );
    }
}

//<TabPane tab="Summary" key="2">Summary Here</TabPane>


