import {match} from "react-router-dom"
import * as Interfaces from "../interfaces"
import { State } from "./reducers"
import { runoffView } from "../selectors"
import * as Top from "../reducers"
// modify, in order to create two more actions for the liabilities and assets RunoffViews creation

const getInnerAsset = (state: State) => state.totalRunoff ? state.totalRunoff.RunoffViewsAsset : [];
export const getAsset = (state: Top.State) => getInnerAsset(runoffView(state));

const getInnerLiability = (state: State) => state.totalRunoff ? state.totalRunoff.RunoffViewsLiability : [];
export const getLiability = (state: Top.State) => getInnerLiability(runoffView(state));

const getInnerNotMapped = (state: State) => state.totalRunoff ? state.totalRunoff.RunoffViewsNotMapped: [];
export const getNotMapped = (state: Top.State) => getInnerNotMapped(runoffView(state));

const getSummaryInner = (state: State) => state.totalRunoff ? state.totalRunoff.SummaryViews : [];
export const getSummary = (state: Top.State) => getSummaryInner(runoffView(state));

const getTotalAssetsInner = (state: State) => state.totalRunoff && state.totalRunoff.TotalAssets;
export const getTotalAssets = (state: Top.State) => getTotalAssetsInner(runoffView(state));

const getTotalLiabilitiesInner = (state: State) => state.totalRunoff && state.totalRunoff.TotalLiabilities;
export const getTotalLiabilities = (state: Top.State) => getTotalLiabilitiesInner(runoffView(state));

const getFinalisedInner = (state: State) => state.totalRunoff ? state.totalRunoff.Finalised : false;
export const getFinalised = (state: Top.State) => getFinalisedInner(runoffView(state));

const getFinalisedDateInner = (state: State) => state.totalRunoff ? state.totalRunoff.FinalisedDate : null;
export const getFinalisedDate = (state: Top.State) => getFinalisedDateInner(runoffView(state));

const getDifferencePositionsInner = (state: State) => state.differencePositions;
export const getDifferencePositions = (state: Top.State) => getDifferencePositionsInner(runoffView(state));

const getRunoffLoadingStatusInner = (state: State) => state.runoffLoadingStatus;
export const getRunoffLoadingStatus = (state: Top.State) => getRunoffLoadingStatusInner(runoffView(state));

const getRunoffExportingStatusInner = (state: State) => state.runoffExportingStatus;
export const getRunoffExportingStatus = (state: Top.State) => getRunoffExportingStatusInner(runoffView(state));

