import * as React from "react";
import * as Immutable from "immutable";
import moment from "moment";
import { Card, Table, Icon, Button, Input, Popconfirm, Popover, Select, DatePicker, Alert, Spin, Modal, message } from "antd";
const Option = Select.Option; 
const { Column } = Table;

const { MonthPicker } = DatePicker;
const spinIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

import * as Interfaces from "../../interfaces";
import RunoffViewTableAssetContainer from "../containers/RunoffViewTableAssetContainer"
import RunoffViewTableLiabilityContainer from "../containers/RunoffViewTableLiabilitiesContainer"
import SummaryViewTableContainer from "../containers/SummaryViewTableContainer"

const SpinTypeless: any = Spin

export interface RunoffViewProps {
    mifCountries: Interfaces.MifCountry[],
    availableEntities: Interfaces.MifEntity[],
    mifCurrencies: Interfaces.MifCurrency[],
    allTotals: Interfaces.CheckAllCurrencies,
    selectedDate: any,
    selectedMifCountry: number,
    selectedMifEntity: number,
    selectedCurrency: number,
    ccyCode: string,
    runoffDataCount: number,
    finalisedDate: Date,
    runoffLoadingStatus: boolean,
    exportStatus: boolean,
    notMapped: number,

    updateRunoffView: () => void;

    updateAllTotals: () => void;

    setLoadingStatus: (loadingStatus: boolean) => void;
    setExportingStatus: (exportingStatus: boolean) => void;
    selectDate: (date: any) => void;
    selectCountry: (countryId: number) => void;
    selectEntity: (entityId: number) => void;
    selectCurrency: (currencyId: number) => void;
    exportDispatch: () => void;
}

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { mimimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

class CurrTable extends Table<Interfaces.CheckAllCurrenciesEntry> { }
class CurrColumn extends Column<Interfaces.CheckAllCurrenciesEntry> { }

export class RunoffView extends React.Component<RunoffViewProps, any> {

    constructor(props) {
        super(props);
        this.handleExport = this.handleExport.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.state = {
            isLoading: false,
            popoverCurrency: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.exportStatus !== prevProps.exportStatus && this.props.exportStatus == false) {
            message.destroy()
        }
        if (this.props.runoffLoadingStatus !== prevProps.runoffLoadingStatus) {
            this.setState({ isLoading: this.props.runoffLoadingStatus})
        }
    }


    //Handling of changes in selector
    handleSelectorChange(type: string, e: any) {
        console.log(`Selected value of tpye '${type}' is '${e}'`);
        switch (type) {
            case "Date":
                var newDate = e
                if (newDate !== null) {
                    newDate = e.toDate();
                    newDate.setDate(15);
                }
                this.props.selectDate(newDate);
                break;
            case "Country":
                this.props.selectCountry(parseInt(e));
                break;
            case "Entity":
                this.props.selectEntity(parseInt(e));
                break;
            case "Currency":
                this.props.selectCurrency(parseInt(e));
                break;
        }
        this.props.setLoadingStatus(true);        
        this.props.updateRunoffView();
        
    }

    handleButtonClick() {
        this.props.selectDate(null);
        this.props.selectCountry(0);
        this.props.selectEntity(0);
        this.props.selectCurrency(0);

        this.props.updateRunoffView();
    }

    handleExport() {
        message.config({top: 0});
        message.loading('Exporting in progress...', 0);
        this.props.setExportingStatus(true); 
        this.props.exportDispatch();
    }

    hidePopover = () => {
        this.setState({
            popoverCurrency: false,
        });
    }

    handlePopoverVisibleChange = (popoverCurrency) => {
        // Cornelius & Florian: Here comes the request to Totals-API
        this.props.updateAllTotals();
        this.setState({ popoverCurrency });
    }

    render() {  
        const myPopoverFooterStyle = {
            float: "right",
            marginTop: "-10px"
            // textAlign: "center"
        } as React.CSSProperties;

        const myColTitleStyle = {
            textOverflow: 'ellipsis',
        //    // overflow: "hidden",
            whiteSpace: 'nowrap'
        } as React.CSSProperties;

        const myPopoverFooterA = {
            fontWeight: "bold",
            float: "left",
            width: "200px",
            textAlign: "left"
        } as React.CSSProperties;

        const myPopoverFooterL = {
            fontWeight: "bold",
            float: "right",
            width: "200px",
            marginRight: "10px",
            textAlign: "right"
        } as React.CSSProperties;


        const alert =
            this.props.finalisedDate != null ? (
                <div>
                    <Alert message={"Finalised on " + moment(this.props.finalisedDate).format('LLLL')} type="info" banner />
                    <div style={{ paddingTop: "5px" }} />
                </div>
                
        ) : (
                <div style={{ paddingTop: "37px" }} />
            );
         
        return (

            //Insert message at top
            <div>
                {alert}
                
                <span style={{ padding: "25px" }}>
                    <MonthPicker 
                        format="MMMM YYYY"
                        style={{ width: 150 }}
                        placeholder="Date"
                        onChange={(e) => { this.handleSelectorChange("Date", e) }}
                        value={this.props.selectedDate !== null ? moment(this.props.selectedDate) : null}
                    />
                    <div className="dateDivider" />
                    <Select
                        showSearch
                        style={{ width: 150 }}
                        placeholder= "Country"
                        optionFilterProp="children"
                        filterOption={(input, option) => { //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                            var optionValue;
                            for (var optionProp in option) {
                                if (optionProp = 'props') {
                                    optionValue = option[optionProp].children.toLowerCase();
                                }
                            }
                            return optionValue.indexOf(input.toLowerCase()) >= 0;
                        }} 
                        onChange={(e) => { this.handleSelectorChange("Country", e) }} 
                        value={this.props.selectedMifCountry.toString()}
                    >
                        <Option value="0">Country</Option>
                        {this.props.mifCountries.map((mifCountry) =>
                            (<Option key={mifCountry.Id.toString()}
                                value={mifCountry.Id.toString()}>{mifCountry.Name}</Option>))}
                    </Select>
                    <div className="divider" />
                    <Select
                        showSearch
                        style={{ width: 150 }}
                        placeholder= "Entity"
                        optionFilterProp="children"  
                        filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                            var optionValue;
                            for (var optionProp in option) {
                                if (optionProp = 'props') {
                                    optionValue = option[optionProp].children.toLowerCase();
                                }
                            }
                            return optionValue.indexOf(input.toLowerCase()) >= 0;
                        }}   
                        onChange={(e) => { this.handleSelectorChange("Entity", e) }} 
                        value={this.props.selectedMifEntity.toString()}
                    >
                        <Option value="0">Entity</Option>
                        {this.props.availableEntities.map((mifEntity) => (<Option key={mifEntity.Id.toString()} value={mifEntity.Id.toString()}>{mifEntity.Name}</Option>))}
                    </Select>
                    <div className="divider" />
                    <Select
                        showSearch
                        style={{ width: 150 }}
                        placeholder= "Currency"
                        optionFilterProp="children"
                        filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                            var optionValue;
                            for (var optionProp in option) {
                                if (optionProp = 'props') {
                                    optionValue = option[optionProp].children.toLowerCase();
                                }
                            }
                            return optionValue.indexOf(input.toLowerCase()) >= 0;
                        }}
                        onChange={(e) => { this.handleSelectorChange("Currency", e) }}                   
                        value={this.props.selectedCurrency.toString()}                
                    >
                        <Option value="0">Currency</Option>
                        {this.props.mifCurrencies.map((mifCurrency) =>
                            (<Option key={mifCurrency.Id.toString()} value={mifCurrency.Id.toString()}>{mifCurrency.Name}</Option>))}               
                    </Select>
                    <div className="divider" />


                    <Button
                        type="dashed"
                        onClick={this.handleButtonClick}
                        ghost={this.props.selectedDate == null && this.props.selectedMifCountry == 0 && this.props.selectedMifEntity == 0 && this.props.selectedCurrency == 0}
                        //disabled={this.props.selectedDate == null && this.props.selectedMifCountry == 0 && this.props.selectedMifEntity == 0 && this.props.selectedCurrency == 0}
                    > Reset </Button>
                    <div className="divider" />

                    <Button 
                        type="primary"
                        onClick={this.handleExport}
                        ghost={this.props.runoffDataCount == 0}
                        disabled={this.props.runoffDataCount == 0 || this.props.exportStatus == true}
                    > <Icon type="export" />Export</Button>
                    <div className="divider" />

                    <Popover
                        content={
                            <div>
                                {(this.props.allTotals == null) ?
                                    <p> No data obtained yet</p>
                                    :
                                    <div>
                                        <CurrTable className="totals-table"
                                            size="small"
                                            pagination={false}
                                            dataSource={this.props.allTotals.TotalsPerEntityAndCurrency}
                                            rowKey={(record) => record.EntityCode + record.OriginalCurrencyCode}
                                            locale={{ emptyText: 'No data found' }}
                                            footer={() => {
                                                return <div style={myPopoverFooterStyle}>
                                                    <div style={myPopoverFooterA}><b>Assets: </b>
                                                        {numberWithCommas(this.props.allTotals.OverallAssetsInLocalCurrency)}
                                                     </div>
                                                    <div style={myPopoverFooterL}><b>Liabilities: </b>
                                                        {numberWithCommas(this.props.allTotals.OverallLiabilitiesInLocalCurrency)}
                                                     </div>
                                                </div>
                                            }}
                                            > 

                                            <CurrColumn
                                                title={<div style={myColTitleStyle}>Entity</div>}
                                                dataIndex="EntityName"  key="EntityName"
                                                width="150px"
                                                sorter={(a, b) => nonNumberSorter(a.EntityName, b.EntityName)}
                                            />
                                            <CurrColumn 
                                                title={<div style={myColTitleStyle}>Currency</div>}
                                                dataIndex="OriginalCurrencyCode"
                                                key="OriginalCurrencyCode"
                                                sorter={(a, b) => nonNumberSorter(a.OriginalCurrencyCode, b.OriginalCurrencyCode)}
                                                width="50px"
                                            />
                                            <CurrColumn
                                                title={<div style={myColTitleStyle}>Assets</div>}
                                                key="TotalAssetsLocalCurrency"
                                                width="200px"
                                                render={(record) => (record.TotalAssetsLocalCurrency) ?
                                                    numberWithCommas(record.TotalAssetsLocalCurrency) : 0}
                                                sorter={(a, b) => a.Value - b.Value}
                                            />
                                            <CurrColumn
                                                title={<div style={myColTitleStyle}>Liabilities</div>}
                                                key="TotalLiabilitiesLocalCurrency"
                                                render={(record) => (record.TotalLiabilitiesLocalCurrency) ?
                                                    numberWithCommas(record.TotalLiabilitiesLocalCurrency) : 0}
                                                width="200px"
                                                sorter={(a, b) => a.Value - b.Value}
                                            />
                                            <CurrColumn
                                                title={<div style={myColTitleStyle}>FX Rate</div>}
                                                key="FxRate"
                                                render={(record) => (record.FxRate) ?
                                                    record.FxRate.toLocaleString("de-DE",
                                                            { mimimumFractionDigits: 3, maximumFractionDigits: 3 }) + " " +
                                                    record.OriginalCurrencyCode + "/" +
                                                    this.props.allTotals.LocalCurrencyCode
                                                    : "Formating issue in UI"}
                                                width="100px"
                                                sorter={(a, b) => a.Value - b.Value}
                                            />
                                        </CurrTable>
                                        <p>The balance sheet is {(this.props.allTotals.OverallAssetsInLocalCurrency != -this.props.allTotals.OverallLiabilitiesInLocalCurrency) ?
                                                " not " : ""} balanced.</p>
                                    </div>
                                }
                            </div>
                        }
                        title={ <div>
                            { (this.props.allTotals == null) ? "Multi-currency popover"
                                : <div>All entities for {this.props.allTotals.CountryName} (in thousands in {this.props.allTotals.LocalCurrencyCode })</div> } 
                           </div> }
                        trigger="click"
                        placement="bottom"
                        visible={this.state.popoverCurrency}
                        onVisibleChange={this.handlePopoverVisibleChange}
                        
                    > 

                        <Button
                            type="primary"
                                disabled={this.props.selectedDate == null ||
                                    this.props.selectedMifCountry == 0}
                            > <Icon type="global" />Check all currencies</Button>
                    </Popover>


                    <div className="divider" />
                    <div className="divider" />


                    {
                        this.state.isLoading == true ? <Spin /> : null
                    }

                </span>

                <div className="summary-view-complete">

                    <h2 style={{ marginLeft: 20, marginTop: 20}}>
                        Accounting Balance Sheet</h2>

                    <h3 style={{ marginLeft: 20, marginBottom: 0 }}>
                        Assets (MIF)</h3>
                    <RunoffViewTableAssetContainer
                            selectedDate={this.props.selectedDate}
                            selectedEntity={this.props.selectedMifEntity}
                            selectedCurrency={this.props.selectedCurrency}                   
                            ccyCode={this.props.ccyCode}                        
                         />

                    <h3 style={{ marginLeft: 20, marginBottom: 0 }}>
                        Liabilities (MIF)</h3>
                    <RunoffViewTableLiabilityContainer
                        selectedDate={this.props.selectedDate}
                        selectedEntity={this.props.selectedMifEntity}
                        selectedCurrency={this.props.selectedCurrency}
                        ccyCode={this.props.ccyCode} />


                    <h2 style={{ marginLeft: 20, marginBottom: 0 }}>
                        Reconcilation with eRDR (Difference Position)</h2>
                        <SummaryViewTableContainer
                            selectedDate={this.props.selectedDate}
                            selectedEntity={this.props.selectedMifEntity}
                            selectedCurrency={this.props.selectedCurrency}  
                            ccyCode={this.props.ccyCode}
                        />

                </div>
            </div>
        );

    }
}
