import { put, takeLatest, fork, select } from 'redux-saga/effects'
import config from '../config';
import * as Interfaces from "../interfaces"
import * as Actions from "./actions";
import * as Top from "../reducers"
import * as Selectors from "./selectors"
import * as GeneralSelectors from "../general/selectors"
import { notification } from "antd"
import * as MainActions from "../actions";

import * as Eff from 'redux-saga/effects'
const takeEvery: any = Eff.takeEvery;

import AuthenticatedRequest from "../http";

function equityInjectionsTakeEvery<TAction extends string>(action: TAction,
    reducer: (act: Actions.Action & { type: TAction }) => any) {
    return takeEvery(action, reducer);
}

function* loadEquityInjections({ forceReload = true }) {

    const selectedCountryId: Date = yield select(GeneralSelectors.getCountry);
    const selectedEntityId: Date = yield select(GeneralSelectors.getEntity);
    const selectedCurrencyId: Date = yield select(GeneralSelectors.getCurrency);

    const currentEquityInjections: Interfaces.EquityInjectionEntry[] = yield (select<any>(x => x.equityInjections));
    if (currentEquityInjections.length === 0 || forceReload) {

        let url = config.apiBaseUrl + "/api/EquityInjections?selectedCountryId=" + selectedCountryId + "&selectedEntityId=" + selectedEntityId + "&selectedCurrencyId=" + selectedCurrencyId;

        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
                {
                    mode: "cors",
                    credentials: "include"
                });
                if (response.status == 401) {
                    notification.error({ message: "Error", description: "Access token expired" });
                    yield put(MainActions.getRefreshTokenAction())
                }
            const json: Interfaces.EquityInjectionEntry[] = yield response.json();
            yield put(Actions.setEquityInjections(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading equity injections" });
        }

    }
}

function* watchLoadEquityInjections() {
    yield takeEvery(Actions.LOAD_EQUITY_INJECTIONS, loadEquityInjections);
}


function* addSaveEquityInjection({ equityInjection }) {

    const url = config.apiBaseUrl + "/api/EquityInjections";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(equityInjection)
            }
        )
        
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        } else if (!response.ok) {
                throw new Error("Error adding equity injection")
            }
            
        yield put(Actions.loadEquityInjections());
        notification["success"]({ message: 'Success', description: 'Equity injection successfully added', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving equity injection" });
    }
}

function* watchAddSaveEquityInjection() {
    yield takeEvery(Actions.ADD_SAVE_EQUITY_INJECTION, addSaveEquityInjection);
}


//NB this needs to be a PUT action
function* editSaveEquityInjection({ equityInjection }) {

    const url = config.apiBaseUrl + "/api/EquityInjections/" + equityInjection.Id;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(equityInjection)
            })
            
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            } else if (!response.ok) {
                    throw new Error("Error editing equity injection")
                }
                

        yield put(Actions.loadEquityInjections());
        notification["success"]({ message: 'Success', description: 'Equity injection successfully edited', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving equity injection" });
    }
}

function* watchEditSaveEquityInjection() {
    yield takeEvery(Actions.EDIT_SAVE_EQUITY_INJECTION, editSaveEquityInjection);
}


function* deleteEquityInjection({ equityInjectionId }) {
    const url = config.apiBaseUrl + "/api/EquityInjections/" + equityInjectionId.toString();
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "DELETE"
            })
            
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            } else if (!response.ok) {
                    throw new Error("Error deleting equity injection")
                }
                

        yield put(Actions.loadEquityInjections());
        notification["success"]({ message: 'Success', description: 'Equity injection successfully deleted' });

    } catch (e) {
        notification.error({ message: "Error", description: "Error Deleting equity injection" });
    }
}

function* watchDeleteEquityInjection() {
    yield takeEvery(Actions.DELETE_EQUITY_INJECTION, deleteEquityInjection);
}

export function* saga() {
    yield fork(watchLoadEquityInjections);
    yield fork(watchAddSaveEquityInjection);
    yield fork(watchEditSaveEquityInjection);
    yield fork(watchDeleteEquityInjection);
}