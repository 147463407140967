// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";
import { Table, Badge, Menu, Icon, Button, Input, DatePicker } from 'antd';
const { Column, ColumnGroup } = Table;
import * as Immutable from "immutable"
import moment from "moment";

interface MaturityCellProps {
    editableStatus: boolean,
    value: any,
    dateValue: Date,
    editStateValue: (value: any) => void,
    editStateDateValue: (value: any) => void,
    maturityType: number,
}

export default class MaturityCell extends React.Component<MaturityCellProps, {}> {

    constructor(props: any) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    //Change the state of the RiskPositionMappingTable parent component through it's function
    handleChange(e) {
        this.props.editStateValue(e.target.value);

    };

    handleDateChange(e) {
        this.props.editStateDateValue(e);

    };
    
    render() {
        const value = this.props.value;
        const dateValue = this.props.dateValue;
        const editableStatus = this.props.editableStatus;
        const maturityType = this.props.maturityType;

        if (maturityType === 1) {

            return (
                <div className="editable-row-text">
                    As Retail
                </div>
            );

        }

        else if (maturityType === 2) {
            return (
                <div className="editable-row-text">
                    As Wholesale
                </div>
            );
        }
        else if (maturityType === 3) {

            return (
                editableStatus
                    ? <div>
                          <DatePicker
                              format="DD MMMM YYYY"
                              defaultValue={this.props.dateValue !== null ? moment(this.props.dateValue) : null}
                              placeholder={"Insert Date"}
                              onChange={this.handleDateChange}>
                          </DatePicker>
                      </div>
                    : <div className="row-text">
                          {moment(dateValue).format("DD MMMM YYYY") || " "}
                      </div>
            );
        } else if (maturityType === 4) {

            return (
                editableStatus
                    ? <div>
                        <Input
                            defaultValue={this.props.value}
                            onChange={e => this.handleChange(e)}
                            placeholder={"No. of M/Y (e.g. 10Y)"}
                        />
                    </div>
                    :
                    <div className="row-text">
                        {value != null ? value.toString() : ""}
                    </div>

            );
        }

        else if (maturityType === 5) {

            return (
                <div className="row-text">
                    30Y
                </div>
            );
        }

        else {
            return (
                <div className="row-text">
                    {value != null ? value.toString() : ""}
                </div>
            );
        }
    }
};