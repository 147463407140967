// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import  {RiskPositionTable}  from "../components/RiskPositionTable";
import * as Actions from "../actions";
import * as Interfaces from "../interfaces";


const mapStateToProps = (state) => ({
    riskPositions: state.riskPositions
    
});


const mapDispatchToProps = (dispatch => ({
    addSaveRiskPosition: (newRiskPosition: Interfaces.RiskPositionEntry) => dispatch(Actions.addSaveRiskPosition(newRiskPosition)),
    deleteRiskPosition: (x: number) => dispatch(Actions.deleteRiskPosition(x)),
    loadRiskPositions: () => dispatch(Actions.loadRiskPositions())

}));

const RiskPositionTableContainer = connect(mapStateToProps, mapDispatchToProps)(RiskPositionTable);

export default RiskPositionTableContainer;
