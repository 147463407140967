// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ErdrPageSelectorTable } from "../components/ErdrPageSelectorTable";
import config from "../../config";
import { Actions, Selectors, State, Dispatch } from "./imports"

const mapStateToProps = (state: State) => ({
    availablePages: Selectors.getAvailablePages(state)

});

const mapDispatchToProps = (dispatch: Dispatch) => ({

});

function mergeProps(stateProps, dispatchProps, ownProps: RouteComponentProps<{}>) {
    return { ...stateProps, ...dispatchProps, changePage: (id: number) => ownProps.history.push(`${config.uiBaseUrl}ErdrData/${id}`) };
}

const ErdrPageSelectorTableContainer: React.ComponentClass<{}> = withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ErdrPageSelectorTable));

export default ErdrPageSelectorTableContainer;
