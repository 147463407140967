// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";

import * as Actions from "../actions";
import * as Interfaces from "../interfaces";
import UploadDRMFileForm from "../components/UploadDRMFileForm";
import * as GeneralSelectors from "../general/selectors";

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    uploadDrmFile: file => dispatch(Actions.uploadDrmFile(file))
});

const UploadDRMFileFormContainer = connect(mapStateToProps, mapDispatchToProps)(UploadDRMFileForm);

export default UploadDRMFileFormContainer;
