// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces"


import { Table, Icon, Button, Input, Select } from 'antd';
const { Column, ColumnGroup } = Table;
const Option = Select.Option;


export interface ErdrRunOffPageProps {
    page: Interfaces.ErdrRunOffEntry[];
    isLoading: boolean;
}


function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

function percentageNumberWithCommas(x) {
    return (x * 100).toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "%";
}


class MyTable extends Table<Interfaces.ErdrRunOffEntry> { }
class MyColumn extends Column<Interfaces.ErdrRunOffEntry> { }


export default class ErdrRunOffPage extends React.Component<ErdrRunOffPageProps, undefined> {

    render() {

        var page = this.props.page;

        if (page == null) {
            return <div>
                <h2 style={{ textAlign: "center" }}>{this.props.isLoading ? "Loading..." : "No Data"}</h2>
            </div>;
        } else {

            return (

                <MyTable className="erdr-page" size="small" dataSource={page} scroll={{ x: 1900 }} pagination={page.length > 10} rowKey="Id">
                    <MyColumn
                        title="Currency"
                        dataIndex="CurrencyName"
                        key="CurrencyName"
                        filters={[...new Set(this.props.page.map(x => x.CurrencyName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value))}
                        onFilter={(value, record) => record.CurrencyName.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CurrencyName, b.CurrencyName)}
                        fixed="left"
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Retail RunOff (Liq)"
                        render={(text, record: any) => (record.RE_RunOffLiq !== null) ? numberWithCommas(record.RE_RunOffLiq, record.CurrencyCode) : "-"}
                        key="RE_RunOffLiq"
                        sorter={(a, b) => a.RE_RunOffLiq - b.RE_RunOffLiq}
                        width={120} />

                    <MyColumn
                        className="numeric-column"
                        title="Retail IRR"
                        render={(text, record: any) => (record.RE_InterestRateIrr !== null) ? percentageNumberWithCommas(record.RE_InterestRateIrr) : "-"}
                        key="RE_InterestRateIrr"
                        sorter={(a, b) => a.RE_InterestRateIrr - b.RE_InterestRateIrr}
                        width={95} />

                    <MyColumn
                        className="numeric-column"
                        title="Retail MFR"
                        render={(text, record: any) => (record.RE_MarketRateMfr !== null) ? percentageNumberWithCommas(record.RE_MarketRateMfr) : "-"}
                        key="RE_MarketRateMfr"
                        sorter={(a, b) => a.RE_MarketRateMfr - b.RE_MarketRateMfr}
                        width={95} />

                    <MyColumn
                        className="numeric-column"
                        title="Retail MFS"
                        render={(text, record: any) => (record.RE_MarketSpreadMfs !== null) ? percentageNumberWithCommas(record.RE_MarketSpreadMfs) : "-"}
                        key="RE_MarketSpreadMfs"
                        sorter={(a, b) => a.RE_MarketSpreadMfs - b.RE_MarketSpreadMfs}
                        width={100} />
                    
                    <MyColumn
                        className="numeric-column"
                        title="Wholesale RunOff (Liq)"                       
                        render={(text, record: any) => (record.WS_RunOffLiq !== null) ? percentageNumberWithCommas(record.WS_RunOffLiq) : "-"}
                        key="WS_RunOffLiq"
                        sorter={(a, b) => a.WS_RunOffLiq - b.WS_RunOffLiq}
                        width={130} />

                    <MyColumn
                        className="numeric-column"
                        title="Wholesale IRR"
                        render={(text, record: any) => (record.WS_InterestRateIrr !== null) ? percentageNumberWithCommas(record.WS_InterestRateIrr) : "-"}
                        key="WS_InterestRateIrr"
                        sorter={(a, b) => a.WS_InterestRateIrr - b.WS_InterestRateIrr}
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Wholesale MFS"
                        render={(text, record: any) => (record.WS_MarketSpreadMfs !== null) ? percentageNumberWithCommas(record.WS_MarketSpreadMfs) : "-"}
                        key="WS_MarketSpreadMfs"
                        sorter={(a, b) => a.WS_MarketSpreadMfs - b.WS_MarketSpreadMfs}
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Wholesale MFR"
                        render={(text, record: any) => (record.WS_MarketRateMfr !== null) ? percentageNumberWithCommas(record.WS_MarketRateMfr) : "-"}
                        key="WS_MarketRateMfr"
                        sorter={(a, b) => a.WS_MarketRateMfr - b.WS_MarketRateMfr}
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Portfolio MFS"
                        render={(text, record: any) => (record.PF_MarketSpreadMfs !== null) ? percentageNumberWithCommas(record.PF_MarketSpreadMfs) : "-"}
                        key="PF_MarketSpreadMfs"
                        sorter={(a, b) => a.PF_MarketSpreadMfs - b.PF_MarketSpreadMfs}
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="Portfolio MFR"
                        render={(text, record: any) => (record.PF_MarketRateMfr !== null) ? percentageNumberWithCommas(record.PF_MarketRateMfr) : "-"}
                        key="PF_MarketRateMfr"
                        sorter={(a, b) => a.PF_MarketRateMfr - b.PF_MarketRateMfr}
                        width={100} />

                    <MyColumn
                        className="numeric-column"
                        title="TM1 Wholesale IRR"
                        render={(text, record: any) => (record.TM1_InterestRateIrr !== null) ? percentageNumberWithCommas(record.TM1_InterestRateIrr) : "-"}
                        key="TM1_InterestRateIrr"
                        sorter={(a, b) => a.TM1_InterestRateIrr - b.TM1_InterestRateIrr}
                        width={115} />

                    <MyColumn
                        className="numeric-column"
                        title="TM1 Wholesale MFS"
                        render={(text, record: any) => (record.TM1_MarketSpreadMfs !== null) ? percentageNumberWithCommas(record.TM1_MarketSpreadMfs) : "-"}
                        key="TM1_MarketSpreadMfs"
                        sorter={(a, b) => a.TM1_MarketSpreadMfs - b.TM1_MarketSpreadMfs}
                        width={115} />

                    <MyColumn
                        className="numeric-column"
                        title="TM1 Wholesale MFR"
                        render={(text, record: any) => (record.TM1_MarketRateMfr !== null) ? percentageNumberWithCommas(record.TM1_MarketRateMfr) : "-"}
                        key="TM1_MarketRateMfr"
                        sorter={(a, b) => a.TM1_MarketRateMfr - b.TM1_MarketRateMfr}
                        width={115} />
                   
                </MyTable>
            );

        }
    }
}