// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom"
import  {RiskPositionModelTable}  from "../components/RiskPositionModelTable";
import * as Actions from "../actions";
import * as Interfaces from "../interfaces";
import * as GeneralSelectors from "../general/selectors";


const mapStateToProps = (state) => ({
    RiskPositionModels: state.riskPositionModels,
    generalLedgers: state.generalLedgers,
    runoffModels: state.runoffModels,
    runoffMaturityTypes: state.runoffMaturityTypes,
    runoffIrrTypes: state.runoffIrrTypes,
    runoffMfrMfsTypes: state.runoffMfrMfsTypes,
    modelableRiskPositions: state.modelableRiskPositions
});


const mapDispatchToProps = (dispatch => ({
    addSaveRiskPositionModel: (newRiskPositionModel: Interfaces.RiskPositionModelEntry) => dispatch(Actions.addSaveRiskPositionModel(newRiskPositionModel)),
    deleteRiskPositionModel: (x: number) => dispatch(Actions.deleteRiskPositionModel(x)),
    editSaveRiskPositionModel: (x: Interfaces.RiskPositionModelEntry) => dispatch(Actions.editSaveRiskPositionModel(x)),
    loadRiskPositionModels: () => dispatch(Actions.loadRiskPositionModels())

}));

const RiskPositionModelTableContainer = connect(mapStateToProps, mapDispatchToProps)(RiskPositionModelTable);

export default RiskPositionModelTableContainer;
