// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

//import * as React from "react";
import React, { useState, useEffect, Component } from 'react'
import { connect, useSelector } from 'react-redux'
import { ConnectedRouter, routerMiddleware, push } from 'connected-react-router'

import { Layout, LocaleProvider } from "antd";
const { Header, Footer, Sider, Content } = Layout;
import config from "./config";

import "./app.less";

import MainMenuContainer from "./containers/MainMenuContainer";
import MifData from "./mif";
import ErdrData from "./erdr";
import AdjustmentsData from "./components/AdjustmentsData";
import EquityInjectionsData from "./equityInjections/components/EquityInjectionsData";
import SpecialRulesData from "./specialRules/components/index";
import ThereofsData from "./specialRules/components/ThereofsData";
import RunoffData from "./runoffView";
import RiskPositionMappingData from "./components/RiskPositionMappingData"
import RiskPositionModelData from "./components/RiskPositionModelData"
import RiskPositionData from "./components/RiskPositionData"
import FileUploaderContainer from "./containers/FileUploaderContainer";
import DrmFileUploaderContainer from "./containers/DrmFileUploaderContainer";
import history from './history'


import * as Actions from "./actions"

import UserRoute from "./UserRoute"

class App extends Component<any, any> {

    //constructor
    constructor(props) {
        super(props);
        this.state = {
            codeFromDev: null,
        };
    }

    getCodeFromDev = () => {
        const token = window.sessionStorage.getItem('token');
        if (token && token !== "undefined") {

            console.log('token exists')
            console.log(JSON.parse(window.sessionStorage.getItem('token')), "code from session")
            this.props.initDataWithToken(JSON.parse(window.sessionStorage.getItem('token')))
            //this.props.loginFromOIDC(window.sessionStorage.getItem('code'))
        } else if (window.location.href.indexOf('code') !== -1) {
            console.log(':: GOT CODE ::');
            let code = window.location.href.split('=')[1];
            code = code.split('#/')[0];

            this.props.loginFromOIDC(code)

            var replaceURl = '';

            if (window.location.port !== '' && window.location.pathname !== '/') {
                replaceURl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            } else {
                replaceURl = 'https://' + window.location.hostname;
            }
            // eslint-disable-next-line no-restricted-globals
            this.setState({ codeFromDev: code })
            // window.sessionStorage.setItem('code', code)
        } else if (window.location.href.indexOf('access_denied') !== -1) {
            location.href = `${config.accessDeniedRedirect}/error/access-denied`
        }
        else {
            // eslint-disable-next-line no-restricted-globals
            location.href = `${config.sso}/as/authorization.oauth2?response_type=code&client_id=${config.client}&scope=openid%20profile%20offline_access%20basic_start_authorization&redirect_uri=${config.redirect}`;
        }
    }

    getTokenFromLocal() {
        this.props.loginFromLocal();
    }

    componentDidMount() {
        if (config.env === 'Local') {
            this.getTokenFromLocal();
        } else {
            this.getCodeFromDev();
        }
    }

    render() {

        return (
            this.props.user &&
            <Layout>
                <ConnectedRouter history={history}>

                    <Layout style={{ "height": "97.5%" }}>
                        <Sider width={210} style={{ "verticalAlign": "top" }}>
                            <MainMenuContainer />
                        </Sider>
                        <Content>
                            <UserRoute path={config.uiBaseUrl + "MifData/:pageId?"} component={MifData} />
                            <UserRoute path={config.uiBaseUrl + "FileUpload"} component={FileUploaderContainer} />
                            <UserRoute path={config.uiBaseUrl + "RiskPositionMappings"} component={RiskPositionMappingData} />
                            <UserRoute path={config.uiBaseUrl + "RiskPositionModels"} component={RiskPositionModelData} />
                            <UserRoute path={config.uiBaseUrl + "RiskPositions"} component={RiskPositionData} />
                            <UserRoute path={config.uiBaseUrl + "DrmFileUpload"} component={DrmFileUploaderContainer} />
                            <UserRoute path={config.uiBaseUrl + "ErdrData/:pageId?"} component={ErdrData} />
                            <UserRoute path={config.uiBaseUrl + "Adjustments"} component={AdjustmentsData} />
                            <UserRoute path={config.uiBaseUrl + "SpecialRules"} component={SpecialRulesData} />
                            <UserRoute path={config.uiBaseUrl + "Thereofs"} component={ThereofsData} />
                            <UserRoute path={config.uiBaseUrl + "EquityInjections"} component={EquityInjectionsData} />
                            <UserRoute path={config.uiBaseUrl + "SummaryView"} component={RunoffData} />
                        </Content>
                    </Layout>
                </ConnectedRouter>

                <Layout>
                    <Sider width={210}>
                        <div style={{ "color": "white", "textAlign": "center" }}>
                            Version 1.7.0</div>
                    </Sider>
                </Layout>
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch => ({
    loginFromOIDC: (code) => dispatch(Actions.loginUserAction(code)),
    loginFromLocal: () => dispatch(Actions.localUserLogin()),
    initDataWithToken: (item) => dispatch(Actions.initDataWithDataAction(item))
}))

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps, mapDispatchToProps)(App);