import * as Interfaces from "../interfaces";

export type LOAD_EQUITY_INJECTIONS = "EQUITY_INJECTIONS/LOAD_EQUITY_INJECTIONS";
export const LOAD_EQUITY_INJECTIONS: LOAD_EQUITY_INJECTIONS = "EQUITY_INJECTIONS/LOAD_EQUITY_INJECTIONS";
export type LoadEquityInjectionsAction = {
    type: LOAD_EQUITY_INJECTIONS,
    forceReload: boolean,
}
export function loadEquityInjections(forceReload = true): Action {
    return {
        type: LOAD_EQUITY_INJECTIONS,
        forceReload
    }
}

export type SET_EQUITY_INJECTIONS = "EQUITY_INJECTIONS/SET_EQUITY_INJECTIONS";
export const SET_EQUITY_INJECTIONS: SET_EQUITY_INJECTIONS = "EQUITY_INJECTIONS/SET_EQUITY_INJECTIONS";
export type SetEquityInjectionsAction = {
    type: SET_EQUITY_INJECTIONS,
    equityInjections: Interfaces.EquityInjectionEntry[],
}
export function setEquityInjections(equityInjections: Interfaces.EquityInjectionEntry[]) {
    return {
        type: SET_EQUITY_INJECTIONS,
        equityInjections
    }
}

export type ADD_SAVE_EQUITY_INJECTION = "EQUITY_INJECTIONS/ADD_SAVE_EQUITY_INJECTION";
export const ADD_SAVE_EQUITY_INJECTION: ADD_SAVE_EQUITY_INJECTION = "EQUITY_INJECTIONS/ADD_SAVE_EQUITY_INJECTION";
export type AddSaveEquityInjectionAction = {
    type: ADD_SAVE_EQUITY_INJECTION,
    equityInjection: Interfaces.EquityInjectionEntry,
}
export function addSaveEquityInjection(equityInjection: Interfaces.EquityInjectionEntry): Action {
    return {
        type: ADD_SAVE_EQUITY_INJECTION,
        equityInjection: equityInjection
    }
}

export type EDIT_SAVE_EQUITY_INJECTION = "EQUITY_INJECTIONS/EDIT_SAVE_EQUITY_INJECTION";
export const EDIT_SAVE_EQUITY_INJECTION: EDIT_SAVE_EQUITY_INJECTION = "EQUITY_INJECTIONS/EDIT_SAVE_EQUITY_INJECTION";
export type EditSaveEquityInjectionAction = {
    type: EDIT_SAVE_EQUITY_INJECTION,
    equityInjection: Interfaces.EquityInjectionEntry,
}
export function editSaveEquityInjection(equityInjection: Interfaces.EquityInjectionEntry): Action {
    return {
        type: EDIT_SAVE_EQUITY_INJECTION,
        equityInjection: equityInjection
    }
}

export type DELETE_EQUITY_INJECTION = "EQUITY_INJECTIONS/DELETE_EQUITY_INJECTION";
export const DELETE_EQUITY_INJECTION: DELETE_EQUITY_INJECTION = "EQUITY_INJECTIONS/DELETE_EQUITY_INJECTION";
export type DeleteEquityInjectionAction = {
    type: DELETE_EQUITY_INJECTION,
    equityInjectionId: number,
}
export function deleteEquityInjection(equityInjectionId: number): Action {
    return {
        type: DELETE_EQUITY_INJECTION,
        equityInjectionId: equityInjectionId
    }
}

export type OtherAction = { type: "EQUITY_INJECTIONS/Default" }
export const OtherAction: OtherAction = { type: "EQUITY_INJECTIONS/Default" }

export type Action =
    LoadEquityInjectionsAction | SetEquityInjectionsAction | AddSaveEquityInjectionAction | EditSaveEquityInjectionAction | DeleteEquityInjectionAction | OtherAction