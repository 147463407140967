import {match} from "react-router-dom"
import * as Interfaces from "../interfaces"
import { State } from "./reducers"
import { mif } from "../selectors"
import * as Top from "../reducers"

const getAvailablePagesInner = (state: State) => state.availablePages;
export const getAvailablePages = (state: Top.State) => getAvailablePagesInner(mif(state));

const getPageFullInner = (state: State, id: number) => state.pagesFull.get(id);
export const getPageFull = (id: number) => (state: Top.State) => getPageFullInner(mif(state), id);
export function getPageFullFromMatch(match: match<{ pageId?: string }>): (state: Top.State) => Interfaces.MifPageFull {
    if (!match.params)
        return () => null;
    const id = parseInt(match.params.pageId);
    if (!id)
        return () => null;
    return getPageFull(id);
}

const getCurrencyFilterInner = (state: State) => state.currencyFilter;
export const getCurrencyFilter = (state: Top.State) => getCurrencyFilterInner(mif(state));

const getFinalisingStatusInner = (state: State) => state.finalisingStatus;
export const getFinalisingStatus = (state: Top.State) => getFinalisingStatusInner(mif(state));

const getMifPageTableLoadingStatusInner = (state: State) => state.mifPageTableLoadingStatus;
export const getMifPageTableLoadingStatus = (state: Top.State) => getMifPageTableLoadingStatusInner(mif(state));