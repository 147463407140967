// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces";
import { Table, Badge, Menu, Icon, Button, Input, InputNumber, Select, DatePicker } from "antd";
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
import * as Immutable from "immutable";
import moment from "moment";



//put props interface here to keep track of what is being passed down to the component
export interface SelectableCellEquityInjectionsProps {
    recordId: number,
    editableStatus: boolean,
    columnName: string,
    value: string,
    editStateValue: (recordId: number, fieldName: string, value: any) => void,
}

export class SelectableCellEquityInjections extends React.Component<SelectableCellEquityInjectionsProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: this.props.value,
            editableStatus: this.props.editableStatus || false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: any) {
        this.props.editStateValue(this.props.recordId,
            this.props.columnName,
            e);
        this.setState({ value: e });

    };


    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({ editableStatus: nextProps.editableStatus });


    }

    render() {
        const value = this.state.value;
        const editableStatus = this.state.editableStatus;
        switch (this.props.columnName) {



            case "ValidFrom": //ValidFrom
                return (
                    editableStatus
                        ? <div>
                            <MonthPicker
                                style={{ width: '100%' }}
                                format="MMMM YYYY"
                                defaultValue={this.props.value !== null ? moment(this.props.value) : moment()}

                                // style={{ width: 150 }}
                                // placeholder={this.props.value !== null ? this.props.value : "Country"}"
                                onChange={this.handleChange}
                            >

                            </MonthPicker>
                        </div>
                        : <div className="editable-row-text">
                            {value.toString() || " "}
                        </div>
                );
            case "ValidTo": //ValidFrom
                return (
                    editableStatus
                        ? <div>
                            <MonthPicker
                                style={{ width: '100%' }}
                                format="MMMM YYYY"
                                defaultValue={this.props.value !== null ? moment(this.props.value) : null}
                                placeholder={"Optional"}
                                onChange={this.handleChange}
                            >

                            </MonthPicker>
                        </div>
                        : <div className="editable-row-text">
                            {value.toString() || " "}
                        </div>
                );
            case "InjectionDate": //ValidFrom
                return (
                    editableStatus
                        ? <div>
                            <MonthPicker
                                style={{ width: '100%' }}
                                format="MMMM YYYY"
                                defaultValue={this.props.value !== null ? moment(this.props.value) : null}
                                placeholder={"Optional"}
                                onChange={this.handleChange}
                            >

                            </MonthPicker>
                        </div>
                        : <div className="editable-row-text">
                            {value.toString() || " "}
                        </div>
                );

            case "Value": //Value
                return editableStatus
                    ? <div>
                        <InputNumber
                            style={{ width: '100%', textAlign: 'right'  }}
                            defaultValue={this.props.value !== null ? parseFloat(this.props.value) : null}
                            //step={1}
                            size="large"
                            formatter={value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            //parser={value => value.replace(/\$\s?|(,*)/g, "")}

                            onChange={this.handleChange}
                        />
                    </div>
                    : <div className="editable-row-text">
                        {value.toFixed(2) || " "}
                    </div>;

            case "Comment": //Comment
                return (
                    editableStatus
                        ? <div>
                            <Input
                                style={{ width: '100%' }}
                                defaultValue={this.props.value !== null ? this.props.value : ""}
                                onChange={this.handleChange}
                            />
                        </div>
                        : <div className="editable-row-text">
                            {value.toString() || " "}
                        </div>
                );


        };
    }
};