import UrlMap from "../UrlMap"

import * as Actions from "./actions"
import * as GeneralActions from "../general/actions"

export const urls = [
    new UrlMap("SpecialRules",
        () => [ 
            Actions.loadSpecialRules(),
            GeneralActions.loadCountries(),
            GeneralActions.loadEntities(),
            GeneralActions.loadCurrencies(),
            GeneralActions.loadRiskPositionNames(),
        ]),

    new UrlMap("Thereofs",
        () => [
            Actions.loadThereofs(),
            GeneralActions.loadCountries(),
            GeneralActions.loadEntities(),
            GeneralActions.loadCurrencies(),
            GeneralActions.loadRiskPositionNames(),
        ])
];