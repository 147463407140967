import { connect } from "react-redux";
import { RunoffView } from "../components/RunoffView";
import * as Interfaces from "../../interfaces";
import {Actions, GeneralActions, GeneralSelectors, Selectors, State, Dispatch} from "./imports"

const mapStateToProps = (state : State) => ({
    mifCountries: GeneralSelectors.getCountries(state),
    availableEntities: GeneralSelectors.getAvailableEntities(state),
    mifCurrencies: GeneralSelectors.getCurrencies(state),   
    selectedDate: GeneralSelectors.getDate(state),
    selectedMifCountry: GeneralSelectors.getCountry(state),
    selectedMifEntity: GeneralSelectors.getEntity(state),
    selectedCurrency: GeneralSelectors.getCurrency(state),
    allTotals: state.runoffView.allTotals,
    ccyCode: GeneralSelectors.getCurrencyById(state) && GeneralSelectors.getCurrencyById(state).Code,
    runoffDataCount: (Selectors.getAsset(state) != null || Selectors.getLiability(state) != null) ? Math.max(Selectors.getAsset(state).length, Selectors.getLiability(state).length) : 0,
    finalisedDate: Selectors.getFinalisedDate(state),
    runoffLoadingStatus: Selectors.getRunoffLoadingStatus(state),
    exportStatus: Selectors.getRunoffExportingStatus(state)
});

const mapDispatchToProps = (dispatch : Dispatch) => ({
    updateRunoffView: () => dispatch(Actions.load()),
    exportDispatch: () => dispatch(Actions.exportAction()),
    updateAllTotals: () => dispatch(Actions.loadAllTotals()), // for popover
    setLoadingStatus: (loadingStatus) => dispatch(Actions.setLoadingStatus(loadingStatus)),
    setExportingStatus: (exportingStatus) => dispatch(Actions.setExportingStatus(exportingStatus)),
    selectDate: (selectedDate) => dispatch(GeneralActions.selectDate(selectedDate)),
    selectCountry: (selectedCountryId) => dispatch(GeneralActions.selectCountry(selectedCountryId)),
    selectEntity: (selectedEntityId) => dispatch(GeneralActions.selectEntity(selectedEntityId)),
    selectCurrency: (selectedcurrencyId) => dispatch(GeneralActions.selectCurrency(selectedcurrencyId))
});

const RunoffViewContainer: any = connect(mapStateToProps, mapDispatchToProps)(RunoffView);

export default RunoffViewContainer;