import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form, Select,  Button, Upload, Icon, DatePicker, message} from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
import * as Actions from "../actions";
import * as Interfaces from "../interfaces";

const { MonthPicker } = DatePicker;

export interface UploadFileFormProps {
    availableEntities: Interfaces.MifEntity[],
    selectedEntity: number,
    selectedDate: any,
    selectedFile: any,
    selectedFileType: any,

}


class UploadFileForm extends React.Component<any, any> {
 

    constructor (props:any) {
        super(props);
        this.state = {
            selectedFile: "",
            selectedFileType: "",

            selectedEntity: null,
            selectedDate: "",

        };
    }

    handleSubmit = (e) => {
        message.config({ top: 0 });
        // fgreil 2019-09-09: number after top is time(seconds) before auto-dismiss, don't dismiss if set to 0
        // for more, see https://ant.design/components/message/ 
        message.loading('Importing in progress...', 3);
        e.preventDefault();
        this.props.uploadFile(this.props.form.getFieldsValue()); 

    }

    handleSubmitForAutoloader = (e) => {
        message.config({ top: 0 });
        message.loading('Autoupload initiated.', 3);
        e.preventDefault();
        this.props.autoUploadFiles(this.props.form.getFieldsValue());

    }


    disabledImportButtonChecker = () =>
    {
        if (this.state.selectedFile === ""  || this.state.selectedFileType === "")
            return true;
        //  else if (this.props.form.getFieldsValue().upload == undefined || this.props.form.getFieldsValue().select == undefined)
        //     return true;
        // else if (this.props.form.getFieldsValue().upload.length == 0)
        //     return true;
        else
            return false;
    }


    disabledAutoUploadButtonChecker = () => {
        // console.log(`disabledAutoUploadButtonChecker() called.`);
        if (this.state.selectedDate == undefined || this.state.selectedEntity == undefined)
            return true;
        else
            return false;
    }


    handleSelectorChange(type: string, e: any) {
        console.log(`Selected value of type '${type}' is '${e}'`);
        switch (type) {
            case "RepDate":
                var newDate = e
                if (newDate !== null) {
                    newDate = e.toDate();
                    newDate.setDate(15);
                }
                this.setState({
                    selectedDate: newDate
                });
                break;
            case "Entity":
                this.setState({
                    selectedEntity: parseInt(e)
                });
                break;
            case "FileToLoad":
                this.setState({
                    selectedFile: e
                });
                break;
            case "FileType":
                this.setState({
                    selectedFileType: e
                });
                break;
        }
        // this.props.setLoadingStatus(true);
        //this.props.updateRunoffView();
    }


    render() {

        const { getFieldDecorator } = this.props.form;

        const innerForm = {
            display: "flex"
        } as React.CSSProperties;

        const leftForm = {
            float: "left"
        } as React.CSSProperties;

        const rightForm = {
            float: "right",
            marginRight: "40px"
        } as React.CSSProperties;


        return (
            <div>
                <h3 style={{ marginLeft: 0, marginTop: 6 }}>Upload a single file</h3>
                <Form onSubmit={this.handleSubmit} layout="inline">
                    <FormItem  // {...myFormItemLayout} label="Upload a single file" 
                        // hasFeedback
                            >

                        {getFieldDecorator('selectFileType', {
                             valuePropName: "fileType"
                            })( 

                        <Select placeholder="Select file type"
                            onChange={(e) => { this.handleSelectorChange("FileType", e) }} 
                                        style={{ width: "150px" }}>
                                    <Option value="Mif">MIF</Option>
                                    <Option value="eRDR">eRDR</Option>
                                    <Option value="FxRates">Fx Rates</Option>
                                    <Option value="TM1Data">TM1 Data</Option>
                                    <Option value="LiquiditySpreadCurve">Spread Curves</Option>
                                    <Option value="HistoricalInterestRate">Interest Rate Curves</Option>
                                </Select>
                        
                        )} 
                         
                            </FormItem>
                            <FormItem // {...myFormItemLayout} label="File"
                            >
                        {getFieldDecorator('upload', {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: (e) => e.fileList.slice(-1)
                                })(
                        <Upload name="importFile" action={' '} multiple={false}
                            onChange={(e) => { this.handleSelectorChange("FileToLoad", e.file.name) }} >
                                 <Button>
                                            <Icon type="upload" />Choose file
                                        </Button>
                                    </Upload>
                        )}
                            </FormItem>
                            <FormItem // {...myFormItemLayout}
                            >
                                <Button
                                    type="primary" htmlType="submit"
                                    disabled={this.disabledImportButtonChecker()}>
                                    Import single file
                                </Button>
                            </FormItem>
                    </Form>

                <h3 style={{ marginLeft: 0, marginTop: 6 }}> Upload more files at once</h3>

                { /* fgreil: New semi-auto-upload part */ }
                <Form onSubmit={this.handleSubmitForAutoloader} layout="inline">
                    { /*<FormItem
                       //  label="Upload multiple files"
                    >
                        {getFieldDecorator('select', {
                            valuePropName: "fileTypeAutoloader"
                        })(
                            <Select
                            placeholder="File class for scan"

                            style={{ width: "150px" }}>
                                <Option value="all">all (MIF, eRDR, TM1)</Option>
                                <Option value="eRDRnTM1">eRDR (FBSR) & TM1</Option>
                            </Select>
                        )}
                    </FormItem> */ }
                    <FormItem>
                        {getFieldDecorator('selectRepDate', {
                            valuePropName: "selectedRepDate"
                        })(
                            <MonthPicker
                                format="MMMM YYYY"
                                style={{ width: 150 }}
                                placeholder="Reporting Date"
                                onChange={(e) => { this.handleSelectorChange("RepDate", e) }}
                            // value={this.props.selectedDate !== null ? moment(this.props.selectedDate) : null}
                            >
                            </MonthPicker>
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('selectEntity', {
                            valuePropName: "selectedEntity"
                        })(
                            <Select
                                showSearch
                                style={{ width: 150 }}
                                placeholder="Entity for file scan"
                                optionFilterProp="children"
                                filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                                    var optionValue;
                                    for (var optionProp in option) {
                                        if (optionProp = 'props') {
                                            optionValue = option[optionProp].children.toLowerCase();
                                        }
                                    }
                                    return optionValue.indexOf(input.toLowerCase()) >= 0;
                                }}
                                onChange={(e) => { this.handleSelectorChange("Entity", e) }}
                            // value={this.props.selectedEntity}
                            >
                                {/* <Option value="0">Entity</Option> */}
                                {this.props.availableEntities.map((mifEntity) =>
                                    (<Option key={mifEntity.Id.toString()}
                                        value={mifEntity.Id.toString()}>{mifEntity.Name}</Option>))}
                            </Select>
                        )}
                    </FormItem>

                    <FormItem>
                        <Button type="primary" htmlType="submit"
                            disabled={this.disabledAutoUploadButtonChecker()}
                        >Scan import folder for matching files & upload</Button>
                        </FormItem>
                </Form> 

            </div>
        );
    }
}

export default Form.create()(UploadFileForm);

