// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import * as React from "react";
import * as Interfaces from "../interfaces";
import { Table, Badge, Menu, Icon, Button, Input, Popconfirm, notification } from 'antd';
const { Column, ColumnGroup } = Table;
import * as Immutable from "immutable";
import moment from "moment";
import DropDownCell from "./DropDownCell"
import EditableCell from "./EditableCell"
import MaturityCell from "./MaturityCell"
import MovAvgCell from "./MovAvgCell"
import RunoffIrrCell from "./RunoffIrrCell"
import RunoffMfrMfsCell from "./RunoffMfrMfsCell"
import * as InputCell from "./NumericInputCell";
import {addThereof} from "../specialRules/actions";

export interface RiskPositionModelTableProps {
    RiskPositionModels: Interfaces.RiskPositionModelEntry[],
    generalLedgers: Interfaces.GeneralLedger[],
    runoffModels: Interfaces.RunoffModel[],
    runoffMaturityTypes: Interfaces.RunoffMaturityType[],
    runoffIrrTypes: Interfaces.RunoffIrrType[],
    runoffMfrMfsTypes: Interfaces.RunoffMfrMfsType[],
    modelableRiskPositions: Interfaces.RiskPositionName[],
    addSaveRiskPositionModel: (x: Interfaces.RiskPositionModelEntry) => void;
    deleteRiskPositionModel: (x: number) => void;
    editSaveRiskPositionModel: (x: Interfaces.RiskPositionModelEntry) => void;
    loadRiskPositionModels: () => void;
   
}

interface RiskPositionModelsTableState {
    data: Immutable.Map<number, Interfaces.RiskPositionModelEntry>,
    filteredInfo: any,
    filteredCount: number,
    sortedInfo: any,
    pageInfo: any
}

function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

// the models 5-8 do not require a maturity type. 2==RE, 1==WS
function modelRequiresMaturityType(modelType : number): number {
    return modelType == 5 || modelType == 7 || modelType == 6 || modelType == 8 ? 1: 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

class MyTable extends Table<Interfaces.RiskPositionModelEntry> { }
class MyColumn extends Column<Interfaces.RiskPositionModelEntry> { }

export class RiskPositionModelTable extends React.Component<RiskPositionModelTableProps, RiskPositionModelsTableState> {

    //constructor
    constructor(props) {
        super(props);
        
        this.state = {
            data: Immutable.Map<number, Interfaces.RiskPositionModelEntry>(),
            filteredInfo: null,
            filteredCount: this.props.RiskPositionModels.length,
            sortedInfo: null,
            pageInfo: 1
        };

        //binding callback functions
        this.handleSorterFilterChange = this.handleSorterFilterChange.bind(this);
        this.handleEditableCellValueChange = this.handleEditableCellValueChange.bind(this);
    }

    getEditable(recordId: number) {
        return this.state.data.get(recordId);
    }

    setEditable(recordId: number, record: Interfaces.RiskPositionModelEntry) {
        const newOuter = this.state.data.set(recordId, record);
        this.setState({ data: newOuter });
    }

    //Function to change the parent state whenever an Editable cell's value is changed
    handleEditableCellValueChange = (recordId: number, fieldName: string) =>
        (value: any) => {
            const oldValue = this.state.data.get(recordId);
            var update = {};
            if (fieldName === "Comment" || fieldName === "LiqMaturityValue" || fieldName === "IntMaturityValue" || fieldName === "InterestRateIrr" || fieldName === "MarketRateMfr" || fieldName === "MarketSpreadMfs") {
                update[fieldName] = value;
            } 

            else if (fieldName === "LiqMaturityFixedDateValue" || fieldName === "IntMaturityFixedDateValue" || fieldName === "MovingAverageStartDate") {
                update[fieldName] = value ?  value._d : value;
            }

            else {
                update[fieldName] = parseInt(value);
            }
            const newValue = Object.assign({}, oldValue, update);
            this.setEditable(recordId, newValue);
        }   

    validateEntity(data: Interfaces.RiskPositionModelEntry) {

        var periodRegex = /\d+\s*[yYmM]/;
        var fixedNumberRegex = /^(\d *\.)?\d+$/;
        
        if ((data.LiqMaturityTypeId === 3 && !data.LiqMaturityFixedDateValue) || (data.IntMaturityTypeId === 3 && !data.IntMaturityFixedDateValue)) {
            notification["error"]({
                message: 'Error',
                description: 'Must select a date when liquidity/interest maturity type is set to fixed date',
                duration: 3
            });
        } 

        //validate model properties for export (all must not be "not mapped")
        else if (data.ExportFlag === 1 && data.RunoffModelId == 4 ) {
            notification["error"]({
                message: 'Error',
                description: 'In order to export risk position the risk model must be set',
                duration: 3
            });
        }


        //validate model properties for export (all must not be "not mapped")
        else if (data.ExportFlag === 1 && data.RunoffModelId <4 && (data.GeneralLedgerId === 115 || data.LiqMaturityTypeId === 6 || data.IntMaturityTypeId === 6 || data.InterestRateIrrTypeId === 6 || data.MarketRateMfrTypeId === 7 || data.MarketSpreadMfsTypeId === 7)) {
            notification["error"]({
                message: 'Error',
                description: 'In order to export risk position for chosen risk model all attributes must be set',
                duration: 3
            });
        }


        //validate model properties for export (all must not be "not mapped")
        else if (data.ExportFlag === 1 && data.RunoffModelId >= 5 && (data.GeneralLedgerId === 115 || data.InterestRateIrrTypeId === 6 || data.MarketRateMfrTypeId === 7 || data.MarketSpreadMfsTypeId === 7)) {
            notification["error"]({
                message: 'Error',
                description: 'In order to export risk position for chosen risk model the interest rate and market rate attributes must be set',
                duration: 3
            });
        }


        else if (data.ExportFlag === 1 && data.RunoffModelId < 5 && ((data.LiqMaturityTypeId === 4 && !periodRegex.test(data.LiqMaturityValue)) || (data.IntMaturityTypeId === 4 && !periodRegex.test(data.IntMaturityValue)))) {
            notification["error"]({
                message: 'Error',
                description: 'Must select a value and period (Y or M) when liquidity/interest maturity type is set to period',
                duration: 3
            });
        }

        else if (data.ExportFlag === 1 && data.RunoffModelId < 5 && (data.InterestRateIrrTypeId === 4 && (data.InterestRateIrr == null || data.InterestRateIrr===""))) {
            notification["error"]({
                message: 'Error',
                description: 'Must select a value when interest rate type is set to fixed number',
                duration: 3
            });
        }

        // Mfr/Mfs fixed number input requirement
        else if ((data.MarketRateMfrTypeId === 4 && (data.MarketRateMfr == null)) ||
            (data.MarketSpreadMfsTypeId === 4 && data.MarketSpreadMfs == null)) {
            notification["error"]({
                message: 'Error',
                description: 'Must select a value when Mfr/Mfs type is set to fixed number',
                duration: 3
            });
        }

        // Mfr Fixed Number correct format
        else if (data.MarketRateMfrTypeId === 4 && !fixedNumberRegex.test(data.MarketRateMfr)) {
            notification["error"]({
                message: 'Error',
                description: 'Incorrect fixed number format for Mfr Fixed Number value',
                duration: 3
            });
        }

        // Mfs Fixed Number correct format
        else if (data.MarketSpreadMfsTypeId === 4 && !fixedNumberRegex.test(data.MarketSpreadMfs)) {
            notification["error"]({
                message: 'Error',
                description: 'Incorrect fixed number format for Mfs Fixed Number value',
                duration: 3
            });
        }

        // Mfr IR Curve period value format
        else if (data.MarketRateMfrTypeId === 8 && !periodRegex.test(data.MarketRateMfr)) {
            notification["error"]({
                message: 'Error',
                description: 'Must select a value and period (Y or M) when Mfr type is set to IR Curve',
                duration: 3
            });
        }

        // Mfs Spread Curve period value format
        else if (data.MarketSpreadMfsTypeId === 8 && !periodRegex.test(data.MarketSpreadMfs)) {
            notification["error"]({
                message: 'Error',
                description: 'Must select a value and period (Y or M) when Mfs type is set to Spread Curve',
                duration: 3
            });
        }

        // Mfr IR curve integer value
        else if (data.MarketRateMfrTypeId === 8 && data.MarketRateMfr.indexOf(".") > -1) {
            notification["error"]({
                message: 'Error',
                description: 'Must select integer value when Mfr type is set to IR Curve',
                duration: 3
            });
        }

        // Mfs Spread curve integer value
        else if (data.MarketSpreadMfsTypeId === 8 && data.MarketSpreadMfs.indexOf(".") > -1) {
            notification["error"]({
                message: 'Error',
                description: 'Must select integer value when Mfs type is set to Spread Curve',
                duration: 3
            });
        }

        else {
            return true;
        }

        return false;

    }

    handleSave = (record: Interfaces.RiskPositionModelEntry) => {
        var data = this.state.data.get(record.Id);

        var validRow = this.validateEntity(data);

        if (validRow) {

            if (data.Id === 0) {

                if (!data.RiskPositionId) {
                    notification["error"]({ message: 'Error', description: 'Must set risk position name' });
                } else if (this.props.RiskPositionModels.find(x => x.RiskPositionId === data.RiskPositionId) !== undefined) {
                    notification["error"]({ message: 'Error', description: 'Model already exists for risk position' });
                }
                else {

                    this.props.addSaveRiskPositionModel(data);
                    const newData = this.state.data.delete(record.Id);                    
                    this.setState({ data: newData });
                }
            } else {
                this.props.editSaveRiskPositionModel(data);
                const newData = this.state.data.delete(record.Id);               
                this.setState({ data: newData });
            }
        }
    }
 
    handleCancel = (recordId: number) => {
        const imm = this.state.data;
        var newStatedata = imm.delete(recordId);
        this.setState({ data: newStatedata });
    }

    handleEdit = (record: Interfaces.RiskPositionModelEntry) => {
        var immEditable = this.state.data;
        var newStatedata = immEditable.set(record.Id, record);
        this.setState({ data: newStatedata });
    }

    handleAdd = () => {
       
        const newData: Interfaces.RiskPositionModelEntry = {
            Id: 0,
            RiskPositionId: 1,
            RiskPositionName: 'Allowance - Assumption: for operate lease installments',            
            GeneralLedgerId: 21,
            GeneralLedgerName: 'Not mapped',
            RunoffModelId: 4,
            RunoffModelName: 'Not mapped',
            LiqMaturityTypeId: 6,
            LiqMaturityTypeName: 'Not mapped',
            LiqMaturityFixedDateValue: null,
            LiqPeriodTypeId: null,
            LiqMaturityValue: null,
            IntMaturityTypeId: 6,
            IntMaturityTypeName: 'Not mapped',
            IntMaturityValue: null,
            IntPeriodTypeId: null,
            IntMaturityFixedDateValue: null,
            InterestRateIrrTypeId: 6,
            InterestRateIrrTypeName: 'Not mapped',
            InterestRateIrr: null,
            MarketRateMfrTypeId: 7,
            MarketRateMfrTypeName: 'Not mapped',
            MarketRateMfrPeriodTypeId: null,
            MarketRateMfr: null,
            MarketSpreadMfsTypeId: 7,
            MarketSpreadMfsTypeName: 'Not mapped',
            MarketSpreadMfsPeriodTypeId: null,
            MarketSpreadMfs: null,
            EffectiveFrom: moment().toDate(),
            EffectiveFromUserId: null,
            EffectiveFromUserFullName: null,
            EffectiveTo: null,
            EffectiveToUserId: null,
            EffectiveToUserFullName: null,
            Comment: null,
            ExportFlag: 2,
            MovingAverageStartDate: null
            
        };
        

        const imm = this.state.data;
        var newStateData = imm.set(0, newData);
        this.setState({ data: newStateData, filteredInfo: null, filteredCount: this.props.RiskPositionModels.length, sortedInfo: null, pageInfo: 1 });
        
    };
  
    handleDelete = (id) => {
        this.props.deleteRiskPositionModel(id);
    }

    handleSorterFilterChange(pagination, filters, sorter) {        
        //Re-calculating number of table entries after application of filters
        var filteredRiskPositionModelCount = this.props.RiskPositionModels.filter(
            x => (filters.RiskPositionName === null || filters.RiskPositionName.length === 0) ? true : filters.RiskPositionName.indexOf(x.RiskPositionName || " ") !== -1).filter(
            x => (filters.GeneralLedgerName === null || filters.GeneralLedgerName.length === 0) ? true : filters.GeneralLedgerName.indexOf(x.GeneralLedgerName || " ") !== -1).filter(
            x => (filters.RunoffModelName === null || filters.RunoffModelName.length === 0) ? true : filters.RunoffModelName.indexOf(x.RunoffModelName || " ") !== -1).filter(
            x => (filters.LiqMaturityTypeName === null || filters.LiqMaturityTypeName.length === 0) ? true : filters.LiqMaturityTypeName.indexOf(x.LiqMaturityTypeName || " ") !== -1).filter(
            x => (filters.IntMaturityTypeName === null || filters.IntMaturityTypeName.length === 0) ? true : filters.IntMaturityTypeName.indexOf(x.IntMaturityTypeName || " ") !== -1).filter(
            x => (filters.MarketRateMfrTypeName === null || filters.MarketRateMfrTypeName.length === 0) ? true : filters.MarketRateMfrTypeName.indexOf(x.MarketRateMfrTypeName == "Curve" ? "IR Curve" : x.MarketRateMfrTypeName || " ") !== -1).filter(
            x => (filters.MarketSpreadMfsTypeName === null || filters.MarketSpreadMfsTypeName.length === 0) ? true : filters.MarketSpreadMfsTypeName.indexOf(x.MarketSpreadMfsTypeName == "Curve" ? "Spread Curve" : x.MarketSpreadMfsTypeName || " ") !== -1).filter(
            x => (filters.InterestRateIrrTypeName === null || filters.InterestRateIrrTypeName.length === 0) ? true : filters.InterestRateIrrTypeName.indexOf(x.InterestRateIrrTypeName || " ") !== -1).length;


        this.setState({
            filteredInfo: filters,
            filteredCount: filteredRiskPositionModelCount,
            sortedInfo: sorter,
            pageInfo: pagination.current
        })       
    }
    
    render() {

        let sortedInfo = this.state.sortedInfo;
        let filteredInfo = this.state.filteredInfo;
        let filteredCount = this.state.filteredCount;
        let pageInfo = this.state.pageInfo;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        pageInfo = pageInfo;

        //Configuration object for pagination of table
        var paginationConfig = {
            pagination: {
                current: pageInfo,
                pageSize: 10,
                total: filteredCount
            }
        }

        // Set names of MFR & MFS type options
        //TODO: Remove the 'Focus' option from this too
        let IRRTypeOptions = this.props.runoffIrrTypes.sort((a, b) => (a.Id == 6 ? 100 : a.Id) - (b.Id == 6 ? 100 : b.Id));
        let MFRTypeOptions = this.props.runoffMfrMfsTypes.filter(x => x.Id !== 6).sort((a, b) => (a.Id == 7 ? 100 : a.Id) - (b.Id == 7 ? 100 : b.Id)).map(x => x.Name == "Curve" ? { Id: x.Id, Name: "IR Curve" } : x);
        let MFSTypeOptions = this.props.runoffMfrMfsTypes.filter(x => x.Id !== 6).sort((a, b) => (a.Id == 7 ? 100 : a.Id) - (b.Id == 7 ? 100 : b.Id)).map(x => x.Name == "Curve" ? { Id: x.Id, Name: "Spread Curve" } : x);

        // Altering filters to accomoudate for different mfr/mfs curve names
        let IRRTypeFilters = this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => x.InterestRateIrrTypeName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null
        let MFRTypeFilters = this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => (x.MarketRateMfrTypeName == "Curve" ? "IR Curve" : x.MarketRateMfrTypeName || " ")))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null
        let MFSTypeFilters = this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => (x.MarketSpreadMfsTypeName == "Curve" ? "Spread Curve" : x.MarketSpreadMfsTypeName || " ")))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null 

        //Adding new row to table datasource when add button is clicked        
        var riskPositionModelsDataSource = this.props.RiskPositionModels.slice();
        const addRiskPositionModel = this.state.data.get(0);
        if (!!addRiskPositionModel)
            riskPositionModelsDataSource.unshift(addRiskPositionModel);
        
        return (
            <div className="riskPositionModelsTableClass">
                <MyTable className="riskPositionModels-table" size="small" dataSource={riskPositionModelsDataSource}
                    rowKey="Id" scroll={{ x: 2900 }} {...paginationConfig} onChange={this.handleSorterFilterChange} >
                    
                    <MyColumn
                        title="Risk Position"
                        render={(record) => <DropDownCell
                            options={this.props.modelableRiskPositions}
                            value={record.RiskPositionId}
                            removeDefault
                            editableStatus={!!this.getEditable(record.Id) && record.Id === 0}
                            editStateValue={this.handleEditableCellValueChange(record.Id, "RiskPositionId")}
                            cellWidth={300}
                        />}
                        key="RiskPositionName"
                        sorter={(a, b) => nonNumberSorter(a.RiskPositionName || " ", b.RiskPositionName || " ")}
                        sortOrder={sortedInfo.columnKey === "RiskPositionName" && sortedInfo.order}
                        filters={this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => x.RiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        filteredValue={filteredInfo.RiskPositionName || null}
                        onFilter={(value, record) => (record.RiskPositionName || " ").indexOf(value) === 0}
                        width={300}
                        fixed="left"
                    />                   
                    <MyColumn
                        title="General Ledger"
                        render={(text, record) => <DropDownCell options={this.props.generalLedgers} value={record.GeneralLedgerId} removeDefault editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "GeneralLedgerId")} />}
                        key="GeneralLedgerName"
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.GeneralLedgerName.localeCompare(a.GeneralLedgerName) : null }}
                        sortOrder={sortedInfo.columnKey === "GeneralLedgerName" && sortedInfo.order}
                        filters={this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => x.GeneralLedgerName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        filteredValue={filteredInfo.GeneralLedgerName || null}
                        onFilter={(value, record) => (record.GeneralLedgerName || " ").indexOf(value) === 0}
                        width={180}
                    />

                    <MyColumn
                        title="Runoff Model"
                        render={(text, record) => <DropDownCell options={this.props.runoffModels} value={record.RunoffModelId} removeDefault editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "RunoffModelId")} />}
                        key="RunoffModelName"
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.RunoffModelName.localeCompare(a.RunoffModelName) : null }}
                        sortOrder={sortedInfo.columnKey === "RunoffModelName" && sortedInfo.order}
                        filters={this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => x.RunoffModelName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        filteredValue={filteredInfo.RunoffModelName || null}
                        onFilter={(value, record) => (record.RunoffModelName || " ").indexOf(value) === 0}
                        width={180}
                    />
                    <MyColumn
                        title="MovAvg Start Date"
                        //render={(text, record) => moment(record.RunoffModelId).format("DD MM YYYY")}

                        render={(text, record) =>
                            <MovAvgCell
                                dateValue={record.MovingAverageStartDate}
                                //maturityType={3}
                                RunOffModelType={this.getEditable(record.Id) ? this.getEditable(record.Id).RunoffModelId : record.RunoffModelId}
                                editableStatus={!!this.getEditable(record.Id)}
                                editStateDateValue={this.handleEditableCellValueChange(record.Id, "MovingAverageStartDate")}
                            />
                        }
                        sorter={(a, b) => -nonNumberSorter(a.MovingAverageStartDate || " ", b.MovingAverageStartDate || " ")}
                        sortOrder={sortedInfo.columnKey === "MovingAverageStartDate" && sortedInfo.order}
                        key="MovingAverageStartDate"
                        width={240}
                    />
                    <MyColumn
                        title="Maturity Type (Liq)"
                        key="LiqMaturityTypeName"
                        render={(text, record) => {
                            if (modelRequiresMaturityType(this.getEditable(record.Id) ? this.getEditable(record.Id).RunoffModelId : record.RunoffModelId) == 0) {
                                return <DropDownCell options={this.props.runoffMaturityTypes}
                                    // this is the case in which int/liq maturities are needed
                                    // this is for old settings (1, 2) which need to be eliminated --> set to not mapped
                                    value={(record.LiqMaturityTypeId == 1 || record.LiqMaturityTypeId == 2 || !record.LiqMaturityTypeId) ? 6 : record.LiqMaturityTypeId}
                                    removeDefault
                                    editableStatus={!!this.getEditable(record.Id)}
                                    editStateValue={this.handleEditableCellValueChange(record.Id, "LiqMaturityTypeId")}
                                />
                            }
                            else {
                                // this is the case in which int/liq maturities are not needed
                                return <DropDownCell options={this.props.runoffMaturityTypes}
                                    value={6} // not mapped
                                    removeDefault
                                    editableStatus={false}
                                    editStateValue={this.handleEditableCellValueChange(record.Id, "LiqMaturityTypeId")}
                                />
                            }
                        }}
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.LiqMaturityTypeName.localeCompare(a.LiqMaturityTypeName) : null }}
                        sortOrder={sortedInfo.columnKey === "LiqMaturityTypeName" && sortedInfo.order}
                        filters={this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => x.LiqMaturityTypeName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        filteredValue={filteredInfo.LiqMaturityTypeName || null}
                        onFilter={(value, record) => (record.LiqMaturityTypeName || " ").indexOf(value) === 0}
                        width={180}                  />
                    <MyColumn
                        title={"Maturity (Liq)"}
                        render={(text, record) =>
                            <MaturityCell value={record.LiqMaturityValue}
                                dateValue={record.LiqMaturityFixedDateValue}
                                maturityType={this.getEditable(record.Id) ? this.getEditable(record.Id).LiqMaturityTypeId : record.LiqMaturityTypeId}
                                editableStatus={!!this.getEditable(record.Id)}
                                editStateValue={this.handleEditableCellValueChange(record.Id, "LiqMaturityValue")}
                                editStateDateValue={this.handleEditableCellValueChange(record.Id, "LiqMaturityFixedDateValue")}
                            />
                        }
                        key="LiqMaturityValue"
                        //filters={this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => x.LiqMaturityValue || ""))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        //filteredValue={filteredInfo.LiqMaturityValue || null}
                        //onFilter={(value, record) => (record.LiqMaturityValue || "").indexOf(value) === 0}
                        width={160}
                    />
                    <MyColumn
                        title="Maturity Type (Int)"
                        key="IntMaturityTypeName"
                        render={(text, record) => {
                            if (modelRequiresMaturityType(this.getEditable(record.Id) ? this.getEditable(record.Id).RunoffModelId : record.RunoffModelId) == 0) {
                                return <DropDownCell options={this.props.runoffMaturityTypes}
                                    // this is the case in which int/liq maturities are needed
                                    // this is for old settings (1, 2) which need to be eliminated --> set to not mapped
                                    value={(record.IntMaturityTypeId == 1 || record.IntMaturityTypeId == 2 || !record.LiqMaturityTypeId) ? 6 : record.IntMaturityTypeId}
                                    removeDefault
                                    editableStatus={!!this.getEditable(record.Id)}
                                    editStateValue={this.handleEditableCellValueChange(record.Id, "IntMaturityTypeId")}
                                />
                            }
                            else {
                                // this is the case in which int/liq maturities are not needed
                                return <DropDownCell options={this.props.runoffMaturityTypes}
                                    value={6} // not mapped
                                    removeDefault
                                    editableStatus={false}
                                    editStateValue={this.handleEditableCellValueChange(record.Id, "IntMaturityTypeId")}
                                />
                            }
                        }}
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.IntMaturityTypeName.localeCompare(a.IntMaturityTypeName) : null }}
                        sortOrder={sortedInfo.columnKey === "IntMaturityTypeName" && sortedInfo.order}
                        filters={this.props.RiskPositionModels !== null ? [...new Set(this.props.RiskPositionModels.map(x => x.IntMaturityTypeName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)) : null}
                        filteredValue={filteredInfo.IntMaturityTypeName || null}
                        onFilter={(value, record) => (record.IntMaturityTypeName || " ").indexOf(value) === 0}
                        width={180}
                    />
                    <MyColumn
                        title="Maturity (Int)"
                        render={(text, record) =>
                            <MaturityCell
                                value={record.IntMaturityValue}
                                dateValue={record.IntMaturityFixedDateValue}
                                maturityType={this.getEditable(record.Id) ? this.getEditable(record.Id).IntMaturityTypeId : record.IntMaturityTypeId}
                                editableStatus={!!this.getEditable(record.Id)}
                                editStateValue={this.handleEditableCellValueChange(record.Id, "IntMaturityValue")}
                                editStateDateValue={this.handleEditableCellValueChange(record.Id, "IntMaturityFixedDateValue")}
                            />
                        }
                        key="IntMaturityValue"
                        //sorter={(a, b) => nonNumberSorter(a.IntMaturityValue || " ", b.IntMaturityValue || " ")}
                        //sortOrder={sortedInfo.columnKey === "IntMaturityValue" && sortedInfo.order}
                        width={160}
                    />
                    <MyColumn
                        title="Interest Rate Type"
                        render={(text, record) => <DropDownCell options={IRRTypeOptions} value={record.InterestRateIrrTypeId} removeDefault editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "InterestRateIrrTypeId")} />}
                        key="InterestRateIrrTypeName"
                        sorter={(a, b) => { return (a !== null && b !== null) ? b.InterestRateIrrTypeName.localeCompare(a.InterestRateIrrTypeName) : null }}
                        sortOrder={sortedInfo.columnKey === "InterestRateIrrTypeName" && sortedInfo.order}
                        filters={IRRTypeFilters}
                        filteredValue={filteredInfo.InterestRateIrrTypeName || null}
                        onFilter={(value, record) => (record.InterestRateIrrTypeName || " ").indexOf(value) === 0}
                        width={180}
                    />
                    <MyColumn
                        title="Interest Rate (IRR)"
                        render={(text, record) => <RunoffIrrCell value={record.InterestRateIrr} irrType={this.getEditable(record.Id) ? this.getEditable(record.Id).InterestRateIrrTypeId : record.InterestRateIrrTypeId} editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "InterestRateIrr")}/>}
                        key="InterestRateIrr"
                        //sorter={(a, b) => nonNumberSorter(a.InterestRateIrr || " ", b.InterestRateIrr || " ")}
                        //sortOrder={sortedInfo.columnKey === "InterestRateIrr" && sortedInfo.order}
                        width={160}
                    />
                    <MyColumn
                        title="Market Rate (MFR) Type"
                        render={(text, record) => <DropDownCell options={MFRTypeOptions} value={record.MarketRateMfrTypeId} removeDefault editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "MarketRateMfrTypeId")} />}
                        key="MarketRateMfrTypeName"
                        sorter={(a, b) => { return (a !== null && b !== null) ? (b.MarketRateMfrTypeName == "Curve" ? "IR Curve" : b.MarketRateMfrTypeName).localeCompare((a.MarketRateMfrTypeName == "Curve" ? "IR Curve" : a.MarketRateMfrTypeName)) : null}}
                        sortOrder={sortedInfo.columnKey === "MarketRateMfrTypeName" && sortedInfo.order}
                        filters={MFRTypeFilters}
                        filteredValue={filteredInfo.MarketRateMfrTypeName || null}
                        onFilter={(value, record) => (record.MarketRateMfrTypeName == "Curve" ? "IR Curve" : record.MarketRateMfrTypeName || " ").indexOf(value) === 0}
                        width={200}
                    />
                    <MyColumn
                        title="Market Rate (MFR)"
                        render={(text, record) => <RunoffMfrMfsCell value={record.MarketRateMfr} rateType={this.getEditable(record.Id) ? this.getEditable(record.Id).MarketRateMfrTypeId : record.MarketRateMfrTypeId} editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "MarketRateMfr")} />}
                        key="MarketRateMfr"
                        //sorter={(a, b) => nonNumberSorter(a.MarketRateMfr || " ", b.MarketRateMfr || " ")}
                        //sortOrder={sortedInfo.columnKey === "MarketRateMfr" && sortedInfo.order}
                        width={160}
                    />
                    <MyColumn
                        title="Market Spread (MFS) Type"
                        render={(text, record) => <DropDownCell options={MFSTypeOptions} value={record.MarketSpreadMfsTypeId} removeDefault editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "MarketSpreadMfsTypeId")} />}
                        key="MarketSpreadMfsTypeName"
                        sorter={(a, b) => { return (a !== null && b !== null) ? (b.MarketSpreadMfsTypeName == "Curve" ? "Spread Curve" : b.MarketSpreadMfsTypeName).localeCompare(a.MarketSpreadMfsTypeName == "Curve" ? "Spread Curve" : a.MarketSpreadMfsTypeName) : null }}
                        sortOrder={sortedInfo.columnKey === "MarketSpreadMfsTypeName" && sortedInfo.order}
                        filters={MFSTypeFilters}                        
                        filteredValue={filteredInfo.MarketSpreadMfsTypeName || null}
                        onFilter={(value, record) => (record.MarketSpreadMfsTypeName == "Curve" ? "Spread Curve" : record.MarketSpreadMfsTypeName || " ").indexOf(value) === 0}
                        width={220}
                    />
                    <MyColumn
                        title="Market Spread (MFS)"
                        render={(text, record) => <RunoffMfrMfsCell value={record.MarketSpreadMfs} rateType={this.getEditable(record.Id) ? this.getEditable(record.Id).MarketSpreadMfsTypeId : record.MarketSpreadMfsTypeId} editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "MarketSpreadMfs")} />}
                        key="MarketSpreadMfs"
                        //sorter={(a, b) => nonNumberSorter(a.MarketSpreadMfs || " ", b.MarketSpreadMfs || " ")}
                        //sortOrder={sortedInfo.columnKey === "MarketSpreadMfs" && sortedInfo.order}                        
                        width={160}
                    />                    
                    <MyColumn
                        title="Comment"
                        render={(text, record) => <EditableCell value={record.Comment} editableStatus={!!this.getEditable(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "Comment")} />}
                        key="Comment"
                        width={200}
                    />
                    <MyColumn
                        title="Export Position"
                        render={(record) => <DropDownCell options={[{ Id: 1, Name: "Yes" }, { Id: 2, Name: "No" }]} value={record.ExportFlag} removeDefault editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleEditableCellValueChange(record.Id, "ExportFlag")} />}
                        key="ExportPosition"
                        //sorter={(a, b) => { return a.ExportFlag - b.ExportFlag }}
                        //sortOrder={sortedInfo.columnKey === "ExportFlag" && sortedInfo.order}
                        width={120}
                    />                    
                    <MyColumn
                        title="Last Updated"
                        render={(text, record) =>
                            record.Id !== 0 ? record.EffectiveFromUserFullName + " on " + moment(record.EffectiveFrom).format("DD MMMM YYYY") : ""}
                        sorter={(a, b) => -nonNumberSorter(a.EffectiveFrom, b.EffectiveFrom)}
                        sortOrder={sortedInfo.columnKey === "EffectiveFrom" && sortedInfo.order}
                        key="EffectiveFrom"
                        width={240}
                    />

                    <MyColumn
                        fixed="right"
                        width={100}
                        title="Actions"
                        key="action"
                        render={(text, record) => (
                            this.getEditable(record.Id)
                                ? <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSave(record);
                                    }}>Save</a>
                                    <span className="ant-divider" />
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleCancel(record.Id);
                                    }}>Cancel</a>
                                </span>
                                : <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleEdit(record);
                                    }}>Edit</a>
                                    <span className="ant-divider" />

                                    <Popconfirm title="Sure to Delete?" onConfirm={(e) => {
                                        this.handleDelete(record.Id);

                                    }}>
                                        <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                    </Popconfirm>

                                </span>)
                        } />
                    
                </MyTable>

                <span className="adjustments-buttons">
                    {!this.state.data.has(0) ? <Button type="primary" onClick={() => this.handleAdd()}>Add</Button> : ""}
                </span>
                <br />

            </div>

        );
    }
}
