import * as Interfaces from "../interfaces";

export type SET_AVAILABLE_PAGES = "MIF/SET_AVAILABLE_PAGES";
export const SET_AVAILABLE_PAGES : SET_AVAILABLE_PAGES = "MIF/SET_AVAILABLE_PAGES";
export type SetAvailablePagesAction = {
    type: SET_AVAILABLE_PAGES,
    pages: Interfaces.MifPageList[],
}
export function setAvailableMifPages(pages: Interfaces.MifPageList[]) : Action {
    return {
        type: SET_AVAILABLE_PAGES,
        pages: pages
    }
}

export type LOAD_AVAILABLE_PAGES = "MIF/LOAD_AVAILABLE_PAGES";
export const LOAD_AVAILABLE_PAGES : LOAD_AVAILABLE_PAGES = "MIF/LOAD_AVAILABLE_PAGES";
export type LoadAvailablePagesAction = {
    type: LOAD_AVAILABLE_PAGES,
    forceReload: boolean
}
export function loadAvailablePages(forceReload = false) : Action {
    return {
        type: LOAD_AVAILABLE_PAGES,
        forceReload: forceReload
    }
}

export type ADD_PAGE_FULL = "MIF/ADD_PAGE_FULL";
export const ADD_PAGE_FULL : ADD_PAGE_FULL = "MIF/ADD_PAGE_FULL";
export type AddPageFullAction = {
    type: ADD_PAGE_FULL,
    id: number,
    pageFull: Interfaces.MifPageFull,
}
export function addPageFull(id: number, pageFull: Interfaces.MifPageFull) : Action {
    return {
        type: ADD_PAGE_FULL,
        id: id,
        pageFull: pageFull
    }
}

export type LOAD_PAGE_FULL = "MIF/LOAD_PAGE_FULL";
export const LOAD_PAGE_FULL : LOAD_PAGE_FULL = "MIF/LOAD_PAGE_FULL";
export type LoadPageFullAction = {
    type: LOAD_PAGE_FULL,
    id: number,
    forceReload: boolean,
}
export function loadMifPageFull(id:number, forceReload = false) : Action {
    return {
        type: LOAD_PAGE_FULL,
        id: id,
        forceReload: forceReload
    }
}

export type RESET_PAGES_FULL = "MIF/RESET_PAGES_FULL";
export const RESET_PAGES_FULL: RESET_PAGES_FULL = "MIF/RESET_PAGES_FULL";
export type ResetPagesFullAction = {
    type: RESET_PAGES_FULL,
    id: string
}

export function resetMifPagesFull(pageId: string): Action {
    return {
        type: RESET_PAGES_FULL,
        id: pageId
    }
}

export type SET_CURRENCY_FILTER = "MIF/SET_CURRENCY_FILTER";
export const SET_CURRENCY_FILTER:SET_CURRENCY_FILTER = "MIF/SET_CURRENCY_FILTER";
export type SetCurrencyFilterAction = {
    type: SET_CURRENCY_FILTER,
    id: number,
}
export function setCurrencyFilter(id:number) : Action {
    return {
        type: SET_CURRENCY_FILTER,
        id: id
    }
}

export type START_DATA = "MIF/START_DATA";
export const START_DATA: START_DATA = "MIF/START_DATA";
export type StartDataAction = {
    type: START_DATA,
    id: number,
    entityErdrCode: string,
    entityName: string,
    referenceDate: Date
}
export function startData(id: number, entityErdrCode: string, entityName: string, referenceDate: Date) : Action {
    return {
        type: START_DATA,
        id: id,
        entityErdrCode: entityErdrCode,
        entityName: entityName,
        referenceDate: referenceDate
    }
}


export type UNFINALISE_DATA = "MIF/UNFINALISE_DATA";
export const UNFINALISE_DATA: UNFINALISE_DATA = "MIF/UNFINALISE_DATA";
export type UnfinaliseDataAction = {
    type: UNFINALISE_DATA,
    id: number,
    entityErdrCode: string,
    entityName: string,
    referenceDate: Date
}
export function unfinaliseData(id: number, entityErdrCode: string, entityName: string, referenceDate: Date): Action {
    return {
        type: UNFINALISE_DATA,
        id: id,
        entityErdrCode: entityErdrCode,
        entityName: entityName,
        referenceDate: referenceDate
    }
}

export type SET_FINALISING_STATUS = "MIF/SET_FINALISING_STATUS";
export const SET_FINALISING_STATUS: SET_FINALISING_STATUS = "MIF/SET_FINALISING_STATUS";
export type SetFinalisingStatusAction = {
    type: SET_FINALISING_STATUS,
    isFinalising: Boolean
}

export function setFinalisingStatus(isFinalising: Boolean): Action {
    return {
        type: SET_FINALISING_STATUS,
        isFinalising: isFinalising
    }
}

export type SET_LOADING_STATUS = "MIF/SET_LOADING_STATUS";
export const SET_LOADING_STATUS: SET_LOADING_STATUS = "MIF/SET_LOADING_STATUS";
export type SetLoadingStatusAction = {
    type: SET_LOADING_STATUS,
    isLoading: Boolean
}

export function setLoadingStatus(isLoading: Boolean): Action {
    return {
        type: SET_LOADING_STATUS,
        isLoading: isLoading
    }
}


export type OtherAction = { type: "MIF/Default" }
export const OtherAction : OtherAction = {type: "MIF/Default"}

export type Action = SetAvailablePagesAction | LoadAvailablePagesAction | AddPageFullAction | LoadPageFullAction | ResetPagesFullAction | SetCurrencyFilterAction | StartDataAction | UnfinaliseDataAction | SetFinalisingStatusAction | SetLoadingStatusAction | OtherAction
