import { combineReducers } from "redux"
import { Map } from "immutable"
import * as Actions from "./actions";
import * as Interfaces from "../interfaces";

export type State = {
    totalRunoff: Interfaces.TotalRunoff,
    differencePositions: Interfaces.RiskPositionName[],
    runoffLoadingStatus: Boolean,
    runoffExportingStatus: Boolean,
    allTotals: Interfaces.CheckAllCurrencies,
}

function totalRunoff(state: Interfaces.TotalRunoff = null, action: Actions.Action) {
    switch (action.type) {
        case Actions.SET:
            return action.totalRunoff;
        case Actions.LOAD:
            return null;
        default:
            return state;
    }
}

function allTotals(state: Interfaces.CheckAllCurrencies = null, action: Actions.Action) {
    switch (action.type) {
        case Actions.SET_ALL_TOTALS:
            return action.CheckAllCurrencies;
        case Actions.LOAD_ALL_TOTALS:
            return null;
        default:
            return state;
    }
}


function differencePositions(state: Interfaces.RiskPositionName[] = [], action: Actions.Action) {
    switch (action.type) {
        case Actions.SET_DIFFERENCE_POSITIONS:
            return action.differencePositions;
        case Actions.LOAD_DIFFERENCE_POSITIONS:
            return [];
        default:
            return state;
    }
}

function runoffLoadingStatus(state: boolean = false, action: Actions.Action) {
    switch (action.type) {
        case Actions.SET_LOADING_STATUS:
            return action.isLoading;
        default:
            return state;
    }
}

function runoffExportingStatus(state: boolean = false, action: Actions.Action) {
    switch (action.type) {
        case Actions.SET_EXPORTING_STATUS:
            return action.isExporting;
        default:
            return state;
    }
}



export const reducer = combineReducers<State>({
    totalRunoff,
    differencePositions,
    runoffLoadingStatus,
    runoffExportingStatus,
    allTotals
});