// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import { Select, Tooltip } from 'antd';
const Option: any = Select.Option;

export interface IOption {
    Id: number,
    Name: string,
}

interface DropDownCellProps {
    options: IOption[],
    editableStatus: boolean,
    value: number,
    editStateValue: (value: any) => void,
    removeDefault?: boolean,
    placeholder?: string,
    maxStringLength?: number,
    cellWidth?: number,
    fixedValue?: string
}

interface DropDownCellState {
    editableStatus: boolean,
    fixedValue: string,
}

export default class DropDownCell extends React.Component<DropDownCellProps, DropDownCellState> {
    constructor(props : DropDownCellProps) {
        super(props);
        this.state = {
            editableStatus: props.editableStatus,
            fixedValue: props.fixedValue
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.editStateValue(e);
    };

    UNSAFE_componentWillReceiveProps(nextProps: DropDownCellProps) {
        this.setState({ editableStatus: nextProps.editableStatus });
    }

    render() {
        if (this.state.editableStatus) {
            return this.props.removeDefault ?
                (<Select
                    showSearch
                    style={this.props.cellWidth != null ? { width: this.props.cellWidth } :  { width: '100%' } }
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: "auto" }}
                    onChange={this.handleChange}
                    optionFilterProp="children"
                    filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                        var optionValue;
                        for (var optionProp in option) {
                            if (optionProp = 'props') {
                                optionValue = option[optionProp].children.toLowerCase();
                            }
                        }
                        return optionValue.indexOf(input.toLowerCase()) >= 0;
                    }}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value && this.props.value.toString()}
                >
                    {this.props.options.map(x =>
                        (<Option key={x.Id.toString()} value={x.Id.toString()}>{x.Name}</Option>))}
                </Select>) : 
                (<Select
                    showSearch
                    style={this.props.cellWidth != null ? { width: this.props.cellWidth } : { width: '100%' }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: "auto" }}
                    onChange={this.handleChange}
                    optionFilterProp="children"
                    filterOption={(input, option) => {                                                  //NB option doesn't seem to be able to know that it has props, ie 'option[props]' returns an error
                        var optionValue;
                        for (var optionProp in option) {
                            if (optionProp = 'props') {
                                optionValue = option[optionProp].children.toLowerCase();
                            }
                        }
                        return optionValue.indexOf(input.toLowerCase()) >= 0;
                    }}
                    defaultValue={(this.props.value || 0).toString()}
                >
                    <Option key="0" value="0">All</Option>
                    {this.props.options.map(x =>
                        (<Option key={x.Id.toString()} value={x.Id.toString()}>{x.Name}</Option>))}
                </Select>) ;
        } else {
            const selected: IOption = this.props.options.filter(x => x.Id === this.props.value)[0];
            if (this.state.fixedValue == null)
            {
                return <span>{!selected ? "-" : selected.Name}</span>;
            }
            else
            {
                return <span>{this.state.fixedValue}</span>;
            }
                
        }
    }

}

