import { put, takeEvery, takeLatest, fork, select } from 'redux-saga/effects'
import config from '../config';
import * as Interfaces from "../interfaces"
import * as Actions from "./actions";
import * as Top from "../reducers"
import * as Selectors from "./selectors"
import { notification } from "antd"
import * as MainActions from "../actions";

import AuthenticatedRequest from "../http";

function specialRulesTakeEvery<TAction extends string>(action: TAction,
    reducer: (act: Actions.Action & { type: TAction }) => any) {
    return takeEvery(action, reducer);
}

function* loadSpecialRules(action: Actions.LoadSpecialRulesAction) {
    const currentSpecialRules: Interfaces.SpecialRulesEntry[] = yield (select(Selectors.getSpecialRules)); 
    if (currentSpecialRules.length === 0 || action.forceReload) {
        const url = config.apiBaseUrl + "/api/SpecialRules";
        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            }
            const json: Interfaces.SpecialRulesEntry[] = yield response.json();
            yield put(Actions.setSpecialRules(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading Special Rules" });
        }

    }
}

function* watchLoadSpecialRules() {
    yield specialRulesTakeEvery(Actions.LOAD_SPECIAL_RULES, loadSpecialRules);
}


function* addSpecialRule(action: Actions.AddSpecialRuleAction) {

    const url = config.apiBaseUrl + "/api/SpecialRules";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(action.specialRule)
            }
        )
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        } else if (!response.ok) {
                    throw new Error("Error adding special rule")
                }
                
            yield put(Actions.loadSpecialRules());
            notification["success"]({ message: 'Success', description: 'Special Rule successfully added', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Special Rule" });
    }
}

function* watchAddSpecialRule() {
    yield specialRulesTakeEvery(Actions.ADD_SPECIAL_RULE, addSpecialRule);
}


//NB this needs to be a PUT action
function* editSpecialRule(action: Actions.EditSpecialRuleAction) {

    const url = config.apiBaseUrl + "/api/SpecialRules/" + action.specialRule.Id;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(action.specialRule)
            }
        )
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        } else if (!response.ok) {
                    throw new Error("Error editing special rule")
                }
                
        yield put(Actions.loadSpecialRules());
        notification["success"]({ message: 'Success', description: 'Special Rule successfully edited', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Special Rule" });
    }
}

function* watchEditSpecialRule() {
    yield specialRulesTakeEvery(Actions.EDIT_SPECIAL_RULE, editSpecialRule);
}

function* deleteSpecialRule(action: Actions.DeleteSpecialRuleAction) {
    const url = config.apiBaseUrl + "/api/SpecialRules/" + action.specialRuleId.toString();
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include",
            method: "DELETE"
            })
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            } else if (!response.ok) {
                    throw new Error("Error deleting special rule")
                }
                
            yield put(Actions.loadSpecialRules());
            notification["success"]({ message: 'Success', description: 'Special Rule successfully deleted', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error deleting Special Rule" });
    }
}

function* watchDeleteSpecialRule() {
    yield specialRulesTakeEvery(Actions.DELETE_SPECIAL_RULE, deleteSpecialRule);
}

function* loadThereofs(action: Actions.LoadThereofsAction) {
    const currentThereofs: Interfaces.ThereofEntry[] = yield (select(Selectors.getThereofs));
    if (currentThereofs.length === 0 || action.forceReload) {
        const url = config.apiBaseUrl + "/api/Thereofs";
        try {
            const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include"
            });
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            }
            const json: Interfaces.ThereofEntry[] = yield response.json();
            yield put(Actions.setThereofs(json));
        } catch (e) {
            notification.error({ message: "Error", description: "Error loading Thereof Adjustments" });
        }

    }
}

function* watchLoadThereofs() {
    yield specialRulesTakeEvery(Actions.LOAD_THEREOFS, loadThereofs);
}


function* addThereof(action: Actions.AddThereofAction) {

    const url = config.apiBaseUrl + "/api/Thereofs";
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(action.thereof)
            }
        )
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        } else if (!response.ok) {
                    throw new Error("Error adding Thereof adjustment")
                }
                
        yield put(Actions.loadThereofs());
        notification["success"]({ message: 'Success', description: 'Thereof Adjustment successfully added', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Thereof Adjustment" });
    }
}

function* watchAddThereof() {
    yield specialRulesTakeEvery(Actions.ADD_THEREOF, addThereof);
}


//NB this needs to be a PUT action
function* editThereof(action: Actions.EditThereofAction) {

    const url = config.apiBaseUrl + "/api/Thereofs/" + action.thereof.Id;
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "PUT",
                headers: new Headers({ "content-type": "text/json" }),
                body: JSON.stringify(action.thereof)
            }
        )
        if (response.status == 401) {
            notification.error({ message: "Error", description: "Access token expired" });
            yield put(MainActions.getRefreshTokenAction())
        } else if (!response.ok) {
                    throw new Error("Error editing Thereof adjustment")
                }
                
        yield put(Actions.loadThereofs());
        notification["success"]({ message: 'Success', description: 'Thereof Adjustment successfully edited', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error saving Thereof Adjustment" });
    }
}

function* watchEditThereof() {
    yield specialRulesTakeEvery(Actions.EDIT_THEREOF, editThereof);
}


function* deleteThereof(action: Actions.DeleteThereofAction) {
    const url = config.apiBaseUrl + "/api/Thereofs/" + action.thereofId.toString();
    try {
        const response: Response = yield AuthenticatedRequest(fetch, url,
        {
            mode: "cors",
            credentials: "include",
            method: "DELETE"
            })
            
            if (response.status == 401) {
                notification.error({ message: "Error", description: "Access token expired" });
                yield put(MainActions.getRefreshTokenAction())
            } else if (!response.ok) {
                    throw new Error("Error deleting Thereof adjustment")
                }
                
        yield put(Actions.loadThereofs());
        notification["success"]({ message: 'Success', description: 'Thereof Adjustment successfully deleted', duration: 3 });
    } catch (e) {
        notification.error({ message: "Error", description: "Error deleting Thereof Adjustment" });
    }
}

function* watchDeleteThereof() {
    yield specialRulesTakeEvery(Actions.DELETE_THEREOF, deleteThereof);
}

export function* saga() {
    yield fork(watchLoadSpecialRules);
    yield fork(watchAddSpecialRule);
    yield fork(watchEditSpecialRule);
    yield fork(watchDeleteSpecialRule);
    yield fork(watchLoadThereofs);
    yield fork(watchAddThereof);
    yield fork(watchEditThereof);
    yield fork(watchDeleteThereof);
}