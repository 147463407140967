import * as React from "react";
import * as Interfaces from "../../interfaces";

import { Table, Icon, Button, Input, Popconfirm, Tooltip, notification } from "antd";
const { Column, ColumnGroup } = Table;

import * as Immutable from "immutable";
import { SelectableCellEquityInjections } from "./SelectableCellEquityInjections";
import { NumericInputCell } from "../../components/NumericInputCell";

import moment from "moment";
import DropDownCell from "../../components/DropDownCell"

export interface EquityInjectionsTableProps {
    equityInjections: Interfaces.EquityInjectionEntry[],
    mifCountries: Interfaces.MifCountry[],
    mifEntities: Interfaces.MifEntity[],
    mifCurrencies: Interfaces.MifCurrency[],
    riskPositionNames: Interfaces.RiskPositionName[],

    addSaveEquityInjection: (x: Interfaces.EquityInjectionEntry) => void;
    deleteEquityInjection: (x: number) => void;
    editSaveEquityInjection: (x: Interfaces.EquityInjectionEntry) => void;

}

interface EquityInjectionsTableState {
    data: Immutable.Map<number, Interfaces.EquityInjectionEntry>,
    entityFilters: any[],
    currencyFilters: any[],
    countryFilters: any[],
    toRiskPositionFilters: any[]


}


class MyTable extends Table<Interfaces.EquityInjectionEntry> { }
class MyColumn extends Column<Interfaces.EquityInjectionEntry> { }


function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}
function convertToFilter(Text) {
    return { text: Text, value: Text };
}

function numberWithCommas(x, ccy?) {
    if (!x) return null;
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

export class EquityInjectionsTable extends React.Component<EquityInjectionsTableProps, EquityInjectionsTableState> {

    //constructor
    constructor(props) {
        super(props);

        this.state = {
            data: Immutable.Map<number, Interfaces.EquityInjectionEntry>(),
            entityFilters: [],
            currencyFilters: [],
            countryFilters: [],
            toRiskPositionFilters: []
        };

        this.handleEditableCellValueChange = this.handleEditableCellValueChange.bind(this);


    }


    //componentWillReceiveProps
    UNSAFE_componentWillReceiveProps(nextProps) {

        this.setState({
            entityFilters: [...new Set(nextProps.equityInjections.map(x => x.EntityName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)),
            countryFilters: [...new Set(nextProps.equityInjections.map(x => x.CountryName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)),
            currencyFilters: [...new Set(nextProps.equityInjections.map(x => x.CurrencyName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value)),
            toRiskPositionFilters: [...new Set(nextProps.equityInjections.map(x => x.ToRiskPositionName || " "))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value))

        });
    }


    //DropDownCell change function
    handleDropDownEditableCellValueChange = (recordId: number, fieldName: string) =>
        (value: any) => {
            const oldDataEntry = this.state.data.get(recordId);
            var update = {};
            update[fieldName] = parseInt(value);
            const newDataEntry: Interfaces.EquityInjectionEntry = Object.assign({}, oldDataEntry, update);
            const newData = this.state.data.set(recordId, newDataEntry);
            this.setState({ data: newData });
        }


    //handleEditableCellValueChange
    handleEditableCellValueChange = (recordId: number, fieldName: string, value: any) => {

        const oldRecord = this.state.data.get(recordId);

        const newRecord: Interfaces.EquityInjectionEntry = ((columnName) => {
            switch (columnName) {
                case "Value":
                    return Object.assign({}, oldRecord, { Value: parseFloat(value) });
                case "Comment":
                    return Object.assign({}, oldRecord, { Comment: value.target.value });
                case "ValidFrom":
                    return Object.assign({}, oldRecord, { ValidFrom: (value.startOf("month").add(-value.startOf("month").toDate().getTimezoneOffset(), "minutes")).toDate() });
                case "ValidTo":
                    return Object.assign({}, oldRecord, { ValidTo: value == null ? null : value.endOf("month").add(-1, "minutes").toDate() });
                case "InjectionDate":
                    return Object.assign({}, oldRecord, { InjectionDate: value == null ? null : value.endOf("month").add(-1, "minutes").toDate() });
                default:
                    return oldRecord;
            }
        })(fieldName);

        //var newStateEditableData = immEditable.set(recordId, newRecord);
        var newData = this.state.data.set(recordId, newRecord);
        this.setState({ data: newData });

    }


    //handleAdd
    handleAdd = () => {
        const newData: Interfaces.EquityInjectionEntry = {
            Id: 0,
            CountryId: null,
            CountryName: null,
            EntityId: null,
            EntityName: null,
            EntityErdrCode: null,
            CurrencyId: null,
            CurrencyName: null,
            Value: null,
            InjectionDate: null,
            ValidFrom: moment().startOf("month").add(-moment().startOf("month").toDate().getTimezoneOffset(), "minutes").toDate(),
            ValidFromUserId: null,
            ValidTo: null,
            EffectiveFrom: moment().toDate(),
            EffectiveFromUserId: null,
            EffectiveFromUserFullName: null,
            EffectiveTo: null,
            EffectiveToUserId: null,
            ToRiskPositionId: null,
            ToRiskPositionName: null,
            Comment: null,
        };

        const imm = this.state.data;
        var newStateData = imm.set(0, newData);
        this.setState({ data: newStateData });
    };


    //handleSave
    handleSave = (record: Interfaces.EquityInjectionEntry) => {
        var entry = this.state.data.get(record.Id);

        if (!entry.CountryId && !entry.EntityId && !entry.CurrencyId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select Currency and exactly one of Country and Entity',
                duration: 3
            });
        } else if (!entry.CountryId && !entry.EntityId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select exactly one of Country and Entity',
                duration: 3
            });
        } else if (!!entry.CountryId && !!entry.EntityId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select exactly one of Country and Entity',
                duration: 3
            });
        } else if (!entry.CurrencyId) {
            notification["error"]({
                message: 'Error',
                description: 'Must select Currency',
                duration: 3
            });
        } else if (!entry.ToRiskPositionId) {
            notification["error"]({
                message: 'Error',
                description: "Must select To Risk Position",
                duration: 3
            });
        } else if (!entry.InjectionDate) {
            notification["error"]({
                message: 'Error',
                description: "Must select Injection Execution Date",
                duration: 3
            });
        }

        else if (entry.Id === 0) {
            this.props.addSaveEquityInjection(entry);
            const newData = this.state.data.delete(record.Id);
            this.setState({ data: newData });
        } else {
            this.props.editSaveEquityInjection(entry);
            const newData = this.state.data.delete(record.Id);
            this.setState({ data: newData });
        }
    }


    //handleCancel
    handleCancel = (recordId: number) => {
        const imm = this.state.data;

        var newStateData = imm.delete(recordId);
        this.setState({ data: newStateData });
    }


    //handleEdit
    handleEdit = (record: Interfaces.EquityInjectionEntry) => {
        var newStatedata = this.state.data.set(record.Id, record);
        this.setState({ data: newStatedata });
    }


    //handleDelete
    handleDelete = (id) => {
        this.props.deleteEquityInjection(id);
    }


    //renderColumns
    renderColumns(recordEntry: Interfaces.EquityInjectionEntry, columnName) {
        const edit = this.state.data.get(recordEntry.Id);
        if (edit && (
            columnName === "ValidFrom" ||
            columnName === "ValidTo" ||
            columnName === "Comment" ||
            columnName === "Value" ||
            columnName === "InjectionDate"
        )) {
            return (<SelectableCellEquityInjections
                recordId={recordEntry.Id}
                editableStatus={true}
                columnName={columnName}
                value={edit[columnName]}
                editStateValue={this.handleEditableCellValueChange}
            />
            );
        } else if (columnName === "ValidFrom") {
            return (moment(recordEntry[columnName]).format("MMMM YYYY"));
        }
        else if (columnName === "ValidTo") {
            return recordEntry[columnName] != null ? moment(recordEntry[columnName]).format("MMMM YYYY") : "";
        } else if (columnName === "EntityName") {
            return (<Tooltip title={recordEntry[columnName]} placement="rightTop"> {recordEntry
                .EntityErdrCode} </Tooltip >);
        } else if (columnName === "Value") {
            return recordEntry.Value !== 0 ? numberWithCommas(recordEntry.Value) : "-";
        } else if (columnName === "InjectionDate") {
            return recordEntry[columnName] != null ? moment(recordEntry[columnName]).format("MMMM YYYY") : "";
        } else {
            return (recordEntry[columnName]);
        }
    }


    render() {


        var equityInjectionsDataSource = this.props.equityInjections.slice();
        const addEquityInjection = this.state.data.get(0);
        if (!!addEquityInjection)
            equityInjectionsDataSource.push(addEquityInjection);

        var fromRiskPositionNames = this.props.riskPositionNames.slice();

        var newNullRiskPositionName = { Id: 0, Name: "None", IsDiffPosition: 1, IsValid: 0 }
        fromRiskPositionNames.unshift(newNullRiskPositionName);

        //const addNullRiskPosition = 

        //having a conditionally rendered button 
        var hasbutton: boolean = !this.state.data.has(0);

        return (
            <div className="equityInjections">

                <MyTable className="equityInjections-table" size="small" dataSource={equityInjectionsDataSource} rowKey="Id" scroll={{ x: 2150 }} pagination={false}>
                    <MyColumn
                        title="Country"
                        render={(record) => <DropDownCell options={this.props.mifCountries} value={record.CountryId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CountryId")} />}
                        key="CountryName"
                        width={200}
                        filters={this.state.countryFilters.length !== 0 ? this.state.countryFilters : null}
                        onFilter={(value, record) => (record.CountryName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CountryName || " ", b.CountryName || " ")}
                        fixed="left"
                    />
                    <MyColumn
                        title="Entity"
                        render={(record) => <DropDownCell options={this.props.mifEntities} value={record.EntityId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "EntityId")} />}
                        key="EntityName"
                        width={200}
                        filters={this.state.entityFilters.length !== 0 ? this.state.entityFilters : null}
                        onFilter={(value, record) => (record.EntityName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.EntityName || " ", b.EntityName || " ")}
                        fixed="left"
                    />
                    <MyColumn
                        title="Currency"
                        render={(record) => <DropDownCell options={this.props.mifCurrencies} value={record.CurrencyId} editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "CurrencyId")} />}
                        key="CurrencyName"
                        width={200}
                        filters={this.state.currencyFilters}
                        onFilter={(value, record) => record.CurrencyName.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.CurrencyName, b.CurrencyName)}
                        fixed="left"
                    />

                    <MyColumn
                        className="numeric-column"
                        title="Value"
                        render={(record) => this.renderColumns(record, "Value")}
                        sorter={(a, b) => nonNumberSorter(a.Value, b.Value)}
                        key="Value"
                        width={200}
                    />

                    <MyColumn
                        title="Valid From"
                        //dataIndex="ValidFrom"
                        render={(record) => this.renderColumns(record, "ValidFrom")}
                        key="ValidFrom"
                        sorter={(a, b) => nonNumberSorter(a.ValidFrom, b.ValidFrom)}
                        width={200}
                    />

                    {/* <Column
                        title="Valid To"
                        //dataIndex="ValidTo"
                        render={(record) => this.renderColumns(record, "ValidTo")}
                        key="ValidTo"
                        sorter={(a, b) => nonNumberSorter(a.ValidTo || " ", b.ValidTo || " ")}
                        width={200}
                    /> */ }
                    <MyColumn
                        title="Injection Execution Date"
                        //dataIndex="ValidTo"
                        render={(record) => this.renderColumns(record, "InjectionDate")}
                        key="InjectionDate"
                        sorter={(a, b) => nonNumberSorter(a.InjectionDate || " ", b.InjectionDate || " ")}
                        width={200}
                    />


                    <MyColumn
                        title="To Risk Position"
                        //dataIndex="ToRiskPositionName"

                        render={(record) => <DropDownCell options={this.props.riskPositionNames} value={record.ToRiskPositionId} removeDefault editableStatus={!!this.state.data.get(record.Id)} editStateValue={this.handleDropDownEditableCellValueChange(record.Id, "ToRiskPositionId")} />}
                        key="ToRiskPositionName"
                        filters={this.state.toRiskPositionFilters.length !== 0 ? this.state.toRiskPositionFilters : null}
                        onFilter={(value, record) => (record.ToRiskPositionName || " ").indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.ToRiskPositionName || " ", b.ToRiskPositionName || " ")}
                        width={200}
                    />

                    <MyColumn
                        title="Comment"
                        render={(record) => this.renderColumns(record, "Comment")}
                        key="Comment"
                        width={200}
                    />


                    <MyColumn
                        title="Last Updated"
                        //dataIndex="LastUpdated"

                        render={(text, record) =>
                            record.Id !== 0 ? record.EffectiveFromUserFullName + " on " + moment(record.EffectiveFrom).format("DD MMMM YYYY") : ""}
                        sorter={(a, b) => nonNumberSorter(a.EffectiveFrom, b.EffectiveFrom)}
                        key="EffectiveFrom"
                        width={250}
                    />
                    <MyColumn
                        fixed="right"
                        width={100}
                        title=""
                        key="action"
                        render={(text, record) => (
                            this.state.data.get(record.Id)
                                ? <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSave(record);
                                    }}>Save</a>
                                    <span className="ant-divider" />
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleCancel(record.Id);
                                    }}>Cancel</a>
                                </span>
                                : <span>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleEdit(record);
                                    }}>Edit</a>
                                    <span className="ant-divider" />

                                    <Popconfirm title="Sure to Delete?" onConfirm={(e) => {
                                        this.handleDelete(record.Id);

                                    }}>
                                        <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                    </Popconfirm>

                                </span>)
                        }
                    />


                </MyTable>
                <br />
                <span className="equityInjections-buttons">
                    {hasbutton ? <Button type="primary" onClick={() => this.handleAdd()}>Add</Button> : ""}
                </span>
                <br />


            </div>

        );
    }
}
