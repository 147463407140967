import * as Interfaces from "../interfaces";

export type LOAD_SPECIAL_RULES = "SPECIAL_RULES/LOAD_SPECIAL_RULES";
export const LOAD_SPECIAL_RULES : LOAD_SPECIAL_RULES = "SPECIAL_RULES/LOAD_SPECIAL_RULES";
export type LoadSpecialRulesAction = {
    type: LOAD_SPECIAL_RULES,
    forceReload: boolean,
}
export function loadSpecialRules(forceReload = true) : Action {
    return {
        type: LOAD_SPECIAL_RULES,
        forceReload
    }
}

export type SET_SPECIAL_RULES = "SPECIAL_RULES/SET_SPECIAL_RULES";
export const SET_SPECIAL_RULES : SET_SPECIAL_RULES = "SPECIAL_RULES/SET_SPECIAL_RULES";
export type SetSpecialRulesAction = {
    type: SET_SPECIAL_RULES,
    specialRules : Interfaces.SpecialRulesEntry[],
}
export function setSpecialRules(specialRules: Interfaces.SpecialRulesEntry[]) : Action {
    return {
        type: SET_SPECIAL_RULES,
        specialRules
    }
}

export type ADD_SPECIAL_RULE = "SPECIAL_RULES/ADD_SPECIAL_RULE";
export const ADD_SPECIAL_RULE : ADD_SPECIAL_RULE = "SPECIAL_RULES/ADD_SPECIAL_RULE";
export type AddSpecialRuleAction = {
    type: ADD_SPECIAL_RULE,
    specialRule : Interfaces.SpecialRulesEntry,
}
export function addSpecialRule(specialRule: Interfaces.SpecialRulesEntry) : Action {
    return {
        type: ADD_SPECIAL_RULE,
        specialRule
    }
}

export type EDIT_SPECIAL_RULE = "SPECIAL_RULES/EDIT_SPECIAL_RULE";
export const EDIT_SPECIAL_RULE : EDIT_SPECIAL_RULE = "SPECIAL_RULES/EDIT_SPECIAL_RULE";
export type EditSpecialRuleAction = {
    type: EDIT_SPECIAL_RULE,
    specialRule : Interfaces.SpecialRulesEntry,
}
export function editSpecialRule(specialRule: Interfaces.SpecialRulesEntry) : Action {
    return {
        type: EDIT_SPECIAL_RULE,
        specialRule
    }
}

export type DELETE_SPECIAL_RULE = "SPECIAL_RULES/DELETE_SPECIAL_RULE";
export const DELETE_SPECIAL_RULE : DELETE_SPECIAL_RULE = "SPECIAL_RULES/DELETE_SPECIAL_RULE";
export type DeleteSpecialRuleAction = {
    type: DELETE_SPECIAL_RULE,
    specialRuleId : number,
}
export function deleteSpecialRule(specialRuleId: number) : Action {
    return {
        type: DELETE_SPECIAL_RULE,
        specialRuleId
    }
}


export type LOAD_THEREOFS = "SPECIAL_RULES/LOAD_THEREOFS";
export const LOAD_THEREOFS : LOAD_THEREOFS = "SPECIAL_RULES/LOAD_THEREOFS";
export type LoadThereofsAction = {
    type: LOAD_THEREOFS,
    forceReload: boolean,
}
export function loadThereofs(forceReload = true) : Action {
    return {
        type: LOAD_THEREOFS,
        forceReload
    }
}

export type SET_THEREOFS = "SPECIAL_RULES/SET_THEREOFS";
export const SET_THEREOFS : SET_THEREOFS = "SPECIAL_RULES/SET_THEREOFS";
export type SetThereofsAction = {
    type: SET_THEREOFS,
    thereofs : Interfaces.ThereofEntry[],
}
export function setThereofs(thereofs: Interfaces.ThereofEntry[]) : Action {
    return {
        type: SET_THEREOFS,
        thereofs
    }
}

export type ADD_THEREOF = "SPECIAL_RULES/ADD_THEREOF";
export const ADD_THEREOF : ADD_THEREOF = "SPECIAL_RULES/ADD_THEREOF";
export type AddThereofAction = {
    type: ADD_THEREOF,
    thereof : Interfaces.ThereofEntry,
}
export function addThereof(thereof: Interfaces.ThereofEntry) : Action {
    return {
        type: ADD_THEREOF,
        thereof
    }
}

export type EDIT_THEREOF = "SPECIAL_RULES/EDIT_THEREOF";
export const EDIT_THEREOF : EDIT_THEREOF = "SPECIAL_RULES/EDIT_THEREOF";
export type EditThereofAction = {
    type: EDIT_THEREOF,
    thereof : Interfaces.ThereofEntry,
}
export function editThereof(thereof: Interfaces.ThereofEntry) : Action {
    return {
        type: EDIT_THEREOF,
        thereof
    }
}

export type DELETE_THEREOF = "SPECIAL_RULES/DELETE_THEREOF";
export const DELETE_THEREOF : DELETE_THEREOF = "SPECIAL_RULES/DELETE_THEREOF";
export type DeleteThereofAction = {
    type: DELETE_THEREOF,
    thereofId : number,
}
export function deleteThereof(thereofId: number) : Action {
    return {
        type: DELETE_THEREOF,
        thereofId
    }
}

export type OtherAction = { type: "SPECIAL_RULES/Default" }
export const OtherAction : OtherAction = {type: "SPECIAL_RULES/Default"}

export type Action =
    LoadSpecialRulesAction | SetSpecialRulesAction | AddSpecialRuleAction | EditSpecialRuleAction | DeleteSpecialRuleAction |
    LoadThereofsAction | SetThereofsAction | AddThereofAction | EditThereofAction | DeleteThereofAction |
    OtherAction
