// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import * as React from "react";
import * as Interfaces from "../../interfaces"
import { Table, Icon, Button, Input, Select } from 'antd';
const { Column, ColumnGroup } = Table;
const Option = Select.Option;

export interface ErdrSummaryProps {
    page: Interfaces.ErdrPageFull;
    currency: number,
    isLoading: boolean;
}


function nonNumberSorter(a, b) {
    return a > b ? -1 : a < b ? 1 : 0;
}

function convertToFilter(Text) {
    return { text: Text, value: Text };
}

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


function numberWithCommas(x, ccy?) {
    try {
        if (ccy) {
            return x.toLocaleString("de-DE", { style: 'currency', currency: ccy, currencyDisplay: "code", maximumFractionDigits: 2 });
        } else {
            return x.toLocaleString("de-DE", { maximumFractionDigits: 2 });
        }
    } catch (e) {
        return x.toFixed(2);
    }
}

class MyTable extends Table<Interfaces.ErdrSummaryEntry> { }
class MyColumn extends Column<Interfaces.ErdrSummaryEntry> { }


export default class ErdrSummary extends React.Component<ErdrSummaryProps, any> {

    render() {
        var page = this.props.page;
        if (page == null) {
            return <div>
                <h2 style={{ textAlign: "center" }}>{this.props.isLoading ? "Loading..." : "No Data"}</h2>
                   </div>;
        } else {

            var dataSource = page.SummaryEntries.filter(x => x.CurrencyId == this.props.currency);
            return (

                <MyTable className="erdr-page" size="small" dataSource={dataSource} pagination={false} rowKey="Id">
                    <MyColumn
                        
                        title="General Ledger"
                        dataIndex="GeneralLedgerErdrName"
                        key="InterPosition"
                        filters={[...new Set(dataSource.map(x => x.GeneralLedgerErdrName))].map(convertToFilter).sort((a, b) => nonNumberSorter(b.value, a.value))}
                        onFilter={(value, record) => record.GeneralLedgerErdrName.indexOf(value) === 0}
                        sorter={(a, b) => nonNumberSorter(a.GeneralLedgerErdrName, b.GeneralLedgerErdrName)}
                        width={100} />
                    <MyColumn
                        className="numeric-column"
                        title="Value"
                        dataIndex="Value"
                        render={(text, record: any) => (record.Value !== null) ? numberWithCommas(record.Value, record.CurrencyCode) : "-"}
                        key="Value"
                        sorter={(a, b) => a.Value - b.Value}
                        width={100} />
                </MyTable>
            );

        }
    }

}