import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const UserRoute = props => {
    console.log('props----', props)
    if (props.user) {
        return <Route {...props} />
    } else {
        return <Redirect to="/" />
    }
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(UserRoute)