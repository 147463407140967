
import config from './config';
import { put, fork, select, call } from 'redux-saga/effects'

function AuthenticationSagaError(message) {
    this.message = message;
    this.name = "AuthenticationSagaError";
}


function* getRefreshToken() {
    try {
        let url = config.apiBaseUrl + "/api/User/Refreshtoken";
        const headerData = yield select(getToken);
        const response = yield call(fetch, url,
            {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${headerData.hashcode}`
                }
            }
        );
        if (!response.ok) {
            throw new Error("Error fetching refresh token")
        } else {
            const responseBody = yield call([response, response.json]);
            console.log(responseBody)
            window.sessionStorage.setItem("token", JSON.stringify(responseBody));
            yield put(Actions.userLoggedIn(responseBody))
            //history.push('/web')
        }
    } catch (e) {
        console.log(e);
    }
}

// Selectors
const getToken = (state) => state.user;

export default function* AuthenticatedRequest(...args) {

    const headerData = yield select(getToken);
    console.log(headerData, 'token ---------------');

    if (headerData) {
        if (config.env === "Local") {
            console.log(args[2], "args------------")
            if (args[2].body) {
                console.log(args[2].body, "inside body-------")
                if (args[2].body instanceof FormData) {
                    console.log(args[2].body instanceof FormData, "instance of form data")
                    args[2].headers = {
                        // "content-type": "text/json",
                        'X-Auth-Timestamp': headerData.timestamp,
                        'X-Auth-Token': headerData.token,
                        'X-Auth-UserDomain': headerData.domain,
                        'X-Auth-UserName': headerData.ntid
                    }
                } else {
                    console.log("-----no instance =-----------")
                    args[2].headers = {
                        "content-type": "text/json",
                        'X-Auth-Timestamp': headerData.timestamp,
                        'X-Auth-Token': headerData.token,
                        'X-Auth-UserDomain': headerData.domain,
                        'X-Auth-UserName': headerData.ntid
                    }

                }
            } else {
                args[2].headers = {
                    // "content-type": "text/json",
                    'X-Auth-Timestamp': headerData.timestamp,
                    'X-Auth-Token': headerData.token,
                    'X-Auth-UserDomain': headerData.domain,
                    'X-Auth-UserName': headerData.ntid
                }
            }
        } else {
            if (args[2].body) {
                console.log(args[2].body, "inside body-------")
                if (args[2].body instanceof FormData) {
                    console.log(args[2].body instanceof FormData, "instance of form data")
                    args[2].headers = {
                        // "content-type": "text/json",
                        'Authorization': `Bearer ${headerData.hashcode}`
                    }
                } else {
                    console.log("-----no instance =-----------")
                    args[2].headers = {
                        "content-type": "text/json",
                        'Authorization': `Bearer ${headerData.hashcode}`
                    }

                }
            } else {
                args[2].headers = {
                    // "content-type": "text/json",
                    'Authorization': `Bearer ${headerData.hashcode}`
                }
            }
        }


        try {
            return yield call(...args);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                yield call(getRefreshToken)
            } else {
                throw error;
            }
        }
    } else {
        throw new AuthenticationSagaError("No access token");
    }
}

function logout() {
    window.sessionStorage.removeItem('token')
}

