import {match} from "react-router-dom"
import * as Interfaces from "../interfaces"
import { State } from "./reducers"
import { erdr } from "../selectors"
import * as Top from "../reducers"

const getAvailablePagesInner = (state: State) => state.availablePages;
export const getAvailablePages = (state: Top.State) => getAvailablePagesInner(erdr(state));

const getPageFullInner = (state: State, id: number) => state.pagesFull.get(id);
export const getPageFull = (id: number) => (state: Top.State) => getPageFullInner(erdr(state), id);
export function getPageFullFromMatch(match: match<{ pageId?: string }>): (state: Top.State) => Interfaces.ErdrPageFull {
    if (!match.params)
        return () => null;
    const id = parseInt(match.params.pageId);
    if (!id)
        return () => null;
    return getPageFull(id);
}